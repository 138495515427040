import React, { useState, useRef, useEffect } from 'react';
import { CopySvg, TrashSvg } from '../../../../assets/svg-files/SvgIcons.ts';
import Select from 'react-select';
import { TwoFactorInput } from '../../../Pages.ts';
import { apiRequest, getCurrencies, Currency } from '../../../../data/Api.ts';
// @ts-ignore
import styles from './Addresses.module.scss';
// @ts-ignore
import mediaStyles from './Addresses-media.module.scss';

interface Address {
    id: number;
    currency: {
        name: string;
        token: string;
        network: string;
        contract: string;
        decimals: number;
        confirmations: number;
        network_fee: number;
        min_deposit: number;
    };
    address: string;
    fullAddress?: string;
    label: string;
    is_autowithdraw: boolean;
}

// Тип для опций Select
type CurrencyOption = {
    value: string;
    label: string;
    network?: string;
};

const Addresses = () => {
    const [code, setCode] = useState("");
    const dialogRef = useRef<HTMLDialogElement | null>(null);
    const deleteDialogRef = useRef<HTMLDialogElement | null>(null);
    const editDialogRef = useRef<HTMLDialogElement | null>(null);
    const [addresses, setAddresses] = useState<Address[]>([]);
    const [loading, setLoading] = useState(true);
    const [currenciesLoading, setCurrenciesLoading] = useState(true);
    const [currencyOptions, setCurrencyOptions] = useState<CurrencyOption[]>([]);
    const [newCurrency, setNewCurrency] = useState({
        address: '',
        label: '',
        currency: '',
        autoWithdraw: false,
    });
    const [autoWithdrawEnabled, setAutoWithdrawEnabled] = useState(false);
    const [newAddress, setNewAddress] = useState({ address: '', label: '' });
    const [editedAddress, setEditedAddress] = useState<{ address: string; label: string; autoWithdraw?: boolean } | null>(null);
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [confirmChanges, setConfirmChanges] = useState(false);
    const [addressToDelete, setAddressToDelete] = useState<Address | null>(null);
    const [tooltipAddressId, setTooltipAddressId] = useState<number | null>(null);

    // Получение данных о валютах из API
    useEffect(() => {
        const fetchCurrencies = async () => {
            try {
                setCurrenciesLoading(true);
                const data = await getCurrencies();

                // Преобразуем данные в формат для Select
                const options = data.map(currency => ({
                    value: currency.name.toLowerCase(), // Используем name из API как value (с переводом в нижний регистр)
                    label: `${currency.name} (${currency.network})`, // Используем name и network для label
                    network: currency.network
                }));

                setCurrencyOptions(options);
            } catch (error) {
                console.error('Ошибка при получении данных о валютах:', error);
            } finally {
                setCurrenciesLoading(false);
            }
        };

        fetchCurrencies();
    }, []);
    // Получение адресов с сервера
    useEffect(() => {
        const fetchAddresses = async () => {
            try {
                setLoading(true);
                const data = await apiRequest<Address[]>('/pay/user/profile/addresses', 'GET');

                if (Array.isArray(data)) {
                    // Преобразуем адреса для отображения сокращенного адреса
                    const processedAddresses = data.map(address => ({
                        ...address,
                        fullAddress: address.address,
                        address: shortenAddress(address.address)
                    }));
                    setAddresses(processedAddresses);
                }
            } catch (error) {
                console.error('Ошибка при получении адресов:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchAddresses();
    }, []);

    const openDialog = () => {
        if (dialogRef.current) {
            dialogRef.current.showModal();
        }
    };

    const handleAutoWithdrawChangeForNew = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewCurrency({
            ...newCurrency,
            autoWithdraw: e.target.checked,
        });
    };

    const handleAutoWithdrawChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (editedAddress) {
            setEditedAddress({
                ...editedAddress,
                autoWithdraw: e.target.checked,
            });
        }
    };

    const openEditDialog = (address: Address) => {
        setEditedAddress({
            address: address.fullAddress || address.address,
            label: address.label,
            autoWithdraw: address.is_autowithdraw,
        });
        setIsEditing(false);
        if (editDialogRef.current) {
            editDialogRef.current.showModal();
        }
    };

    const closeDialog = () => {
        if (dialogRef.current) {
            dialogRef.current.close();
        }
        if (editDialogRef.current) {
            editDialogRef.current.close();
        }
        setEditedAddress(null);
        setIsEditing(false);
        setConfirmChanges(false);
    };

    const handleBackdropClick = (e: React.MouseEvent) => {
        if (e.target === e.currentTarget) {
            closeDialog();
            closeDeleteDialog();
        }
    };

    const shortenAddress = (address: string) => {
        return `${address.slice(0, 6)}...${address.slice(-4)}`;
    };

    const handleAddAddress = async () => {
        if (newAddress.address && newAddress.label && newCurrency.currency) {
            try {
                setLoading(true);

                const requestData = {
                    currency_name: newCurrency.currency,
                    address: newAddress.address,
                    label: newAddress.label,
                    is_autowithdraw: newCurrency.autoWithdraw
                };

                // Выполняем запрос на создание адреса
                const response = await apiRequest<Address>('/pay/user/profile/address', 'PATCH', requestData);

                // Получаем обновленный список адресов
                const updatedAddresses = await apiRequest<Address[]>('/pay/user/profile/addresses', 'GET');

                if (Array.isArray(updatedAddresses)) {
                    // Преобразуем адреса для отображения сокращенного адреса
                    const processedAddresses = updatedAddresses.map(address => ({
                        ...address,
                        fullAddress: address.address,
                        address: shortenAddress(address.address)
                    }));
                    setAddresses(processedAddresses);
                }

                // Сбрасываем форму
                setNewAddress({ address: '', label: '' });
                setNewCurrency({ address: '', label: '', currency: '', autoWithdraw: false });
                closeDialog();
            } catch (error) {
                console.error('Ошибка при добавлении адреса:', error);
                alert('Произошла ошибка при добавлении адреса. Пожалуйста, попробуйте снова.');
            } finally {
                setLoading(false);
            }
        } else {
            alert('Пожалуйста, заполните все поля.');
        }
    };

    const handleCopyToClipboard = (address: string, addressId: number) => {
        navigator.clipboard.writeText(address).then(() => {
            setTooltipAddressId(addressId); // Устанавливаем ID адреса для отображения тултипа

            setTimeout(() => {
                setTooltipAddressId(null); // Скрываем тултип через 15 секунд
            }, 1500); // Уменьшаем тайм-аут до 1500 мс (1.5 секунды), чтобы тултип не оставался слишком долго
        }).catch((error) => {
            console.error('Failed to copy address: ', error);
        });
    }

    const handleDeleteAddress = (e: React.MouseEvent, address: Address) => {
        e.stopPropagation();
        setAddressToDelete(address);
        if (deleteDialogRef.current) {
            deleteDialogRef.current.showModal();
        }
    };

    const confirmDeleteAddress = async () => {
        if (addressToDelete) {
            try {
                setLoading(true);

                // Здесь следует добавить запрос на удаление адреса, если он будет в API
                // const response = await apiRequest(`/pay/user/profile/address/${addressToDelete.id}`, 'DELETE');

                // Временное решение - обновляем локальный список
                const updatedAddresses = addresses.filter((address) => address.id !== addressToDelete.id);
                setAddresses(updatedAddresses);

                closeDeleteDialog();
            } catch (error) {
                console.error('Ошибка при удалении адреса:', error);
                alert('Произошла ошибка при удалении адреса. Пожалуйста, попробуйте снова.');
            } finally {
                setLoading(false);
            }
        }
    };

    const closeDeleteDialog = () => {
        if (deleteDialogRef.current) {
            deleteDialogRef.current.close();
        }
        setAddressToDelete(null);
    };

    const handleEditAddress = () => {
        setIsEditing(true);
        setConfirmChanges(false);
    };

    const handleCurrencyChange = (selectedOption) => {
        setNewCurrency({ ...newCurrency, currency: selectedOption.value });
    };

    const handleConfirmChanges = async () => {
        if (editedAddress) {
            try {
                setLoading(true);

                // Получаем ID редактируемого адреса из addresses
                const addressToEdit = addresses.find(addr =>
                    addr.fullAddress === editedAddress.address || addr.address === editedAddress.address
                );

                if (!addressToEdit) {
                    throw new Error('Адрес не найден');
                }

                // Отправляем запрос на обновление адреса
                const requestData = {
                    id: addressToEdit.id,
                    label: editedAddress.label,
                    is_autowithdraw: editedAddress.autoWithdraw
                };

                await apiRequest('/pay/user/profile/address', 'PATCH', requestData);

                // Получаем обновленный список адресов
                const updatedAddresses = await apiRequest<Address[]>('/pay/user/profile/addresses', 'GET');

                if (Array.isArray(updatedAddresses)) {
                    // Преобразуем адреса для отображения сокращенного адреса
                    const processedAddresses = updatedAddresses.map(address => ({
                        ...address,
                        fullAddress: address.address,
                        address: shortenAddress(address.address)
                    }));
                    setAddresses(processedAddresses);
                }

                closeDialog();
            } catch (error) {
                console.error('Ошибка при обновлении адреса:', error);
                alert('Произошла ошибка при обновлении адреса. Пожалуйста, попробуйте снова.');
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <div className={`${styles.main}`}>
            <div className={`${styles.mainHeader}`}>
                <h3 className={`${styles.mainTitle}`}>Saved addresses</h3>
                <button className={`${styles.mainButton}`} onClick={openDialog}>Add addresses</button>
            </div>
            <div className={`${styles.mainWrapper}`}>
                {loading ? (
                    <div className={`${styles.loading}`}>Loading...</div>
                ) : (
                    <>
                        {addresses.length > 0 && (
                            <div className={`${styles.mainLine}`}>
                                <div className={`${styles.mainCurrency} ${styles.mainText}`}>Currency</div>
                                <div className={`${styles.mainAddres} ${styles.mainText}`}>Address</div>
                                <div className={`${styles.mainLabel} ${styles.mainText}`}>Label</div>
                                <div className={`${styles.mainVoid}`}></div>
                            </div>
                        )}

                        <ul className={`${styles.mainList}`}>
                            {addresses.length === 0 ? (
                                <span className={`${styles.mainSpan}`}>No addresses found</span>
                            ) : (
                                addresses.map((address, index) => (
                                    <li
                                        key={index}
                                        className={`${styles.mainItem} ${address.is_autowithdraw ? styles.mainWithdrawEnabled : ''}`}
                                        onClick={() => openEditDialog(address)}
                                    >
                                        <div className={`${styles.mainCurrency} ${styles.mainItemText}`}>
                                            {address.currency.name}
                                            <span className={styles.currencyNetwork}>({address.currency.network})</span>
                                        </div>
                                        <div
                                            className={`${styles.mainAddress} ${styles.mainItemText}`}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleCopyToClipboard(address.fullAddress || address.address, address.id);
                                            }}
                                        >
                                            {tooltipAddressId === address.id && (
                                                <div className={`${styles.tooltip}`}>
                                                    Address copied!
                                                </div>
                                            )}
                                            <CopySvg className={`${styles.mainCopySvg}`} />
                                            {address.address}
                                        </div>
                                        <div className={`${styles.mainLabel} ${styles.mainItemText}`}>{address.label}</div>
                                        <button className={`${styles.mainDelete}`} onClick={(e) => handleDeleteAddress(e, address)}>
                                            <TrashSvg className={`${styles.mainTrashSvg}`} />
                                        </button>
                                    </li>
                                ))
                            )}
                        </ul>
                    </>
                )}
            </div>

            {/* Модалка для добавления адреса */}
            <dialog ref={dialogRef} className={`${styles.dialog} ${mediaStyles.dialog}`} onClick={handleBackdropClick}>
                <div className={`${styles.dialogBlock} ${mediaStyles.dialogBlock}`}>
                    <div className={`${styles.dialogContainer} ${mediaStyles.dialogContainer}`} onClick={(e) => e.stopPropagation()}>
                        <div className={`${styles.dialogHeader}`}>
                            <h2 className={`${styles.dialogTitle}`}>Add address</h2>
                            <button onClick={closeDialog}>X</button>
                        </div>
                        <div className={`${styles.dialogContent} ${mediaStyles.dialogContent}`}>
                            <label className={`${styles.dialogLabel} ${mediaStyles.dialogLabel}`}>
                                <span className={`${styles.dialogSpan}`}>Choose the currency</span>
                                {currenciesLoading ? (
                                    <div className={`${styles.loading}`}>Loading currencies...</div>
                                ) : (
                                    <Select
                                        placeholder='Выберите валюту'
                                        value={currencyOptions.find(option => option.value === newCurrency.currency) || null}
                                        onChange={handleCurrencyChange}
                                        options={currencyOptions}
                                        isSearchable={false}
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                borderColor: 'light-dark(var(--border-color-light), var(--border-color-dark))',
                                                backgroundColor: 'light-dark(var(--second-color-light), var(--second-color-dark))',
                                                color: 'light-dark(var(--text-color-light), var(--text-color-dark));',
                                                padding: '5px',
                                                borderRadius: '10px',
                                                cursor: 'pointer',
                                                height: '48px',
                                                boxShadow: 'none',
                                                ':hover': {
                                                    borderColor: 'light-dark(var(--border-color-light), var(--border-color-dark))',
                                                },
                                            }),
                                            singleValue: (provided) => ({
                                                ...provided,
                                                color: "light-dark(var(--text-color-light), var(--text-color-dark));",
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                backgroundColor: 'light-dark(var(--second-color-light), var(--second-color-dark))',
                                                borderRadius: '13px',
                                                border: '1px solid light-dark(var(--border-color-light), var(--border-color-dark))',
                                            }),
                                            menuList: (provided) => ({
                                                ...provided,
                                                padding: 0,
                                                borderRadius: '10px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: "2px"
                                            }),
                                            option: (provided) => ({
                                                ...provided,
                                                backgroundColor: "light-dark(var(--second-color-light), var(--second-color-dark))",
                                                color: 'light-dark(var(--text-color-light), var(--text-color-dark))',
                                                padding: '15px 19px',
                                                borderRadius: '10px',
                                                minHeight: '48px',
                                                transition: "background-color 0.2s ease",
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    backgroundColor: 'background-color: light-dark(var(--color-light), var(--color-dark));',
                                                },
                                            }),
                                            indicatorSeparator: (provided) => ({
                                                ...provided,
                                                display: 'none',
                                            }),
                                            dropdownIndicator: (provided) => ({
                                                ...provided,
                                                display: 'none',
                                            })
                                        }}
                                    />
                                )}
                            </label>
                            <label className={`${styles.dialogLabel} ${mediaStyles.dialogLabel}`}>
                                <span className={`${styles.dialogSpan}`}>Wallet address for transfer</span>
                                <input
                                    className={`${styles.dialogInput} ${mediaStyles.dialogInput}`}
                                    type="text"
                                    name="payout_EVM"
                                    value={newAddress.address}
                                    onChange={(e) => setNewAddress({ ...newAddress, address: e.target.value })}
                                    placeholder="Enter address"
                                />
                            </label>
                            <label className={`${styles.dialogLabel} ${mediaStyles.dialogLabel}`}>
                                <span className={`${styles.dialogSpan}`}>Label</span>
                                <input
                                    className={`${styles.dialogInput} ${mediaStyles.dialogInput}`}
                                    type="text"
                                    name="label"
                                    value={newAddress.label}
                                    onChange={(e) => setNewAddress({ ...newAddress, label: e.target.value })}
                                    placeholder="Enter label"
                                />
                            </label>
                            <div className={`${styles.mainBlock}`}>
                                <label className={`${styles.mainSwitch}`}>
                                    <input
                                        className={`${styles.mainInput}`}
                                        type="checkbox"
                                        checked={newCurrency.autoWithdraw}
                                        onChange={handleAutoWithdrawChangeForNew}
                                    />
                                    <span className={`${styles.mainSlider}`}></span>
                                </label>
                                <p className={`${styles.dialogSpan}`}>Включить автовывод</p>
                            </div>
                            <TwoFactorInput value={code} onChange={setCode} />
                        </div>
                        <button className={`${styles.dialogButton}`} onClick={handleAddAddress}>
                            Activate
                        </button>
                    </div>
                </div>
            </dialog>

            {/* Модалка для подтверждения удаления адреса */}
            <dialog ref={deleteDialogRef} className={`${styles.dialog} ${mediaStyles.dialog}`} onClick={handleBackdropClick}>
                <div className={`${styles.dialogBlock} ${mediaStyles.dialogBlock}`}>
                    <div className={`${styles.dialogContainer} ${mediaStyles.dialogContainer}`}>
                        <div className={`${styles.dialogHeader}`}>
                            <h2 className={`${styles.dialogTitle}`}>Confirm Deletion</h2>
                            <button onClick={closeDeleteDialog}>X</button>
                        </div>
                        <div className={`${styles.dialogContent} ${mediaStyles.dialogContent}`}>
                            {addressToDelete ? (
                                <p>
                                    Are you sure you want to delete this address: <strong>{addressToDelete.label}</strong> ({addressToDelete.currency.name})?
                                </p>
                            ) : (
                                <p>Address not found.</p> // В случае, если нет выбранного адреса для удаления
                            )}

                            <TwoFactorInput value={code} onChange={setCode} />
                        </div>
                        <button className={`${styles.dialogButton}`} onClick={confirmDeleteAddress}>
                            Confirm
                        </button>
                    </div>
                </div>
            </dialog>

            {/* Модалка для редактирования адреса */}
            <dialog ref={editDialogRef} className={`${styles.dialog} ${mediaStyles.dialog}`} onClick={handleBackdropClick}>
                <div className={`${styles.dialogBlock} ${mediaStyles.dialogBlock}`}>
                    <div className={`${styles.dialogContainer} ${mediaStyles.dialogContainer}`} onClick={(e) => e.stopPropagation()}>
                        <div className={`${styles.dialogHeader}`}>
                            <h2 className={`${styles.dialogTitle}`}>{isEditing ? 'Edit address' : 'View address'}</h2>
                            <button onClick={closeDialog}>X</button>
                        </div>
                        <div className={`${styles.dialogContent} ${mediaStyles.dialogContent}`}>

                            <label className={`${styles.dialogLabel} ${mediaStyles.dialogLabel}`}>
                                <span className={`${styles.dialogSpan}`}>Wallet address for transfer</span>
                                <input
                                    className={`${styles.dialogInput} ${mediaStyles.dialogInput}`}
                                    type="text"
                                    name="payout_EVM"
                                    value={editedAddress?.address || ''}
                                    onChange={(e) => setEditedAddress({ ...editedAddress!, address: e.target.value })}
                                    placeholder="Enter address"
                                    disabled={!isEditing} // Блокируем инпут, если не редактируем
                                />
                            </label>
                            <label className={`${styles.dialogLabel} ${mediaStyles.dialogLabel}`}>
                                <span className={`${styles.dialogSpan}`}>Label</span>
                                <input
                                    className={`${styles.dialogInput} ${mediaStyles.dialogInput}`}
                                    type="text"
                                    name="label"
                                    value={editedAddress?.label || ''}
                                    onChange={(e) => setEditedAddress({ ...editedAddress!, label: e.target.value })}
                                    placeholder="Enter label"
                                    disabled={!isEditing} // Блокируем инпут, если не редактируем
                                />
                            </label>
                            {isEditing && <TwoFactorInput value={code} onChange={setCode} />} {/* Показываем 2FA, если редактируем */}
                        </div>
                        {isEditing ? (
                            <button className={`${styles.dialogButton}`} onClick={handleConfirmChanges}>
                                Confirm
                            </button>
                        ) : (
                            <button className={`${styles.dialogButton}`} onClick={handleEditAddress}>
                                Edit
                            </button>
                        )}
                    </div>
                </div>
            </dialog>
        </div>
    );
};

export default Addresses;
