import React, { useRef, useEffect, useState, useMemo } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title, PieController } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useTranslation } from 'react-i18next';
import { useDashboardData } from '../DataProvider/DashboardDataProvider.tsx';

// @ts-ignore
import styles from './Circular.module.scss';
// @ts-ignore
import mediaStyles from './Circular-media.module.scss';

// Регистрация необходимых контроллеров и элементов
ChartJS.register(ArcElement, Tooltip, Legend, Title, PieController, ChartDataLabels);

const SecondGraph: React.FC<{ period: string }> = ({ period }) => {
    const chartRef = useRef<HTMLCanvasElement | null>(null); // Ссылка на canvas
    const chartInstanceRef = useRef<ChartJS | null>(null); // Ссылка на экземпляр графика
    const { data, isLoading, error } = useDashboardData();

    const [chartData, setChartData] = useState({
        trx: { amount: 0 },
        bnb: { amount: 0 },
        eth: { amount: 0 }
    });

    const [isDarkMode, setIsDarkMode] = useState(() => {
        // При загрузке проверяем сохраненную тему из localStorage
        const savedTheme = localStorage.getItem('theme');
        if (savedTheme) {
            return savedTheme === 'dark'; // Если тема сохранена, используем её
        }
        // Если темы нет в localStorage, проверяем системные настройки
        return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    });

    const { i18n } = useTranslation();
    const periodText = {
        day: { label: i18n.t('day'), subtitle: i18n.t('dailyStatistic') },
        week: { label: i18n.t('week'), subtitle: i18n.t('weeklyStatistic') },
        month: { label: i18n.t('month'), subtitle: i18n.t('moonthlyStatistic') }
    };
    const currentPeriodText = periodText[period] || periodText.day;

    // Преобразуем данные транзакций в данные графика
    useEffect(() => {
        if (data && data[period]) {
            const transactions = data[period];

            // Объект для подсчета сумм по типам валют
            const amounts = {
                trx: 0,
                bnb: 0,
                eth: 0
            };

            // Для отладки: счетчики транзакций по валютам
            const counts = {
                trx: 0,
                bnb: 0,
                eth: 0,
                other: 0
            };

            // Суммируем amount_usd для каждой валюты
            transactions.forEach(tx => {
                // Проверяем структуру поля currency - если это объект, берем name, иначе используем как строку
                const currencyName = typeof tx.currency === 'object' && tx.currency !== null ?
                    (tx.currency.name || '').toUpperCase() :
                    (tx.currency || '').toUpperCase();

                // Специальная обработка для USDTERC20
                if (currencyName === 'USDTERC20') {
                    amounts.eth += tx.amount_usd;
                    counts.eth++;
                }
                // Проверяем включает ли валюта подстроки TRX, BNB или ERC (для Ethereum)
                else if (currencyName.includes('TRX')) {
                    amounts.trx += tx.amount_usd;
                    counts.trx++;
                } else if (currencyName.includes('BNB')) {
                    amounts.bnb += tx.amount_usd;
                    counts.bnb++;
                } else if (currencyName.includes('ERC') || currencyName.includes('ETH')) {
                    amounts.eth += tx.amount_usd;
                    counts.eth++;
                } else {
                    // Попробуем распознать валюту по содержанию строки
                    if (currencyName.includes('USDT')) {
                        // Любые USDT валюты добавляем в ETH
                        amounts.eth += tx.amount_usd;
                        counts.eth++;
                    } else {
                        counts.other++;
                    }
                }
            });

            // Устанавливаем данные для графика
            setChartData({
                trx: { amount: amounts.trx },
                bnb: { amount: amounts.bnb },
                eth: { amount: amounts.eth }
            });
        }
    }, [data, period]);

    const renderChart = (chartData: any) => {
        const totalTrx = chartData.trx.amount;
        const totalBnb = chartData.bnb.amount;
        const totalEth = chartData.eth.amount;
        const totalAmount = totalEth + totalBnb + totalTrx;

        // Предотвращаем деление на ноль
        if (totalAmount === 0) {
            // Если нет данных, просто очищаем график
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
                chartInstanceRef.current = null;
            }
            return;
        }
        const percentageTrx = (totalTrx / totalAmount) * 100;
        const percentageBnb = (totalBnb / totalAmount) * 100;
        const percentageEth = (totalEth / totalAmount) * 100;
        const percentTotalAmount = (percentageEth + percentageBnb + percentageTrx);

        // Проверяем и уничтожаем старую диаграмму, если она существует
        if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
        }

        // Создаем новый график
        const chart2 = new ChartJS(chartRef.current!, {
            type: 'pie',
            data: {
                labels: ['Tron', 'Binance', 'Ethereum'],
                datasets: [
                    {
                        label: 'Tron & Binance & Ethereum',
                        data: [
                            percentageTrx,
                            percentageBnb,
                            percentageEth
                        ],
                        backgroundColor: ['#FF0066', '#FFB743', '#776AF3'],
                        hoverBackgroundColor: ['#FF0066', '#FFB743', '#776AF3'],
                        borderColor: ['#FF0066', '#FFB743', '#776AF3'],
                        hoverBorderColor: ['#FF0066', '#FFB743', '#776AF3'],
                        borderRadius: 15,
                        borderWidth: 0,
                        weight: .2, //изменяет ширину полоски
                        spacing: 3, //изменяет расстояние между полосками
                        hoverBorderWidth: 3, // Увеличивает ширину бордера при наведении
                    },
                    {
                        label: 'Total amount',
                        data: [percentTotalAmount],
                        backgroundColor: isDarkMode ? '#121212' : '#f5f7fa',
                        hoverBackgroundColor: isDarkMode ? '#121212' : '#f5f7fa',
                        borderColor: isDarkMode ? '#121212' : '#f5f7fa',
                        hoverBorderColor: isDarkMode ? '#121212' : '#f5f7fa',
                        borderWidth: 0,
                        hoverBorderWidth: 7,
                        // @ts-ignore
                        radius: '85%',
                    }
                ]
            },
            options: {
                // @ts-ignore
                responsive: true, // Устанавливает график, чтобы он автоматически подстраивался
                maintainAspectRatio: false, // Отключает ограничение пропорций
                aspectRatio: 1, // Пропорции канваса
                layout: {
                    padding: {
                        top: 20,
                        right: 30,
                        bottom: 20,
                        left: 30,
                    },
                },

                plugins: {
                    tooltip: {
                        enabled: true,
                        position: 'nearest',
                        displayColors: false,
                        borderWidth: 50,
                        callbacks: {
                            title: function (tooltipItem) {
                                const datasetLabel = tooltipItem[0].dataset.label;
                                const dataLabel = tooltipItem[0].label;

                                if (datasetLabel === 'Total amount') {
                                    return `${i18n.t('amount')}`;
                                } else if (datasetLabel === 'Tron & Binance & Ethereum') {
                                    return dataLabel;
                                }
                            },

                            label: function (tooltipItem) {
                                const datasetLabel = tooltipItem.dataset.label;
                                const dataIndex = tooltipItem.dataIndex;

                                if (datasetLabel === 'Tron & Binance & Ethereum') {
                                    // Выводим проценты для каждого сектора
                                    if (dataIndex === 0) {
                                        return totalTrx.toFixed(0) + '$';
                                    } else if (dataIndex === 1) {
                                        return totalBnb.toFixed(0) + '$';
                                    } else if (dataIndex === 2) {
                                        return totalEth.toFixed(0) + '$';
                                    }
                                } else if (datasetLabel === 'Total amount') {
                                    // Выводим значение total amount
                                    return totalAmount.toFixed(0) + '$';
                                }
                            }


                        }
                    },
                    legend: {
                        display: false,
                    },
                    datalabels: {
                        color: isDarkMode ? 'white' : 'black',
                        textAlign: 'center',
                        formatter: function (value, context) {
                            // Возвращаем индивидуальное значение для каждого сегмента
                            return Math.round(value) + '%'
                        },
                        // @ts-ignore
                        anchor: function (context) {
                            const index = context.dataIndex;
                            const datasetIndex = context.datasetIndex;

                            // Для каждого набора данных устанавливаем якорь
                            if (datasetIndex === 0) { // Для первого набора данных (Paid & Unpaid)
                                if (index === 0) return 'center';  // Для Paid
                                if (index === 1) return 'center';    // Для Unpaid
                                if (index === 2) return 'center';    // Для Unpaid
                            } else if (datasetIndex === 1) { // Для второго набора данных (TotalAmount)
                                return 'center'; // TotalAmount будет по центру
                            }

                        },
                        // @ts-ignore
                        align: function (context) {
                            const index = context.dataIndex;
                            const datasetIndex = context.datasetIndex;

                            // Для каждого набора данных задаем выравнивание
                            if (datasetIndex === 0) {  // Для первого набора данных (Paid & Unpaid)
                                if (index === 0) return 'end';   // Для Paid
                                if (index === 1) return 'end';  // Для Unpaid
                                if (index === 2) return 'end';  // Для Unpaid
                            } else if (datasetIndex === 1) {  // Для второго набора данных (TotalAmount)
                                return 'top';  // TotalAmount будет по центру
                            }
                        },
                        // @ts-ignore
                        offset: function (context) {
                            const index = context.dataIndex;
                            const datasetIndex = context.datasetIndex;

                            // Для каждого набора данных задаем смещение
                            if (datasetIndex === 0) { // Для первого набора данных (Paid & Unpaid)
                                if (index === 0) return 10;  // Для Paid
                                if (index === 1) return 10;  // Для Unpaid
                                if (index === 2) return 10;  // Для Unpaid
                            } else if (datasetIndex === 1) { // Для второго набора данных (TotalAmount)
                                return 10;  // TotalAmount по центру
                            }
                        },
                        // @ts-ignore
                        clamp: function (context) {
                            const index = context.dataIndex;
                            const datasetIndex = context.datasetIndex;

                            // Для каждого набора данных задаем смещение
                            if (datasetIndex === 0) { // Для первого набора данных (Paid & Unpaid)
                                if (index === 0) return false;  // Для Paid
                                if (index === 1) return false;  // Для Unpaid
                                if (index === 2) return false;  // Для Unpaid
                            } else if (datasetIndex === 1) { // Для второго набора данных (TotalAmount)
                                return true;  // TotalAmount по центру
                            }

                        },
                    }
                }
            },
            plugins: [ChartDataLabels]
        });

        // Сохраняем ссылку на график
        chartInstanceRef.current = chart2;
    }

    useEffect(() => {
        renderChart(chartData);

        // Очистка после обновления компонента
        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
            }
        };
    }, [chartData]);

    return (
        <div className={`${styles.chartItem} ${mediaStyles.chartItem}`}>
            <div className={`${styles.chartWrapper} ${mediaStyles.chartWrapper}`}>
                <div className={`${styles.chartTitle} ${mediaStyles.chartTitle}`}>
                    {i18n.t('transactionsType')}
                    <span className={`${styles.chartSpan} ${mediaStyles.chartSpan}`}>({currentPeriodText.label})</span>
                </div>
                <div className={`${styles.chartSubtitle} ${mediaStyles.chartSubtitle}`}>{currentPeriodText.subtitle}</div>
            </div>
            <div className={`${styles.graphContainer} ${mediaStyles.graphContainer}`}>
                {isLoading ? (
                    <div className={`${styles.loading}`} style={{ textAlign: 'center', padding: '40px' }}>Loading...</div>
                ) : error ? (
                    <div className={`${styles.error}`} style={{ textAlign: 'center', padding: '40px', color: 'red' }}>
                        {error}
                    </div>
                ) : chartData.trx.amount === 0 && chartData.bnb.amount === 0 && chartData.eth.amount === 0 ? (
                    <div className={`${styles.noData}`} style={{ textAlign: 'center', padding: '40px', fontSize: '18px' }}>
                        No Data
                    </div>
                ) : (
                    <>
                        <div className={`${styles.graph} ${mediaStyles.graph}`}>
                            <canvas ref={chartRef} className={`${styles.graph} ${mediaStyles.graph}`} id="chart2"></canvas>
                        </div>
                        <div className={`${styles.chartTableSecond} ${mediaStyles.chartTableSecond}`}>
                            <div className={`${styles.chartText} ${mediaStyles.chartText}`}><span style={{ backgroundColor: "#FF0066" }}
                                className={`${styles.chartBox} ${mediaStyles.chartBox}`}></span>TRX</div>
                            <div className={`${styles.chartText} ${mediaStyles.chartText}`}><span style={{ backgroundColor: "#FFB743" }}
                                className={`${styles.chartBox} ${mediaStyles.chartBox}`}></span>BNB</div>
                            <div className={`${styles.chartText} ${mediaStyles.chartText}`}><span style={{ backgroundColor: "#776AF3" }}
                                className={`${styles.chartBox} ${mediaStyles.chartBox}`}></span>ETH</div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default SecondGraph;
