import React, { useState, useEffect, useRef } from 'react';
import GLightbox from 'glightbox';
// import { initialMessages } from '../../../data/data.ts';
import { PaperclipSvg } from '../../../assets/svg-files/SvgIcons.ts';
import { apiRequest } from '../../../data/Api.ts';
//@ts-ignore
import styles from './TicketPage.module.scss'; // Импорт стилей

interface Ticket {
    id: string;
    subject: string;
    status: string;
    messages: { created_at: string; message: string; photo?: string; from_support: boolean }[];
}

const TicketPage = () => {
    const [ticket, setTicket] = useState<Ticket | null>(null);
    const [messageContent, setMessageContent] = useState('');
    const [attachment, setAttachment] = useState<File | null>(null);
    const [isSending, setIsSending] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);

    // Получаем id тикета из URL
    const getTicketIdFromUrl = () => {
        const pathArray = window.location.pathname.split('/');
        return pathArray[pathArray.length - 1];
    };

    const ticketId = getTicketIdFromUrl();

    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const chatRef = useRef<HTMLDivElement>(null);

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMessageContent(e.target.value);
    };

    useEffect(() => {
        const textarea = textareaRef.current;
        if (textarea) {
            textarea.style.height = '42px';
            const scrollHeight = textarea.scrollHeight;
            const newHeight = Math.min(scrollHeight, 150);
            if (wrapperRef.current) {
                wrapperRef.current.style.height = `${newHeight}px`;
            }
            textarea.style.height = `${newHeight}px`;
        }
    }, [messageContent]);

    // Загрузка тикета
    useEffect(() => {
        const fetchTicket = async () => {
            try {
                setLoading(true);
                const data = await apiRequest<Ticket>(`/pay/user/support/ticket/${ticketId}`, 'GET');
                setTicket(data);
                setError(null);
            } catch (error) {
                console.error('Ошибка при загрузке тикета:', error);
                setError('Не удалось загрузить тикет. Пожалуйста, попробуйте снова.');
            } finally {
                setLoading(false);
            }
        };

        fetchTicket();
    }, [ticketId]);

    // Инициализация лайтбокса для просмотра изображений
    useEffect(() => {
        if (ticket) {
            GLightbox({ selector: 'a[data-gallery="gallery-undefined"]' });
        }
    }, [ticket]);

    // Форматирование времени сообщения
    const formatMessageTime = (date: Date) => {
        const now = new Date();
        const yesterday = new Date(now);
        yesterday.setDate(now.getDate() - 1);

        const isToday = date.toDateString() === now.toDateString();
        const isYesterday = date.toDateString() === yesterday.toDateString();

        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        if (isToday) {
            return `Today, ${hours}:${minutes}`;
        } else if (isYesterday) {
            return `Yesterday, ${hours}:${minutes}`;
        } else {
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = String(date.getFullYear()).slice(-2);
            return `${day}.${month}.${year}, ${hours}:${minutes}`;
        }
    };

    // Отправка сообщения
    const handleSendMessage = async () => {
        if (messageContent.trim() === '') {
            alert('Пожалуйста, введите сообщение для отправки.');
            return;
        }

        setIsSending(true);

        const formData = new FormData();
        formData.append('message', messageContent);

        if (attachment) {
            formData.append('photo', attachment);
        }

        try {
            // Используем apiRequest с FormData
            const message = await apiRequest<{ photo?: string }>(`/pay/user/support/ticket/${ticketId}`, 'POST', formData);

            // Добавляем новое сообщение в список
            if (ticket) {
                const newMessage = {
                    created_at: new Date().toISOString(),
                    message: messageContent,
                    from_support: false,
                    photo: message.photo
                };

                setTicket({
                    ...ticket,
                    messages: [...ticket.messages, newMessage]
                });
            }

            // Сбрасываем форму
            setMessageContent('');
            setAttachment(null);

            // Уведомляем пользователя
            showSuccessToast('Сообщение отправлено!');

            // Обновляем лайтбокс для новых изображений
            setTimeout(() => {
                GLightbox({ selector: 'a[data-gallery="gallery-undefined"]' });

                // Прокрутка к последнему сообщению
                if (chatRef.current) {
                    chatRef.current.scrollTop = chatRef.current.scrollHeight;
                }
            }, 100);
        } catch (error) {
            console.error('Ошибка при отправке сообщения:', error);
            showErrorToast('Произошла ошибка. Пожалуйста, попробуйте снова.');
        } finally {
            setIsSending(false);
        }
    };

    const showSuccessToast = (message: string) => {
        alert(message);
    };

    const showErrorToast = (message: string) => {
        alert(message);
    };

    const handleAttachmentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0] || null;
        setAttachment(file);

        const fileNameElement = document.getElementById("chatAttachmentName");
        if (fileNameElement) {
            fileNameElement.textContent = file ? file.name : '';
        }
    };

    if (loading) {
        return <div className={styles.loading}>Загрузка...</div>;
    }

    if (error) {
        return <div className={styles.error}>{error}</div>;
    }

    if (!ticket) {
        return <div className={styles.noTicket}>Тикет не найден</div>;
    }


    return (
        <div className={`${styles.main}`}>
            <a className={`${styles.mainBack}`} href="/pages/Support">
                Go back
            </a>

            <div className={`${styles.mainContent}`}>
                <div className={`${styles.mainHeader}`}>
                    <h3 className={`${styles.mainTitle}`}>
                        Ticket #{ticket.id} - {ticket.subject}
                    </h3>
                    <span className={`${styles.mainStatus}`}>
                        {ticket.status === 'in_progress' ? (
                            <span className={`${styles.mainStatus} ${styles.mainStatusInProgress}`}>In progress</span>
                        ) : ticket.status === 'freeze' ? (
                            <span className={`${styles.mainStatus} ${styles.mainStatusFreezed}`}>Freezed</span>
                        ) : (
                            <span className={`${styles.mainStatus} ${styles.mainStatusClosed}`}>Closed</span>
                        )}
                    </span>
                </div>

                <div className={`${styles.mainChat}`} ref={chatRef}>
                    {ticket.messages.map((message, index) => {
                        const time = formatMessageTime(new Date(message.created_at));
                        let messageContentHTML: React.ReactNode = '';

                        if (message.photo) {
                            const fileExtension = message.photo.split('.').pop()?.toLowerCase();
                            if (fileExtension === 'pdf') {
                                messageContentHTML = (
                                    <a
                                        href={`${process.env.REACT_APP_API_URL}/${message.photo}`}
                                        target="_blank"
                                        className={`${styles.mainLinkToPdf}`}
                                    >
                                        <div className={`${styles.mainBlockPdf}`}>
                                            <span className={`${styles.mainPdf}`}></span>
                                            <span className={`${styles.mainOpenPdf}`}>Open PDF</span>
                                        </div>
                                    </a>
                                );
                            } else {
                                messageContentHTML = (
                                    <a href={`${process.env.REACT_APP_API_URL}/${message.photo}`} data-gallery="gallery-undefined" className={`${styles.mainLinkToPhoto}`}>
                                        <img
                                            className={`${styles.mainPhoto}`}
                                            src={`${process.env.REACT_APP_API_URL}/${message.photo}`}
                                            alt="Photo"
                                            style={{ maxWidth: '200px' }}
                                        />
                                    </a>
                                );
                            }
                        }

                        return (
                            <div key={index} className={`${styles.mainСhatMessage} ${message.from_support ? '' : styles.mainСhatMessageСlient}`}>
                                <div className={`${styles.mainСhatMessageContainer} ${message.from_support ? '' : styles.mainСhatMessageСlient}`}>
                                    <div className={`${styles.mainСhatMessageWrapper}`}>
                                        <div className={`${styles.mainСhatMessageHoverTrigger}`}>
                                            <div className={`${styles.mainСhatMessageContent}`}>
                                                <div
                                                    className={`${styles.mainСhatMessageMessage} ${message.from_support ? styles.receivedMessageContent : styles.sentMessageContent} ${styles.border} ${styles.rounded2} ${styles.p3}`}
                                                >
                                                    <p className={styles.mainСhatMessageText}>{message.message}</p>
                                                    {messageContentHTML}
                                                    <p className={`${styles.mainСhatMessageTime}`}>{time}</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>

                {/* Отключаем форму отправки сообщений для закрытых и замороженных тикетов */}
                {ticket.status === 'close' ? (
                    <div className={`${styles.mainFooterDisabled}`}>
                        <p className={`${styles.mainFooterDisabledText}`}>Этот тикет был закрыт</p>
                    </div>
                ) : ticket.status === 'freeze' ? (
                    <div className={`${styles.mainFooterDisabled}`}>
                        <p className={`${styles.mainFooterDisabledText}`}>Этот тикет был заморожен</p>
                    </div>
                ) : (
                    <div className={`${styles.mainFooter}`}>
                        <div className={`${styles.mainFooterWrapper}`}>
                            <div className={`${styles.mainFooterItem}`}>
                                <div ref={wrapperRef} className={`${styles.mainFooterTextareaWrapper}`}>
                                    <textarea
                                        ref={textareaRef}
                                        className={`${styles.mainFooterTextarea}`}
                                        value={messageContent}
                                        onChange={handleInputChange}
                                        placeholder="Type your message..."
                                    />
                                </div>
                                <label className={`${styles.mainFooterLabel}`} htmlFor="chatAttachment">
                                    <span className={`${styles.mainFooterLabelSpan}`}>
                                        <PaperclipSvg className={`${styles.mainFooterSvg}`} />
                                    </span>
                                </label>
                                <input
                                    className={`${styles.mainFooterInput}`}
                                    type="file"
                                    accept=".png, .jpg, .jpeg, .pdf"
                                    id="chatAttachment"
                                    onChange={handleAttachmentChange}
                                />
                                <span className={`${styles.mainFooterFileName}`} id="chatAttachmentName">
                                    {attachment ? attachment.name : ''}
                                </span>
                            </div>
                            <div>
                                <button
                                    className={`${styles.mainFooterButton}`}
                                    type="button"
                                    onClick={handleSendMessage}
                                    disabled={isSending}
                                >
                                    {isSending ? 'Отправка...' : 'Отправить'} <span className="fa-solid fa-paper-plane ms-1"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TicketPage;
