import React, { useState } from 'react';
import { format } from 'date-fns';
import LocalizedCalendar from '../../components/common/LocalizedCalendar.tsx';
// @ts-ignore
import styles from './Balance.module.scss';
import { ArrowSvg } from '../../assets/svg-files/SvgIcons.ts'; // Импорт компонента стрелки, если нужно

const ExportModal = ({
    closeDialog,
    downloadsDialog,
    filterOptions,
    selectedOptions,
    i18n,
    handleCheckboxChange,
    handleCategoryClick,
    handleSearchChange,
    searchQuery,
    filteredResults,
    isAllTime,
    startDate,
    endDate,
    handleDateChange,
    selectedDates,
    isCalendarVisible,
    handleCalendarToggle,
    calendarButtonRef,
    handleExportToEmail,
    handleExportToExcel
}) => {
    const [activeCategory, setActiveCategory] = useState<string | null>(null);

    return (
        <dialog ref={downloadsDialog} onClick={closeDialog} className={styles.dialog}>
            <div className={`${styles.balanceDialogHeader}`}>
                <h3 className={`${styles.balanceDialogTitle}`}>Export Data</h3>
                <button onClick={closeDialog} className={`${styles.balanceDialogCross}`}>X</button>
            </div>

            <div className={`${styles.balanceDialogWrapper}`}>
                {/* Поиск фильтров */}
                <div className={styles.balanceFilterSearch}>
                    <input
                        className={styles.balanceFilterInput}
                        type="text"
                        placeholder="Search filters..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                </div>

                {searchQuery ? (
                    <ul className={styles.balanceFilterUl}>
                        {filteredResults.map(({ category, option }) => (
                            <li key={option} className={styles.balanceFilterItem}>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={selectedOptions[category]?.has(option) || false}
                                        onChange={() => handleCheckboxChange(category, option)}
                                    />
                                    {option} <span className={styles.filterCategory}>({category})</span>
                                </label>
                            </li>
                        ))}
                    </ul>
                ) : activeCategory ? (
                    <>
                        <ul className={styles.balanceFilterUl}>
                            {filterOptions[activeCategory].map((option) => (
                                <li key={option}>
                                    <label className={styles.balanceFilterItem}>
                                        {option}
                                        <input
                                            type="checkbox"
                                            checked={selectedOptions[activeCategory]?.has(option) || false}
                                            onChange={() => handleCheckboxChange(activeCategory, option)}
                                            className={`${styles.balanceDialogFilterCheckbox}`}
                                        />
                                    </label>
                                </li>
                            ))}
                        </ul>
                        <button className={styles.balanceBackButton} onClick={() => setActiveCategory(null)}>
                            <ArrowSvg className={styles.balanceBackArrow} />Back
                        </button>
                    </>
                ) : (
                    <ul className={styles.balanceFilterUl}>
                        {Object.keys(filterOptions).map((category) => (
                            <li
                                key={category}
                                className={styles.balanceFilterItem}
                                onClick={() => handleCategoryClick(category)}
                            >
                                {category}
                                <ArrowSvg className={styles.balanceFilterArrow} />
                            </li>
                        ))}
                    </ul>
                )}

                {/* Фильтр по Date */}
                <li>
                    <label className={`${styles.balanceDialogLabel}`}>
                        Date
                        <input
                            type="checkbox"
                            checked={selectedOptions.date || false}
                            onChange={() => handleCheckboxChange('date')}
                            className={`${styles.balanceDialogCheckbox}`}
                        />
                    </label>
                    {isCalendarVisible && (
                        <div className={styles.calendarContainer}>
                            <LocalizedCalendar
                                selectRange={true}
                                onChange={handleDateChange}
                                value={selectedDates}
                                minDetail="year"
                            />
                            <button onClick={() => handleCalendarToggle(false)} className={styles.closeButton}>
                                {i18n.t('calendar.buttons.close')}
                            </button>
                        </div>
                    )}
                </li>

                {/* Кнопка экспорта */}
                <div className={`${styles.balanceDialogButtons}`}>
                    <button className={`${styles.balanceDialogSave}`} onClick={handleExportToEmail}>Export to Email</button>
                    <button className={`${styles.balanceDialogSave}`} onClick={handleExportToExcel}>Download Excel</button>
                </div>
            </div>
        </dialog>
    );
};

export default ExportModal;
