import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { apiRequest } from '../../../../data/Api.ts';

// Типы для данных транзакций
interface Transaction {
    id: number;
    tx_type: string;
    api_label: string;
    label: string;
    currency: string;
    amount: number;
    amount_usd: number;
    network_fee: number;
    network_fee_usd: number;
    service_fee: number;
    service_fee_usd: number;
    tx_hash: string;
    is_paid: boolean;
    is_postback_sended: boolean;
    created_at: string;
}

// Тип для всех данных дашборда
interface DashboardData {
    day: Transaction[];
    week: Transaction[];
    month: Transaction[];
}

// Интерфейс контекста
interface DashboardDataContextType {
    data: DashboardData | null;
    isLoading: boolean;
    error: string | null;
    refreshData: () => Promise<void>;
}

// Создаем контекст
const DashboardDataContext = createContext<DashboardDataContextType | undefined>(undefined);

// Хук для использования контекста в компонентах
export const useDashboardData = () => {
    const context = useContext(DashboardDataContext);
    if (context === undefined) {
        throw new Error('useDashboardData must be used within a DashboardDataProvider');
    }
    return context;
};

// Компонент-провайдер
interface DashboardDataProviderProps {
    children: ReactNode;
}

export const DashboardDataProvider: React.FC<DashboardDataProviderProps> = ({ children }) => {
    const [data, setData] = useState<DashboardData | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    // Функция для загрузки данных
    const fetchData = async () => {
        setIsLoading(true);
        setError(null);

        try {
            let response;
            try {
                // Пытаемся получить данные с сервера
                response = await apiRequest('/pay/user/dashboard/transactions', 'GET');
                if (response) {
                    // Подготавливаем объект данных
                    let dashboardData: DashboardData;

                    // Проверяем формат ответа
                    if (Array.isArray(response)) {
                        // Если ответ - массив, преобразуем его в нужный формат
                        dashboardData = {
                            day: response,
                            week: response,
                            month: response
                        };
                    } else if (response.day || response.week || response.month) {
                        // Стандартный формат ответа с периодами
                        dashboardData = {
                            day: response.day || [],
                            week: response.week || [],
                            month: response.month || []
                        };
                    } else {
                        throw new Error('Неизвестный формат ответа от API');
                    }

                    setData(dashboardData);
                } else {
                    throw new Error('Ответ от сервера пуст');
                }
            } catch (apiError) {
                setError(`Ошибка получения данных: ${apiError.message || 'Неизвестная ошибка'}`);
                setData({ day: [], week: [], month: [] }); // Устанавливаем пустые данные
            }
        } catch (err) {
            setError('Произошла ошибка при загрузке данных');

            // Устанавливаем пустые данные при ошибке
            setData({ day: [], week: [], month: [] });
        } finally {
            setIsLoading(false);
        }
    };

    // Загружаем данные при монтировании компонента
    useEffect(() => {
        fetchData();
    }, []);

    // Предоставляем данные и функции через контекст
    const value = {
        data,
        isLoading,
        error,
        refreshData: fetchData
    };

    return (
        <DashboardDataContext.Provider value={value}>
            {children}
        </DashboardDataContext.Provider>
    );
};

export default DashboardDataProvider; 