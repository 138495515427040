import React, { Component } from 'react'
import Topups from './components/topups/Topups.tsx'
import Periods from './components/periods/Periods.tsx';
import FirstGraph from './components/circular/FirstGraph.tsx';
import SecondGraph from './components/circular/SecondGraph.tsx';
import Graph from './components/graph/Graph.tsx';
import Transactions from './components/transactions/Transactions.tsx';
import { DashboardDataProvider } from './components/DataProvider/DashboardDataProvider.tsx';
// @ts-ignore
import styles from './Dashboard.module.scss';
// @ts-ignore
import mediaStyles from './Dashboard-media.module.scss';



export default class Dashboard extends Component {
    state = {
        selectedPeriod: 'day' // Начальный период
    };

    handlePeriodChange = (period: string) => {
        // @ts-ignore
        this.setState({ selectedPeriod: period });
    };

    render() {
        const { selectedPeriod } = this.state;
        return (
            <DashboardDataProvider>
                <Topups />
                <Periods selectedPeriod={selectedPeriod} onPeriodChange={this.handlePeriodChange} />
                <div className={`${styles.chart} ${mediaStyles.chart}`}>
                    <FirstGraph period={selectedPeriod} />
                    <SecondGraph period={selectedPeriod} />
                </div>
                <Graph
                    // @ts-ignore
                    selectedPeriod={selectedPeriod} />
                <Transactions
                    // @ts-ignore
                    selectedPeriod={selectedPeriod} />
            </DashboardDataProvider>
        )
    }
}
