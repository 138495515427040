import React, { useState, useRef, useEffect } from 'react';
import { IpSvg, TimeSvg, PlanetSvg, DeviceSvg, FingerprintSvg, MiniQrSvg } from '../../../../assets/svg-files/SvgIcons.ts';
import { apiRequest, getUserProfile } from '../../../../data/Api.ts';
// @ts-ignore
import styles from './Safety.module.scss';
// @ts-ignore
import mediaStyles from './Safety-media.module.scss';

interface AuthorizationEntry {
    ip: string;
    device: string;
    location: string;
    created_at: string;
}

interface TwoFAStatus {
    enabled: boolean;
}

interface TwoFAData {
    qr_code: string;
    secret: string;
}

interface TwoFAResponse {
    success: boolean;
    message?: string;
}

const Safety = () => {
    const dialogRef = useRef<HTMLDialogElement | null>(null);
    const [qrCode, setQrCode] = useState<string | null>(null);
    const [secret, setSecret] = useState<string | null>(null);
    const [step, setStep] = useState(1);
    const [code, setCode] = useState('');
    const [is2faEnabled, setIs2faEnabled] = useState(false);
    const [isTgEnabled, setisTgEnabled] = useState(false);
    const [authHistory, setAuthHistory] = useState<AuthorizationEntry[]>([]);
    const [loading, setLoading] = useState(true);
    const [profileLoading, setProfileLoading] = useState(true);

    // Получение данных профиля пользователя
    useEffect(() => {
        const fetchUserProfileData = async () => {
            try {
                setProfileLoading(true);
                const profileData = await getUserProfile();

                // Обновляем состояние 2FA на основе данных профиля
                setIs2faEnabled(profileData.is_2fa_enabled);

                // Обновляем состояние Telegram на основе данных профиля
                setisTgEnabled(profileData.telegram_id !== null);

            } catch (error) {
                console.error('Ошибка при получении данных профиля:', error);
            } finally {
                setProfileLoading(false);
            }
        };

        fetchUserProfileData();
    }, []);

    // Получение истории авторизаций
    useEffect(() => {
        const fetchAuthorizationHistory = async () => {
            try {
                setLoading(true);
                const data = await apiRequest<AuthorizationEntry[]>('/pay/user/profile/authorizations', 'GET');

                if (Array.isArray(data)) {
                    setAuthHistory(data);
                }
            } catch (error) {
                console.error('Ошибка при получении истории авторизаций:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchAuthorizationHistory();
    }, []);

    useEffect(() => {
        const fetch2faData = async () => {
            // Получаем данные 2FA только если 2FA не включено
            if (!is2faEnabled) {
                try {
                    const data = await apiRequest<TwoFAData>('/pay/user/profile/enable-2fa', 'POST');
                    setQrCode(data.qr_code);
                    setSecret(data.secret);
                } catch (error) {
                    console.error('Ошибка при получении данных 2FA:', error);
                }
            }
        };

        fetch2faData();
    }, [is2faEnabled]);

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = String(date.getFullYear()).slice(-2);
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${day}.${month}.${year} ${hours}:${minutes}`;
    };

    // Сортировка истории по времени (новые сверху)
    const sortedHistory = authHistory.sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);

        return dateB.getTime() - dateA.getTime();
    });
    const closeDialog = () => {
        if (dialogRef.current) {
            dialogRef.current.close();
        }
    };
    const handleBackdropClick = (e: React.MouseEvent) => {
        if (e.target === e.currentTarget) {
            closeDialog();
        }
    };
    const openDialog = () => {
        setStep(1);
        if (dialogRef.current) {
            dialogRef.current.showModal();
        }
    };

    const openDisableDialog = () => {
        setStep(2); // Сразу показываем шаг ввода кода
        setCode(''); // Очищаем предыдущий ввод кода
        if (dialogRef.current) {
            dialogRef.current.showModal();
        }
    };

    const handleNext = () => {
        if (step === 1) {
            setStep(2);
        } else if (step === 2) {
            handleActivate();
        }
    };

    const handleActivate = async () => {
        if (!code) {
            alert('Please enter the 2FA code.');
            return;
        }

        try {
            const requestData = {
                code: code
            };

            const response = await apiRequest<TwoFAResponse>('/pay/user/profile/confirm-2fa', 'POST', requestData);

            console.log('2FA activated successfully:', response);
            closeDialog();
            setIs2faEnabled(true);
        } catch (error) {
            console.error('Ошибка при активации 2FA:', error);
            alert('Invalid 2FA code, please try again.');
        }
    };

    const handleDisable2FA = async () => {
        if (!code) {
            alert('Please enter the 2FA code to disable.');
            return;
        }

        try {
            const response = await apiRequest<TwoFAResponse>(`/pay/user/profile/disable-2fa?twofa_code=${code}`, 'DELETE');

            console.log('2FA disabled successfully:', response);
            setIs2faEnabled(false);
            closeDialog();
        } catch (error) {
            console.error('Ошибка при отключении 2FA:', error);
            alert('Invalid 2FA code, please try again.');
        }
    };
    return (
        <div className={`${styles.mainSafety}`}>
            <div className={`${styles.mainSafetyWrapper}`}>
                <h3 className={`${styles.mainSafetyTitle}`}>Authorization history</h3>

                {loading ? (
                    <div className={`${styles.mainSafetyLoader}`}>Loading...</div>
                ) : authHistory.length === 0 ? (
                    <div className={`${styles.mainSafetyEmpty}`}>No authorization history found</div>
                ) : (
                    <div className={`${styles.mainSafetyHistory}`}>
                        {sortedHistory.map((entry, index) => (
                            <div key={`${entry.created_at}-${index}`} className={`${styles.mainSafetyHistoryContainer}`}>
                                <div className={`${styles.mainSafetyHistoryItem}`}>
                                    <span className={`${styles.mainSafetyHistoryText}`}>
                                        <TimeSvg className={`${styles.mainSafetyHistorySvg}`} />
                                        <span className={`${styles.mainSafetyHistorySpan}`}>Time:</span> {formatDate(entry.created_at)}
                                    </span>
                                    <span className={`${styles.mainSafetyHistoryText}`}>
                                        <DeviceSvg className={`${styles.mainSafetyHistorySvg}`} />
                                        <span className={`${styles.mainSafetyHistorySpan}`}>Device:</span> {entry.device}
                                    </span>
                                </div>
                                <div className={`${styles.mainSafetyHistoryItem}`}>
                                    <span className={`${styles.mainSafetyHistoryText}`}>
                                        <IpSvg className={`${styles.mainSafetyHistorySvg}`} />
                                        <span className={`${styles.mainSafetyHistorySpan}`}>IP:</span> {entry.ip}
                                    </span>
                                    <span className={`${styles.mainSafetyHistoryText}`}>
                                        <PlanetSvg className={`${styles.mainSafetyHistorySvg}`} />
                                        <span className={`${styles.mainSafetyHistorySpan}`}>Country:</span> {entry.location}
                                    </span>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div className={`${styles.mainSafetyContainer}`}>
                <div className={`${styles.mainSafetyItem}`}>
                    <h3 className={`${styles.mainSafetyTitle}`}>2FA authentication <span className={is2faEnabled ? styles.mainSafetyEnabled : styles.mainSafetyDisabled}>{is2faEnabled ? 'enabled' : 'disabled'}</span></h3>
                    {profileLoading ? (
                        <div className={`${styles.mainSafetyLoader}`}>Loading...</div>
                    ) : (
                        <button
                            className={`${styles.mainSafetyButton}`}
                            onClick={is2faEnabled ? openDisableDialog : openDialog}
                            disabled={false}
                        >
                            {is2faEnabled ? 'Disable' : 'Connect'}
                        </button>
                    )}
                </div>
                <div className={`${styles.mainSafetyItem}`}>
                    <h3 className={`${styles.mainSafetyTitle}`}>Connect Telegram <span className={isTgEnabled ? styles.mainSafetyEnabled : styles.mainSafetyDisabled}>{isTgEnabled ? 'enabled' : 'disabled'}</span></h3>
                    {profileLoading ? (
                        <div className={`${styles.mainSafetyLoader}`}>Loading...</div>
                    ) : (
                        <button className={`${styles.mainSafetyButton}`}>{isTgEnabled ? 'Disconnect' : 'Connect'}</button>
                    )}
                </div>
            </div>
            <dialog ref={dialogRef} className={`${styles.dialog} ${mediaStyles.dialog}`} onClick={handleBackdropClick}>
                <div className={`${styles.dialogBlock} ${mediaStyles.dialogBlock}`}>
                    <div className={`${styles.dialogContainer} ${mediaStyles.dialogContainer}`} onClick={(e) => e.stopPropagation()}>
                        <div className={`${styles.dialogHeader}`}>
                            <h2 className={`${styles.dialogTitle}`}>{is2faEnabled ? 'Disable 2FA' : 'Setting up two-factor authentication (2FA)'}</h2>
                            <button onClick={closeDialog}>X</button>
                        </div>
                        <div className={`${styles.dialogInformation} ${mediaStyles.dialogInformation}`}>
                            <p className={`${styles.dialogInformationText}`}><FingerprintSvg className={`${styles.dialogInformationSvg}`} />Install Google Authenticator or a similar authentication app.</p>
                            <p className={`${styles.dialogInformationText}`}><MiniQrSvg className={`${styles.dialogInformationSvg}`} />Scan the code using the authentication app or copy the code to add it manually.</p>
                        </div>
                        <div className={`${styles.dialogContent} ${mediaStyles.dialogContent}`}>
                            {step === 1 && qrCode && !is2faEnabled && (
                                <div className={`${styles.dialogQr}`}>
                                    <img className={`${styles.dialogQr}`} src={`data:image/png;base64,${qrCode}`} alt="QR Code" />
                                </div>
                            )}
                            {step === 1 && secret && !is2faEnabled && (
                                <div className={`${styles.dialogSecret}`}>
                                    <span>{secret}</span>
                                </div>
                            )}
                            {step === 2 && (
                                <div className={`${styles.dialogInput} ${mediaStyles.dialogInput}`}>
                                    <input
                                        type="text"
                                        placeholder="Enter code"
                                        value={code}
                                        onChange={(e) => setCode(e.target.value)}
                                        maxLength={6}
                                        pattern="[0-9]{6}"
                                    />
                                </div>
                            )}
                        </div>
                        <button className={`${styles.dialogButton}`} onClick={is2faEnabled ? handleDisable2FA : handleNext}>
                            {is2faEnabled ? 'Disable 2FA' : (step === 1 ? 'Next' : 'Activate')}
                        </button>
                    </div>
                </div>
            </dialog>
        </div>

    );
};

export default Safety;
