import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
// @ts-ignore
import styles from './InvoiceCard.module.scss';
// @ts-ignore
import mediaStyles from './InvoiceCard-media.module.scss';
import { invoiceImages } from '../../../assets/images/images.ts';
import { Addresses } from '../../Pages.ts';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { apiRequest } from '../../../data/Api.ts';
import { QRCodeSVG } from 'qrcode.react';

interface InvoiceData {
    id: number;
    uuid: string;
    amount: number;
    amount_usd: number;
    recieved_amount: number;
    recieved_amount_usd: number;
    api_label: string;
    label: string;
    postback_url: string;
    success_url: string;
    currency: string;
    address: string;
    confirmations: number;
    required_confirmations: number;
    is_paid: boolean;
    is_expired: boolean;
    is_postback_sended: boolean;
    created_at: string;
    expires_at: string;
}

const InvoiceCard: React.FC = () => {
    const { i18n } = useTranslation();
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [invoiceData, setInvoiceData] = useState<InvoiceData | null>(null);
    const [timeLeft, setTimeLeft] = useState<number>(0);
    const [totalTime, setTotalTime] = useState<number>(0);

    // Значения прогресса для круговых диаграмм (от 0 до 1)
    const [expirationProgress, setExpirationProgress] = useState(1);
    const [confirmationsProgress, setConfirmationsProgress] = useState(0);

    useEffect(() => {
        const fetchInvoiceData = async () => {
            if (id) {
                setLoading(true);
                try {
                    const response = await apiRequest<InvoiceData>(`/pay/invoice/${id}`, 'GET');
                    setInvoiceData(response);

                    // Устанавливаем начальные значения для таймера
                    const created = new Date(response.created_at).getTime();
                    const expires = new Date(response.expires_at).getTime();
                    const total = expires - created;
                    setTotalTime(total);
                    setTimeLeft(expires - Date.now());

                    // Устанавливаем прогресс подтверждений
                    setConfirmationsProgress(response.confirmations / response.required_confirmations);
                } catch (error) {
                    console.error('Ошибка при загрузке данных инвойса:', error);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchInvoiceData();
    }, [id]);

    useEffect(() => {
        if (!invoiceData) return;

        const timer = setInterval(() => {
            const now = Date.now();
            const expires = new Date(invoiceData.expires_at).getTime();
            const timeLeft = expires - now;

            if (timeLeft <= 0) {
                setTimeLeft(0);
                setExpirationProgress(0);
                clearInterval(timer);
                return;
            }

            setTimeLeft(timeLeft);
            setExpirationProgress(timeLeft / totalTime);
        }, 1000);

        return () => clearInterval(timer);
    }, [invoiceData, totalTime]);

    const formatTimeLeft = (ms: number) => {
        const seconds = Math.floor(ms / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);

        return `${hours.toString().padStart(2, '0')}:${(minutes % 60).toString().padStart(2, '0')}:${(seconds % 60).toString().padStart(2, '0')}`;
    };

    if (loading) {
        return (
            <div className={styles.loading}>Загрузка...</div>
        );
    }

    if (!invoiceData) {
        return (
            <div className={styles.error}>Инвойс не найден</div>
        );
    }

    return (
        <div
            className={`${styles.invoiceCard} ${mediaStyles.invoiceCard}`}
            style={{
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${invoiceImages.background})`
            }}
        >
            <div className={`${styles.invoiceCardContainer} ${mediaStyles.invoiceCardContainer}`}>
                <div className={styles.invoiceContent}>
                    <div className={styles.invoiceHeader}>
                        <div className={`${styles.invoiceTitleContainer} ${mediaStyles.invoiceTitleContainer}`}>
                            <h2 className={styles.invoiceTitle}>Nord PAY</h2>
                        </div>
                        <div className={styles.invoiceHeaderInfo}>
                            <div className={styles.invoiceHeaderInfoItem}>
                                <p className={styles.invoiceHeaderInfoNetwork}>Network {invoiceData.currency}</p>
                                <p className={styles.invoiceHeaderInfoSum}>{invoiceData.amount} {invoiceData.currency}</p>
                                <p className={styles.invoiceHeaderInfoCommission}>You pay network fee</p>
                            </div>
                        </div>
                        <div
                            className={`${styles.invoiceBanner} ${mediaStyles.invoiceBanner}`}
                            style={{
                                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${invoiceImages.banner})`
                            }}
                        />
                    </div>
                    <div className={styles.invoiceBody}>
                        <div className={styles.invoiceBodyContainer}>
                            <div className={styles.invoiceBodyItem}>
                                <div className={styles.invoiceBodyTimerInfo}>
                                    <p className={styles.invoiceBodyItemTitle}>Expiration time</p>
                                    <p className={styles.invoiceBodyItemSubtitle}>{formatTimeLeft(timeLeft)}</p>
                                </div>
                                <div className={styles.circularProgressContainer}>
                                    <CircularProgressbar
                                        value={expirationProgress * 100}
                                        strokeWidth={10}
                                        styles={buildStyles({
                                            strokeLinecap: 'round',
                                            pathColor: '#ffffff',
                                            trailColor: '#000000',
                                        })}
                                    />
                                </div>
                            </div>
                            <div className={styles.invoiceBodyItem}>
                                <div className={styles.invoiceBodyTimerInfo}>
                                    <p className={styles.invoiceBodyItemTitle}>Confirmations</p>
                                    <p className={styles.invoiceBodyItemSubtitle}>{invoiceData.confirmations} of {invoiceData.required_confirmations}</p>
                                </div>
                                <div className={styles.circularProgressContainer}>
                                    <CircularProgressbar
                                        value={confirmationsProgress * 100}
                                        strokeWidth={10}
                                        styles={buildStyles({
                                            strokeLinecap: 'round',
                                            pathColor: '#ffffff',
                                            trailColor: '#000000',
                                        })}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={styles.invoiceBodyWay}>
                            <div className={styles.invoiceBodyWayQr}>
                                {invoiceData && (
                                    <QRCodeSVG
                                        value={invoiceData.address}
                                        size={100}
                                        level="H"
                                        includeMargin={true}
                                        bgColor="#ffffff"
                                        fgColor="#000000"
                                    />
                                )}
                            </div>
                            <div className={styles.invoiceBodyWayWrapper}>
                                <p className={styles.invoiceBodyWayAddressTitle}>Recipient's wallet address</p>
                                <p className={styles.invoiceBodyWayAddress}>{invoiceData.address}</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.invoiceFooter}>
                        <a className={styles.invoiceFooterLink}>AML Policy</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InvoiceCard;
