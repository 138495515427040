import React, { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { PiEyeBold, PiEyeClosedBold } from "react-icons/pi";
import { TwoFactorInput } from '../../Pages.ts';
import { BASE_URL, COOKIE_TOKEN, DOMAIN } from "../../../data/config.js";
// @ts-ignore
import styles from "../Auth.module.scss";
// @ts-ignore
import mediaStyles from "../Auth-media.module.scss";

const Login = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false); // Состояние для глазика
    const [rememberMe, setRememberMe] = useState(false);
    const [errors, setErrors] = useState<{ email: boolean | string; password: boolean | string }>({
        email: false,
        password: false,
    });
    const dialogRef = useRef<HTMLDialogElement>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [code, setCode] = useState("");

    const isValidEmail = (email: string) => {
        const emailRegex = /^.+@[a-zA-Z]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };

    const token = getCookie('access_token');

    function getCookie(name: string): string | null {
        const value = document.cookie;
        const parts = value.split('; ').find(cookie => cookie.startsWith(name + "="));
        if (parts) {
            return parts.split('=')[1];
        }
        return null;
    }
    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newEmail = event.target.value;
        setEmail(newEmail);

        if (formSubmitted) {
            setErrors((prev) => ({
                ...prev,
                email: newEmail.trim().length === 0
                    ? t("enterEmail")
                    : !isValidEmail(newEmail)
                        ? t("incompleteEmail")
                        : false
            }));
        }
    };
    const openDialog = () => {
        setIsOpen(true);
        if (dialogRef.current) {
            dialogRef.current.showModal();
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormSubmitted(true);

        const isEmailInvalid = !isValidEmail(email);
        const isPasswordEmpty = password.trim().length === 0;

        const newErrors = {
            email: email.trim().length === 0 ? t("enterEmail") : isEmailInvalid ? t("incompleteEmail") : false,
            password: isPasswordEmpty ? t("enterPass") : false,
        };

        setErrors(newErrors);

        if (newErrors.email || newErrors.password) return;

        setLoading(true);

        try {
            const response = await fetch(`${BASE_URL}/pay/user/auth/sign-in`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password, remember_me: rememberMe, tg_id: null })
            });

            const data = await response.json();

            if (data.twofa_required) {
                console.log("2FA required");
                openDialog();
            } else if (data.access_token) {
                document.cookie = `${COOKIE_TOKEN}=${data.access_token}; path=/; domain=${DOMAIN}; SameSite=Lax; Secure`;
                console.log("Login successful", data.access_token);
                navigate('/');
            } else {
                alert(data.detail || 'Unknown error');
            }
        } catch (error) {
            alert(`Error: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };





    const togglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };

    const isFormValid = email.trim() && password.trim() && isValidEmail(email);

    const closeDialog = () => {
        setIsOpen(false);
        if (dialogRef.current) {
            dialogRef.current.close();
        }
    };
    const handleBackdropClick = (e: React.MouseEvent) => {
        if (e.target === dialogRef.current) {
            closeDialog();
        }
    };

    const handleTwoFACodeSubmit = async () => {
        if (!code) {
            alert('Please enter the 2FA code.');
            return;
        }

        try {
            const response = await fetch(`${BASE_URL}/pay/user/auth/sign-in-2fa`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    twofa_code: code.replace('-', ''),
                    email: email,
                    password: password,
                    remember_me: rememberMe,
                }),
            });

            const data = await response.json();

            if (data.access_token) {
                document.cookie = `${COOKIE_TOKEN}=${data.access_token}; path=/; domain=${DOMAIN}; SameSite=Lax; Secure`;
                console.log("2FA verified successfully", data.access_token);
                navigate('/');
            } else {
                alert('Verification failed');
            }
        } catch (error) {
            alert(`Error: ${error.message}`);
        }
    };



    return (
        <div className={`${styles.auth} ${mediaStyles.auth}`}>
            <div className={`${styles.authContainer} ${mediaStyles.authContainer}`}>
                <header className={`${styles.authHeader} ${mediaStyles.authHeader}`}>
                    <h2 className={`${styles.authTitle} ${mediaStyles.authTitle}`}> {t("enter")}</h2>
                </header>
                <main className={`${styles.authMain} ${mediaStyles.authMain}`}>
                    <form className={`${styles.authForm} ${mediaStyles.authForm}`} onSubmit={handleSubmit} noValidate>
                        {/* Поле Email */}
                        <div className={styles.inputWrapper}>
                            {errors.email && <span className={styles.tooltip}>{errors.email}</span>}
                            <input
                                className={`${styles.authInput} ${mediaStyles.authInput} ${errors.email ? styles.invalid : ""}`}
                                type="email"
                                placeholder={t("email")}
                                value={email}
                                onChange={handleEmailChange}
                            />
                        </div>

                        <div className={styles.authWrapper} style={{ position: "relative" }}>
                            {errors.password && <span className={styles.tooltip}>{errors.password}</span>}
                            <input
                                className={`${styles.authInputPass} ${mediaStyles.authInputPass} ${errors.password ? styles.invalid : ""}`}
                                type={showPassword ? "text" : "password"}
                                placeholder={t("pass")}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <button
                                type="button"
                                onClick={togglePasswordVisibility}
                                className={`${styles.authEye} ${mediaStyles.authEye}  ${errors.password ? styles.invalid : ""}`}
                            >
                                {showPassword ? <PiEyeBold size={20} /> : <PiEyeClosedBold size={20} />}
                            </button>
                        </div>

                        <div className={styles.rememberMeWrapper}>
                            <input
                                type="checkbox"
                                id="rememberMe"
                                checked={rememberMe}
                                onChange={() => setRememberMe(prev => !prev)}
                            />
                            <label htmlFor="rememberMe">{t("rememberMe")}</label>
                        </div>

                        <Link className={`${styles.authPass} ${mediaStyles.authPass}`} to="/Authorization/ResetPassword">
                            {t("forgotPass")}
                        </Link>
                        <button className={`${styles.authButton} ${mediaStyles.authButton} ${isFormValid ? styles.valid : styles.invalid}`} type="submit">
                            {t("enter")}
                        </button>
                    </form>
                </main>
                <footer className={`${styles.authFooter} ${mediaStyles.authFooter}`}>
                    <p className={`${styles.authText} ${mediaStyles.authText}`}>
                        {t("noAcc")}{" "}
                        <Link className={`${styles.authLink} ${mediaStyles.authLink}`} to="/Authorization/Registration">
                            {t("createAcc")}
                        </Link>
                    </p>
                </footer>
            </div>
            <dialog ref={dialogRef} className={`${styles.dialog}`} onClick={handleBackdropClick}>
                <div className={`${styles.dialogContainer}`} onClick={(e) => e.stopPropagation()}>
                    <div className={`${styles.dialogHeader}`}>
                        <h2 className={`${styles.dialogTitle}`}>Confirm Deletion</h2>
                        <button onClick={closeDialog}>X</button>
                    </div>

                    <div>
                        <TwoFactorInput value={code} onChange={setCode} />
                    </div>
                    <button className={`${styles.dialogButton}`} onClick={handleTwoFACodeSubmit}>
                        Confirm
                    </button>
                </div>
            </dialog >
        </div >
    );
};

export default Login;
