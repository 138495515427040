import React, { Component } from 'react'
import { useTranslation } from 'react-i18next';

// @ts-ignore
import styles from './Periods.module.scss';
// @ts-ignore
import mediaStyles from './Periods-media.module.scss';

export const Periods = ({ selectedPeriod, onPeriodChange }: { selectedPeriod: string, onPeriodChange: (period: string) => void }) => {
    const { t } = useTranslation();

    const getButtonClass = (period: string) => {
        return selectedPeriod === period
            ? `${styles.periodsActive} ${styles.periodsButton} ${mediaStyles.periodsButton}`
            : `${styles.periodsButton} ${mediaStyles.periodsButton}`;
    };

    return (
        <div className={`${styles.periods} ${mediaStyles.periods}`}>
            <div className={`${styles.periodsContainer} ${mediaStyles.periodsContainer}`}>
                <button className={getButtonClass('day')} onClick={() => onPeriodChange('day')}>{t('day')}</button>
                <button className={getButtonClass('week')} onClick={() => onPeriodChange('week')}>{t('week')}</button>
                <button className={getButtonClass('month')} onClick={() => onPeriodChange('month')}>{t('month')}</button>
            </div>
        </div>
    )
}
export default Periods;