import React, { useState, useRef, useEffect } from 'react';
import { BrushSvg, TrashSvg, CopyBlueSvg } from '../../assets/svg-files/SvgIcons.ts';
// @ts-ignore
import styles from './MyProjects.module.scss';
// @ts-ignore
import mediaStyles from './MyProjects-media.module.scss';
import { apiRequest } from '../../data/Api.ts';

interface ApiKeyData {
    id: number;
    label: string;
    api_key: string;
    percent: number;
    is_user_payer: boolean;
    company_name: string;
    banner: string;
    background: string;
    created_at: string;
}

const MyProjects = () => {
    const [apiKeys, setApiKeys] = useState<{
        id: number;
        label: string;
        key: string;
        payer: string;
        percent: number;
        createdAt: string;
    }[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const dialogRef = useRef<HTMLDialogElement>(null);
    const dialogCustomizeRef = useRef<HTMLDialogElement>(null);
    const viewDialogRef = useRef<HTMLDialogElement>(null);

    const [isChecked, setIsChecked] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [selectedOption, setSelectedOption] = useState<{ value: string; label: string }>({ value: 'you', label: 'You' });
    const [companyName, setCompanyName] = useState('');
    const [bannerImage, setBannerImage] = useState('');
    const [backgroundImage, setBackgroundImage] = useState('');
    const [isInvoiceCustomization, setIsInvoiceCustomization] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);

    const handleGoBack = () => setIsInvoiceCustomization(false);
    const handleCompanyNameChange = (e) => {
        const value = e.target.value;
        setCompanyName(value);
        if (value.length < 2) {
            setIsSaveDisabled(true);
        } else {
            setIsSaveDisabled(false);
        }
    };
    const handleChange = (value: string) => {
        const label = value === 'you' ? 'You' : 'Buyer';
        setSelectedOption({ value, label });
    };
    const handleCreateInvoice = () => {
        setIsInvoiceCustomization(false);
        dialogRef.current?.showModal()
    };
    const handleCustomizeInvoice = () => {
        setIsInvoiceCustomization(true);
    };
    const [label, setLabel] = useState('');
    const [viewKey, setViewKey] = useState<{
        id: number;
        label: string;
        key: string;
        payer: string;
        percent: number;
        createdAt: string;
    } | null>(null);


    const handleSave = async () => {
        if (viewKey) {
            try {
                // Отправка данных на сервер для обновления
                const requestData = {
                    "label": viewKey.label,
                    "is_user_payer": selectedOption.value === 'you'
                };

                await apiRequest(`/pay/user/projects/${viewKey.id}`, 'PUT', requestData);

                // Обновление списка ключей на основе данных с сервера
                await fetchApiKeys();

                setIsEditing(false);
                viewDialogRef.current?.close();
            } catch (error) {
                console.error('Ошибка при обновлении API ключа:', error);
                alert('Не удалось обновить API ключ. Пожалуйста, попробуйте снова.');
            }
        }
    };
    const handleCreateKey = async (event: React.FormEvent) => {
        event.preventDefault();

        if (label.length > 32) {
            alert("Label не может содержать более 32 символов.");
            return;
        }

        try {
            // Передаем данные на сервер
            const requestData = {
                "label": label,
                "is_user_payer": selectedOption.value === 'you'
            };

            await apiRequest('/pay/user/projects/', 'POST', requestData);

            // Перезагружаем список ключей с сервера
            fetchApiKeys();

            setLabel('');
            setSelectedOption({ value: 'you', label: 'You' });
            setIsChecked(false);

            dialogRef.current?.close();
        } catch (error) {
            console.error('Ошибка при создании API ключа:', error);
            alert('Не удалось создать API ключ. Пожалуйста, попробуйте снова.');
        }
    };
    const handleLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value.slice(0, 32);
        setLabel(newValue);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, field: keyof NonNullable<typeof viewKey>) => {
        setViewKey(prevKey => prevKey ? { ...prevKey, [field]: e.target.value } : prevKey);
    };

    const handleDeleteKey = async (id: number) => {
        try {
            // Удаление ключа на сервере
            await apiRequest(`/pay/user/projects/${id}`, 'DELETE');
            // Обновление списка ключей
            setApiKeys(apiKeys.filter(key => key.id !== id));
        } catch (error) {
            console.error('Ошибка при удалении API ключа:', error);
            alert('Не удалось удалить API ключ. Пожалуйста, попробуйте снова.');
        }
    };

    const closeDialog = () => {
        if (dialogRef.current) {
            dialogRef.current.close();
        }
        if (viewDialogRef.current) {
            viewDialogRef.current.close();
        }
        if (viewDialogRef.current) {
            viewDialogRef.current.close();
        }
    };
    const handleBackdropClick = (e: React.MouseEvent) => {
        if (e.target === e.currentTarget) {
            closeDialog();
        }

    };
    const openViewDialog = (keyData: typeof viewKey, isEditingMode: boolean) => {
        // Добавляем проверку на null
        if (keyData) {
            setViewKey(keyData);
            setIsEditing(isEditingMode);

            // Синхронизация selectedOption с payer из viewKey
            const payerValue = keyData.payer.toLowerCase() === 'you' ? 'you' : 'buyer';
            setSelectedOption({ value: payerValue, label: keyData.payer });

            viewDialogRef.current?.showModal();
        } else {
            console.error("keyData is null");
        }
    };

    const handleSaveInvoiceCustomization = async (event: React.FormEvent) => {
        event.preventDefault();
        setIsSaveDisabled(true); // Блокируем кнопку

        try {
            // Отправляем данные кастомизации на сервер
            const customizationData = {
                "company_name": companyName,
                "banner": bannerImage,
                "background": backgroundImage
            };

            await apiRequest('/pay/user/projects/customize', 'POST', customizationData);

            setShowTooltip(true); // Показываем тултип
            setTimeout(() => setShowTooltip(false), 2000);
        } catch (error) {
            console.error("Ошибка при сохранении настроек кастомизации:", error);
            alert("Не удалось сохранить настройки кастомизации. Попробуйте снова.");
        }
    };

    const handleCopyInput = (e: React.MouseEvent) => {
        const input = e.target as HTMLInputElement;
        if (input && input.value) {
            // Копирование содержимого инпута в буфер обмена
            navigator.clipboard.writeText(input.value).then(() => {
                setShowTooltip(true);  // Показать тултип
                setTimeout(() => {
                    setShowTooltip(false);  // Скрыть тултип через 1.5 секунды
                }, 1500);
            }).catch(err => {
                alert('Failed to copy: ' + err);
            });
        }
    };


    const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
        const file = e.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const img = new Image();
                img.onload = () => {
                    // Установка изображения в зависимости от типа
                    if (type === 'banner') {
                        setBannerImage(reader.result as string);  // Устанавливаем баннер
                    } else {
                        setBackgroundImage(reader.result as string);  // Устанавливаем фон
                    }
                };
                img.src = reader.result as string;
            };
            reader.readAsDataURL(file);
        }
    };
    const bannerFileInputRef = useRef<HTMLInputElement>(null);
    const backgroundFileInputRef = useRef<HTMLInputElement>(null);
    const handleChangeImage = (type: string) => {

        if (type === 'banner' && bannerFileInputRef.current) {
            bannerFileInputRef.current.click();
        } else if (type === 'background' && backgroundFileInputRef.current) {
            backgroundFileInputRef.current.click();
        }
    }

    // Функция для удаления изображения
    const handleRemoveImage = (type: 'banner' | 'background') => {
        if (type === 'banner') {
            setBannerImage('');
        } else {
            setBackgroundImage('');
        }
    };

    // Функция для загрузки API ключей с сервера
    const fetchApiKeys = async () => {
        setIsLoading(true);
        try {
            const response = await apiRequest<ApiKeyData[]>('/pay/user/projects/', 'GET');
            if (response && Array.isArray(response)) {
                // Преобразуем данные в нужный формат для компонента
                const formattedKeys = response.map(item => ({
                    id: item.id,
                    label: item.label,
                    key: item.api_key,
                    payer: item.is_user_payer ? 'You' : 'Buyer',
                    percent: item.percent,
                    createdAt: formatDate(item.created_at)
                }));
                setApiKeys(formattedKeys);
            }
        } catch (error) {
            console.error('Ошибка при получении API ключей:', error);
        } finally {
            setIsLoading(false);
        }
    };

    // Форматирование даты
    const formatDate = (dateString: string) => {
        try {
            const date = new Date(dateString);
            const formattedDate = date.toLocaleDateString('ru-RU', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            });
            const formattedTime = date.toLocaleTimeString('ru-RU', {
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            });
            return `${formattedDate}, ${formattedTime}`;
        } catch (e) {
            return dateString;
        }
    };

    // Загрузка API ключей при монтировании компонента
    useEffect(() => {
        fetchApiKeys();
    }, []);

    return (
        <div className={styles.mainContainer}>
            {/* Если нет API-ключей */}
            {isLoading ? (
                <div className={styles.mainWrapper}>
                    <p>Загрузка данных...</p>
                </div>
            ) : apiKeys.length === 0 ? (
                <div className={styles.mainWrapper}>
                    <div className={styles.mainBlock}>
                        <p className={styles.mainBlockTitle}>У вас ещё нет ключей</p>
                        <button type="button" onClick={handleCreateInvoice} className={styles.mainBlockButton}>
                            Создать ключ
                        </button>
                    </div>
                    <div className={styles.mainText}>
                        <h3 className={styles.mainTextTitle}>API ключи</h3>
                        <p className={styles.mainTextSubtitle}>Здесь будет вся информация о всех созданных вами API-ключах</p>
                    </div>
                </div>
            ) : (
                <div className={styles.mainWrapperKeys}>
                    <button type="button" onClick={handleCreateInvoice} className={styles.mainButton}>
                        Создать
                    </button>
                    <div className={styles.mainBox}>
                        {apiKeys.map((key) => (
                            <div key={key.id} className={styles.mainBlockKey}>
                                <div className={styles.mainTitleContainer}>
                                    <h4 className={styles.mainApiTitle}>{key.label}</h4>
                                    <p className={styles.mainCreated}>{key.createdAt}</p>
                                </div>
                                <div className={styles.mainTextContainer}>
                                    <p className={styles.mainDesignation}>API Key: <span className={styles.mainHiddenKey}>********</span></p>
                                    <p className={styles.mainDesignation}>Комиссия: {key.percent ? `${key.percent}%` : '0.5%'}</p>
                                </div>
                                <div className={styles.mainButtonContainer}>
                                    <button onClick={() => openViewDialog(key, false)} className={styles.mainVeiwButton}>Просмотр</button>
                                    <button onClick={() => openViewDialog(key, true)} className={styles.mainEditButton}>Изменить</button>
                                    <button onClick={() => handleDeleteKey(key.id)} className={styles.mainDeleteButton}>Удалить</button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            {/* Модальное окно создания API-ключа */}
            <dialog ref={dialogRef} onClick={handleBackdropClick} className={`${styles.dialog} ${mediaStyles.dialog}`}>
                <div className={`${styles.dialogBlock} ${mediaStyles.dialogBlock}`}>


                    {!isInvoiceCustomization ? (
                        <div className={styles.dialogContainer}>
                            <div className={styles.dialogHeader}>
                                <h2 className={styles.dialogTitle}>Создать API-ключ</h2>
                                <button type="button" onClick={() => dialogRef.current?.close()}>X</button>
                            </div>
                            <div className={styles.dialogContent}>
                                <label className={styles.dialogLabel}>
                                    <span className={styles.dialogSpan}>Label</span>
                                    <input className={styles.dialogInput} name="label" value={label} onChange={handleLabelChange} maxLength={32}
                                        required />
                                </label>

                                <div className={styles.dialogRadioWrapper}>
                                    <span className={styles.dialogSpan}>Who pays the commission of invoice?</span>
                                    <div className={styles.dialogRadio}>
                                        <label className={styles.dialogRadioLabel}>
                                            You
                                            <span className={`${styles.dialogRadioSpan} ${selectedOption.value === 'you' ? styles.dialogRadioSpanRadioChecked : ''}`}></span>
                                            <input
                                                type="radio"
                                                name="payer"
                                                value="you"
                                                checked={selectedOption.value === 'you'}
                                                onChange={() => handleChange('you')}
                                                className={styles.dialogRadioInput}
                                            />
                                        </label>
                                        <label className={styles.dialogRadioLabel}>
                                            Buyer
                                            <span className={`${styles.dialogRadioSpan} ${selectedOption.value === 'buyer' ? styles.dialogRadioSpanRadioChecked : ''}`}></span>
                                            <input
                                                type="radio"
                                                name="payer"
                                                value="buyer"
                                                checked={selectedOption.value === 'buyer'}
                                                onChange={() => handleChange('buyer')}
                                                className={styles.dialogRadioInput}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <button className={styles.dialogCustomizeButton} onClick={handleCustomizeInvoice}><BrushSvg />Customize invoice</button>
                                <div className={styles.dialogCommission}>
                                    <p className={styles.dialogCommissionText}>Commission</p>
                                    <span className={styles.dialogCommissionPercent}>0.5 %</span>
                                </div>
                                <button className={styles.dialogButton} onClick={handleCreateKey} type="button">Создать</button>
                            </div>
                        </div>
                    ) : (
                        <div className={styles.dialogContainer}>
                            <div className={styles.dialogHeader}>
                                <h2 className={styles.dialogTitle}>Customize Invoice</h2>
                                <button type="button" onClick={() => dialogRef.current?.close()}>X</button>
                            </div>
                            <div className={styles.dialogContent}>
                                <div className={styles.dialogLabel}>
                                    <span className={styles.dialogSpan}>Company Name</span>
                                    <input
                                        className={styles.dialogInput}
                                        value={companyName}
                                        onChange={handleCompanyNameChange}
                                        placeholder='Enter Company Name'
                                        required
                                    />
                                </div>

                                <div className={styles.dialogLabel}>
                                    <span className={styles.dialogSpan}>Banner Image</span>
                                    {bannerImage ? (
                                        <>
                                            <div className={`${styles.dialogImageWrapper}`}>
                                                <img src={bannerImage} alt="Banner" className={styles.dialogImageZone} />
                                                <div className={`${styles.dialogImageInfo}`}>
                                                    <div className={`${styles.dialogImageText}`}>The recommended image size is at least 400 x 225 pixels.</div>
                                                    <div className={`${styles.dialogImageButtons}`}>
                                                        <button className={`${styles.dialogButtonImage}`} onClick={() => handleRemoveImage('banner')}>Remove</button>
                                                        <button className={`${styles.dialogButtonImage}`} onClick={() => handleChangeImage('banner')}>Change</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <div className={`${styles.dialogImageWrapper}`}>
                                            <div className={styles.dialogImageZone} />
                                            <div className={`${styles.dialogImageInfo}`}>
                                                <div className={`${styles.dialogImageText}`}>The recommended image size is at least 400 x 225 pixels.</div>
                                                <button
                                                    className={`${styles.dialogButtonImage}`}
                                                    data-no-close
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleChangeImage('banner');
                                                    }}
                                                >
                                                    Add
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                    <input
                                        ref={bannerFileInputRef}
                                        type="file"
                                        accept="image/*"
                                        onChange={(e) => handleImageUpload(e, 'banner')}
                                        style={{ display: 'none' }}
                                    />
                                </div>

                                <div className={styles.dialogLabel}>
                                    <span className={styles.dialogSpan}>Background Image</span>
                                    {backgroundImage ? (
                                        <>
                                            <div className={`${styles.dialogImageWrapper}`}>
                                                <img src={backgroundImage} alt="Background" className={styles.dialogImageZone} />
                                                <div className={`${styles.dialogImageInfo}`}>
                                                    <div className={`${styles.dialogImageText}`}>The recommended image size is at least 1920 x 1080 pixels.</div>
                                                    <div className={`${styles.dialogImageButtons}`}>
                                                        <button className={`${styles.dialogButtonImage}`} onClick={() => handleRemoveImage('background')}>Remove</button>
                                                        <button className={`${styles.dialogButtonImage}`} onClick={() => handleChangeImage('background')}>Change</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className={`${styles.dialogImageWrapper}`}>
                                                <div className={styles.dialogImageZone} />
                                                <div className={`${styles.dialogImageInfo}`}>
                                                    <div className={`${styles.dialogImageText}`}>The recommended image size is at least 1920 x 1080 pixels.</div>
                                                    <button
                                                        className={`${styles.dialogButtonImage}`}
                                                        data-no-close
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleChangeImage('background');
                                                        }}
                                                    >
                                                        Add
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    <input
                                        ref={backgroundFileInputRef}
                                        type="file"
                                        accept="image/*"
                                        onChange={(e) => handleImageUpload(e, 'background')}
                                        style={{ display: 'none' }}
                                    />
                                </div>
                                <div className={styles.dialogButtons}>
                                    <button className={styles.dialogButton} onClick={handleGoBack}>Back</button>

                                    <button className={`${styles.dialogButton} ${styles.dialogButtonSave}`} onClick={handleSaveInvoiceCustomization} disabled={isSaveDisabled}>{showTooltip && <div className={styles.tooltip}>Данные сохранены</div>}Save</button>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            </dialog>

            {/* Модальное окно просмотра API-ключа */}
            <dialog ref={viewDialogRef} onClick={handleBackdropClick} className={`${styles.dialog} ${mediaStyles.dialog}`}>
                <div className={`${styles.dialogBlock} ${mediaStyles.dialogBlock}`}>
                    {viewKey && (
                        <div className={`${styles.dialogContainer} ${mediaStyles.dialogContainer}`}>
                            <div className={styles.dialogHeader}>
                                <h2 className={styles.dialogTitle}>{isEditing ? "Редактировать API-ключ" : "Детали API-ключа"}</h2>
                                <button type="button" onClick={() => viewDialogRef.current?.close()}>X</button>
                            </div>
                            <div className={styles.dialogContent}>
                                <label className={styles.dialogLabel}>
                                    <span className={styles.dialogSpan}>Label</span>
                                    <input className={styles.dialogInput} value={viewKey.label} onChange={(e) => handleInputChange(e, 'label')}
                                        readOnly={!isEditing} />
                                </label>
                                <label className={styles.dialogLabel}>
                                    <span className={styles.dialogSpan}>API Key</span>
                                    <div className={`${styles.dialogInputWrapper} ${styles.mainInputApi} ${styles.mainInput}`}>
                                        <input
                                            className={` ${styles.dialogInputAnother}`}
                                            value={viewKey.key}
                                            onClick={handleCopyInput}
                                            readOnly
                                        /> <CopyBlueSvg className={`${styles.dialogCopySvg}`} />
                                    </div>
                                    {showTooltip && (
                                        <div className={`${styles.tooltip}`}>
                                            Address copied!
                                        </div>
                                    )}
                                </label>
                                <div className={styles.dialogRadioWrapper}>
                                    <span className={styles.dialogSpan}>Who pays the commission of invoice?</span>
                                    <div className={styles.dialogRadio}>
                                        <label className={styles.dialogRadioLabel}>
                                            You
                                            <span className={`${styles.dialogRadioSpan} ${selectedOption.value === 'you' ? styles.dialogRadioSpanRadioChecked : ''}`}></span>
                                            <input
                                                type="radio"
                                                name="payer"
                                                value="you"
                                                checked={selectedOption.value === 'you'}
                                                onChange={() => isEditing && handleChange('you')}
                                                disabled={!isEditing}
                                                className={styles.dialogRadioInput}
                                            />
                                        </label>
                                        <label className={styles.dialogRadioLabel}>
                                            Buyer
                                            <span className={`${styles.dialogRadioSpan} ${selectedOption.value === 'buyer' ? styles.dialogRadioSpanRadioChecked : ''}`}></span>
                                            <input
                                                type="radio"
                                                name="payer"
                                                value="buyer"
                                                checked={selectedOption.value === 'buyer'}
                                                onChange={() => isEditing && handleChange('buyer')}
                                                disabled={!isEditing}
                                                className={styles.dialogRadioInput}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className={styles.dialogCommission}>
                                    <p className={styles.dialogCommissionText}>Commission</p>
                                    <span className={styles.dialogCommissionPercent}>{viewKey.percent ? `${viewKey.percent}%` : '0.5%'}</span>
                                </div>
                                {isEditing ?
                                    <button className={styles.dialogButton} type="button" onClick={handleSave}>Save Changes</button>
                                    : ''}
                            </div>
                        </div>
                    )}
                </div>
            </dialog>
        </div>
    );
};

export default MyProjects;
