import React, { useRef, useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title, PieController } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useTranslation } from 'react-i18next';
import { useDashboardData } from '../DataProvider/DashboardDataProvider.tsx';
// @ts-ignore
import styles from './Circular.module.scss';
// @ts-ignore
import mediaStyles from './Circular-media.module.scss';

// Регистрация необходимых контроллеров и элементов
ChartJS.register(ArcElement, Tooltip, Legend, Title, PieController, ChartDataLabels);

const FirstGraph: React.FC<{ period: string }> = ({ period }) => {
    const chartRef = useRef<HTMLCanvasElement | null>(null); // Ссылка на canvas
    const chartInstanceRef = useRef<ChartJS | null>(null); // Ссылка на экземпляр графика
    const { data, isLoading, error } = useDashboardData();
    const [chartData, setChartData] = useState<any>(null);
    const [isDarkMode, setIsDarkMode] = useState(() => {
        // При загрузке проверяем сохраненную тему из localStorage
        const savedTheme = localStorage.getItem('theme');
        if (savedTheme) {
            return savedTheme === 'dark'; // Если тема сохранена, используем её
        }
        // Если темы нет в localStorage, проверяем системные настройки
        return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    });

    // Преобразуем данные транзакций в данные графика
    useEffect(() => {
        if (data && data[period]) {
            const transactions = data[period];
            // Счетчики для оплаченных и неоплаченных транзакций
            const counters = {
                wallets: { payed: 0, unpayed: 0 },
                invoices: { payed: 0, unpayed: 0 }
            };

            // Считаем оплаченные и неоплаченные транзакции
            transactions.forEach(tx => {
                // Определяем тип транзакции (кошелек или инвойс)
                const txType = tx.tx_type.toLowerCase();
                const isWallet = txType.includes('wallet');

                if (tx.is_paid) {
                    if (isWallet) {
                        counters.wallets.payed++;
                    } else {
                        counters.invoices.payed++;
                    }
                } else {
                    if (isWallet) {
                        counters.wallets.unpayed++;
                    } else {
                        counters.invoices.unpayed++;
                    }
                }
            });

            setChartData(counters);
        } else {
            setChartData(null);
        }
    }, [data, period]);

    const renderChart = (chartData: any) => {
        // Проверяем, что данные не пустые
        if (!chartData ||
            !(chartData.wallets?.payed || chartData.wallets?.unpayed ||
                chartData.invoices?.payed || chartData.invoices?.unpayed)) {
            return;
        }

        const totalPayd = (chartData.wallets?.payed || 0) + (chartData.invoices?.payed || 0);
        const totalUnpayd = (chartData.wallets?.unpayed || 0) + (chartData.invoices?.unpayed || 0);
        const totalAmount = totalPayd + totalUnpayd;

        // Проверка на нулевые данные
        if (totalAmount === 0) {
            return;
        }

        const percentagePayd = (totalPayd / totalAmount) * 100;
        const percentageUnpayd = (totalUnpayd / totalAmount) * 100;
        const percentTotalAmount = (percentagePayd + percentageUnpayd);

        // Проверяем и уничтожаем старую диаграмму, если она существует
        if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
        }

        // Создаем новый график
        const chart = new ChartJS(chartRef.current!, {
            type: 'pie',
            data: {
                labels: ['Paid', 'Unpaid', 'TotalAmount'],
                datasets: [
                    {
                        label: 'Paid & Unpaid',
                        data: [
                            percentagePayd,
                            percentageUnpayd
                        ],
                        backgroundColor: ['#CBD2FF', '#0088FF'],
                        hoverBackgroundColor: ['#CBD2FF', '#0088FF'],
                        borderColor: ['#CBD2FF', '#0088FF'],
                        hoverBorderColor: ['#CBD2FF', '#0088FF'],
                        borderRadius: 15,
                        borderWidth: 0,
                        weight: .2, //изменяет ширину полоски
                        spacing: 3, //изменяет расстояние между полосками
                        hoverBorderWidth: 3, // Увеличивает ширину бордера при наведении
                    },
                    {
                        label: 'Total amount',
                        data: [percentTotalAmount],
                        backgroundColor: isDarkMode ? '#121212' : '#f5f7fa',
                        hoverBackgroundColor: isDarkMode ? '#121212' : '#f5f7fa',
                        borderColor: isDarkMode ? '#121212' : '#f5f7fa',
                        hoverBorderColor: isDarkMode ? '#121212' : '#f5f7fa',
                        borderWidth: 0,
                        hoverBorderWidth: 7,
                        // @ts-ignore
                        radius: '85%',
                    }
                ]
            },
            options: {
                // @ts-ignore
                responsive: true, // Устанавливает график, чтобы он автоматически подстраивался
                maintainAspectRatio: false, // Отключает ограничение пропорций
                aspectRatio: 1, // Пропорции канваса
                layout: {
                    padding: {
                        top: 20,
                        right: 30,
                        bottom: 20,
                        left: 30,
                    },
                },

                plugins: {
                    tooltip: {
                        enabled: true,
                        position: 'nearest',
                        displayColors: false,
                        borderWidth: 50,
                        callbacks: {
                            title: function (tooltipItem) {
                                const datasetLabel = tooltipItem[0].dataset.label;
                                const dataLabel = tooltipItem[0].label;

                                if (datasetLabel === 'Total amount') { // Проверяем dataset label
                                    return `${i18n.t('totalAmount')}`;
                                } else if (datasetLabel === 'Paid & Unpaid') {
                                    const index = tooltipItem[0].dataIndex;

                                    if (index === 0) { // Для первой линии (Paid)
                                        return `${i18n.t('payed')}`;
                                    } else if (index === 1) { // Для второй линии (Unpaid)
                                        return `${i18n.t('unpayed')}`;
                                    }
                                }
                                return ''; // Возвращаем пустую строку, если ни одно условие не выполнилось
                            },

                            label: function (tooltipItem) {
                                const label = tooltipItem.dataset.label;
                                const index = tooltipItem.dataIndex;

                                let lines: string[] = [];

                                if (label === 'Paid & Unpaid') {
                                    if (index === 0) { // Для Paid
                                        lines.push(`${i18n.t('wallets')}: ${chartData.wallets.payed} Pcs`);
                                        lines.push(`${i18n.t('invoices')}: ${chartData.invoices.payed} Pcs`);
                                        lines.push(`${i18n.t('amount')}: ${totalPayd} Pcs`);
                                    } else if (index === 1) { // Для Unpaid
                                        lines.push(`${i18n.t('wallets')}: ${chartData.wallets.unpayed} Pcs`);
                                        lines.push(`${i18n.t('invoices')}: ${chartData.invoices.unpayed} Pcs`);
                                        lines.push(`${i18n.t('amount')}: ${totalUnpayd} Pcs`);
                                    }
                                } else if (label === 'Total amount') {
                                    lines.push(`${i18n.t('payed')}: ${chartData.wallets.payed + chartData.invoices.payed} Pcs`);
                                    lines.push(`${i18n.t('unpayed')}: ${chartData.wallets.unpayed + chartData.invoices.unpayed} Pcs`);
                                    lines.push(`${i18n.t('amount')}: ${totalAmount} Pcs`);
                                }
                                return lines;
                            }

                        }
                    },
                    legend: {
                        display: false,
                    },
                    datalabels: {
                        color: isDarkMode ? 'white' : 'black',
                        textAlign: 'center',
                        formatter: (value) => Math.round(value) + '%',
                        // @ts-ignore
                        anchor: function (context) {
                            const index = context.dataIndex;
                            const datasetIndex = context.datasetIndex;

                            // Для каждого набора данных устанавливаем якорь
                            if (datasetIndex === 0) { // Для первого набора данных (Paid & Unpaid)
                                if (index === 0) return 'center';  // Для Paid
                                if (index === 1) return 'center';    // Для Unpaid
                            } else if (datasetIndex === 1) { // Для второго набора данных (TotalAmount)
                                return 'center'; // TotalAmount будет по центру
                            }

                        },
                        // @ts-ignore
                        align: function (context) {
                            const index = context.dataIndex;
                            const datasetIndex = context.datasetIndex;

                            // Для каждого набора данных задаем выравнивание
                            if (datasetIndex === 0) {  // Для первого набора данных (Paid & Unpaid)
                                if (index === 0) return 'end';   // Для Paid
                                if (index === 1) return 'end';  // Для Unpaid
                            } else if (datasetIndex === 1) {  // Для второго набора данных (TotalAmount)
                                return 'top';  // TotalAmount будет по центру
                            }
                        },
                        // @ts-ignore
                        offset: function (context) {
                            const index = context.dataIndex;
                            const datasetIndex = context.datasetIndex;

                            // Для каждого набора данных задаем смещение
                            if (datasetIndex === 0) { // Для первого набора данных (Paid & Unpaid)
                                if (index === 0) return 10;  // Для Paid
                                if (index === 1) return 10;  // Для Unpaid
                            } else if (datasetIndex === 1) { // Для второго набора данных (TotalAmount)
                                return 10;  // TotalAmount по центру
                            }
                        },
                        // @ts-ignore
                        clamp: function (context) {
                            const index = context.dataIndex;
                            const datasetIndex = context.datasetIndex;

                            // Для каждого набора данных задаем смещение
                            if (datasetIndex === 0) { // Для первого набора данных (Paid & Unpaid)
                                if (index === 0) return false;  // Для Paid
                                if (index === 1) return false;  // Для Unpaid
                            } else if (datasetIndex === 1) { // Для второго набора данных (TotalAmount)
                                return true;  // TotalAmount по центру
                            }

                        },
                    }
                }
            },
            plugins: [ChartDataLabels]
        });

        // Сохраняем ссылку на график
        chartInstanceRef.current = chart;
    }

    useEffect(() => {
        if (chartData) {
            renderChart(chartData);
        }
    }, [chartData]);

    const { i18n } = useTranslation();
    const periodText = {
        day: { label: i18n.t('day'), subtitle: i18n.t('dailyStatistic') },
        week: { label: i18n.t('week'), subtitle: i18n.t('weeklyStatistic') },
        month: { label: i18n.t('month'), subtitle: i18n.t('moonthlyStatistic') }
    };
    const currentPeriodText = periodText[period] || periodText.day;

    // Функция проверки, есть ли данные для отображения
    const hasData = (data: any) => {
        if (!data) return false;
        return (
            (data.wallets?.payed || 0) > 0 ||
            (data.wallets?.unpayed || 0) > 0 ||
            (data.invoices?.payed || 0) > 0 ||
            (data.invoices?.unpayed || 0) > 0
        );
    };

    return (
        <div className={`${styles.chartItem} ${mediaStyles.chartItem}`}>
            <div className={`${styles.chartWrapper} ${mediaStyles.chartWrapper}`}>
                <div className={`${styles.chartTitle} ${mediaStyles.chartTitle}`}>
                    {i18n.t('payedUnpayed')}
                    <span className={`${styles.chartSpan} ${mediaStyles.chartSpan}`}>({currentPeriodText.label})</span>
                </div>
                <div className={`${styles.chartSubtitle} ${mediaStyles.chartSubtitle}`}>{currentPeriodText.subtitle}</div>
            </div>
            <div className={`${styles.graphContainer} ${mediaStyles.graphContainer}`}>
                {isLoading ? (
                    <div className={`${styles.loading} ${mediaStyles.loading}`}>Loading...</div>
                ) : hasData(chartData) ? (
                    <>
                        <div className={`${styles.graph} ${mediaStyles.graph}`}>
                            <canvas ref={chartRef} className={`${styles.graph} ${mediaStyles.graph}`} id="chart1"></canvas>
                        </div>
                        <div className={`${styles.chartTable} ${mediaStyles.chartTable}`}>
                            <div className={`${styles.chartText} ${mediaStyles.chartText}`}><span style={{ backgroundColor: "#0088FF" }}
                                className={`${styles.chartBox} ${mediaStyles.chartBox}`}></span>{i18n.t('unpayed')}</div>
                            <div className={`${styles.chartText} ${mediaStyles.chartText}`}><span style={{ backgroundColor: "#CBD2FF" }}
                                className={`${styles.chartBox} ${mediaStyles.chartBox}`}></span>{i18n.t('payed')}</div>
                        </div>
                    </>
                ) : (
                    <div className={`${styles.noData} ${mediaStyles.noData}`} style={{ textAlign: 'center', padding: '40px', fontSize: '18px' }}>
                        No Data
                    </div>
                )}
            </div>
        </div >
    );
}

export default FirstGraph;
