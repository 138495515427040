import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import { Registration, Login, ResetPassword, Confirm, ChangePassword } from '../Pages.ts';
import { videos } from '../../assets/video/media.ts';
import { useMenu } from '../../tools/MenuContext.tsx';
import { useTranslation } from 'react-i18next';
import { SunSvg, MoonSvg, LangSvg } from '../../assets/svg-files/SvgIcons.ts';
// @ts-ignore
import styles from './Authorization.module.scss';
// @ts-ignore
import mediaStyles from './Authorization-media.module.scss';

const Authorization = () => {
    const [isDarkMode, setIsDarkMode] = useState(() => {
        const savedTheme = localStorage.getItem('theme');
        if (savedTheme) {
            return savedTheme === 'dark';
        }
        return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    });

    useEffect(() => {
        const root = document.documentElement;

        // Функция для установки темы
        const setTheme = (isDarkMode) => {
            if (isDarkMode) {
                root.style.colorScheme = 'dark';
                localStorage.setItem('theme', 'dark');
            } else {
                root.style.colorScheme = 'light';
                localStorage.setItem('theme', 'light');
            }
        };

        setTheme(isDarkMode);
    }, [isDarkMode]);


    useEffect(() => {
        const savedTheme = localStorage.getItem('theme');
        const root = document.documentElement;
        if (savedTheme === 'dark') {
            root.style.colorScheme = 'dark';
        } else {
            root.style.colorScheme = 'light';
        }
    }, []);

    const toggleTheme = () => {
        setIsDarkMode((prevMode) => !prevMode);
    };

    const { i18n } = useTranslation();
    const [language, setLanguage] = useState(() => {
        return localStorage.getItem('language') || i18n.language;
    });

    useEffect(() => {
        i18n.changeLanguage(language);
        localStorage.setItem('language', language);
    }, [language, i18n]);

    const toggleLanguage = () => {
        setLanguage((prevLanguage) => (prevLanguage === 'ru' ? 'en' : 'ru'));
    };

    return (
        <div className={`${styles.Authorization} ${mediaStyles.Authorization}`}>
            <video className={`${styles.AuthorizationVideo} ${mediaStyles.AuthorizationVideo}`} src={videos.test} autoPlay playsInline muted loop controls={false} preload="auto">
            </video>
            <div className={`${styles.AuthorizationContainer} ${mediaStyles.AuthorizationContainer}`}>
                <ul className={`${styles.AuthorizationWrapper} ${mediaStyles.AuthorizationWrapper}`}>
                    <li>
                        <button className={styles.AuthorizationItem} onClick={toggleTheme}>
                            {isDarkMode ? <MoonSvg className={styles.AuthorizationSvg} /> : <SunSvg className={styles.AuthorizationSvg} />}
                        </button>
                    </li>
                    <li>
                        <button onClick={toggleLanguage} className={styles.AuthorizationItem}>
                            <LangSvg className={styles.AuthorizationSvg} />
                        </button>
                    </li>
                </ul>
                <Routes>
                    <Route index element={<Login />} />
                    <Route path="Login" element={<Login />} />
                    <Route path="Registration" element={<Registration />} />
                    <Route path="ResetPassword" element={<ResetPassword />} />
                    <Route path="Confirm" element={<Confirm />} />
                    <Route path="ChangePassword" element={<ChangePassword />} />
                </Routes>
            </div>
        </div>
    );
};

export default Authorization;

