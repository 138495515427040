import React, { Component } from 'react'
// @ts-ignore
import styles from './Footer.module.scss';
// @ts-ignore
import mediaStyles from './Footer-media.module.scss';

export default class Footer extends Component {
    render() {
        return (
            <footer className={`${styles.footer} ${mediaStyles.footer}`}>
                <div className={`${styles.footerText} ${mediaStyles.footerText}`}>NORD PAY LLC. 2025 ©</div>
            </footer>
        )
    }
}
