import React, { createContext, useContext, useState, useEffect } from 'react';

interface MenuContextType {
    isMenuOpen: boolean;
    toggleMenu: () => void;
}

const MenuContext = createContext<MenuContextType | undefined>(undefined);

export const MenuProvider: React.FC = ({ children }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    // Обработчик для изменения состояния меню в зависимости от ширины экрана
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 980) {
                setIsMenuOpen(false); // Если ширина больше 980px, закрыть меню
            }
        };

        // Инициализируем проверку при первом рендере
        handleResize();

        // Слушаем изменения размера окна
        window.addEventListener('resize', handleResize);

        // Убираем слушатель при размонтировании компонента
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Пустой массив, чтобы эффект сработал только при монтировании и размонтировании компонента

    const toggleMenu = () => {
        setIsMenuOpen((prevState) => !prevState);
    };

    return (
        <MenuContext.Provider value={{ isMenuOpen, toggleMenu }}>
            {children}
        </MenuContext.Provider>
    );
};

export const useMenu = () => {
    const context = useContext(MenuContext);
    if (!context) {
        throw new Error('useMenu must be used within a MenuProvider');
    }
    return context;
};
