import React, { Component } from 'react';
import { MenuProvider } from './tools/MenuContext.tsx';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Aside from './components/Aside/Aside.tsx';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute.tsx';
import { Authorization, InvoiceCard } from './pages/Pages.ts';

export default class App extends Component {
  render() {
    return (
      //@ts-ignore
      <MenuProvider>
        <Router>
          <Routes>
            <Route path="Authorization/*" element={<Authorization />} />
            <Route path="/invoice-card/:id?" element={<InvoiceCard />} />
            <Route path="/*" element={<Aside />} />
          </Routes>
        </Router>
      </MenuProvider>
    );
  }
}
{/* <Route element={<ProtectedRoute />}>
              <Route element={<ProtectedRoute />}>
              <Route path="/*" element={<Aside />} />
            </Route>
</Route>  */}