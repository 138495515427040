import React, { useRef, useEffect, useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, Filler, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { useTranslation } from 'react-i18next';
import { useDashboardData } from '../DataProvider/DashboardDataProvider.tsx';
// @ts-ignore
import styles from './Graph.module.scss';
// @ts-ignore
import mediaStyles from './Graph-media.module.scss';

const hoverLine = {
    id: 'hoverLine',
    afterInit: (chart, args, opts) => {
        if (chart.config.type !== 'line') return;
        chart.verticalLiner = {};
    },
    afterEvent: (chart, args, options) => {
        if (chart.config.type !== 'line') return;
        const { inChartArea } = args;
        chart.verticalLiner = { draw: inChartArea };
    },
    beforeTooltipDraw: (chart, args, options) => {
        if (chart.config.type !== 'line') return;
        const { draw } = chart.verticalLiner;
        if (!draw) return;

        const { ctx } = chart;
        const { top, bottom } = chart.chartArea;
        const { tooltip } = args;
        const x = tooltip?.caretX;

        const lineWidth = 1;
        const padding = 10;

        ctx.save();
        ctx.fillStyle = 'rgba(168, 168, 168, 0.1)';
        ctx.fillRect(x - padding, top, padding * 2, bottom - top);
        ctx.beginPath();
        ctx.lineWidth = lineWidth;
        ctx.strokeStyle = '#4384F7';
        ctx.setLineDash([3, 3]);
        ctx.moveTo(x, top);
        ctx.lineTo(x, bottom);
        ctx.stroke();

        ctx.restore();
    }
};

const pointOverlayPlugin = {
    id: 'pointOverlay',
    beforeTooltipDraw: (chart, args, options) => {
        if (chart.config.type !== 'line') return;
        const { ctx } = chart;
        chart.data.datasets.forEach((dataset, datasetIndex) => {
            const meta = chart.getDatasetMeta(datasetIndex);
            if (meta.type === 'line') {
                meta.data.forEach((element, index) => {
                    if (!element.hidden) {
                        element.draw(ctx);
                    }
                });
            }
        });
    }
};

ChartJS.register(
    CategoryScale,
    Filler,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    pointOverlayPlugin,
    hoverLine
);

interface GraphProps {
    selectedPeriod: 'day' | 'week' | 'month';
}

const Graph: React.FC<GraphProps> = ({ selectedPeriod }) => {
    const { i18n } = useTranslation();
    const { data: dashboardData, isLoading, error } = useDashboardData();
    const now = new Date();
    const chartRef = useRef<any>(null);
    const [months, setMonths] = useState<string[]>([]);
    const [daysOfWeek, setDaysOfWeek] = useState<string[]>([]);
    const [labels, setLabels] = useState<string[]>([]);
    const [filteredData, setFilteredData] = useState<any[]>([]);

    useEffect(() => {
        const updatedMonths = [
            `${i18n.t('jan')}`, `${i18n.t('feb')}`, `${i18n.t('mar')}`, `${i18n.t('apr')}`,
            `${i18n.t('may')}`, `${i18n.t('jun')}`, `${i18n.t('jul')}`, `${i18n.t('aug')}`,
            `${i18n.t('sep')}`, `${i18n.t('oct')}`, `${i18n.t('nov')}`, `${i18n.t('dec')}`
        ];
        setMonths(updatedMonths);

        const updatedDaysOfWeek = [
            `${i18n.t('mon')}`, `${i18n.t('tue')}`, `${i18n.t('wed')}`, `${i18n.t('thu')}`,
            `${i18n.t('fri')}`, `${i18n.t('sat')}`, `${i18n.t('sun')}`
        ];
        setDaysOfWeek(updatedDaysOfWeek);
    }, [i18n.language]);

    useEffect(() => {
        if (dashboardData && dashboardData[selectedPeriod]) {
            const { filteredData: newFilteredData, labels: newLabels } = filterDataByPeriod(
                selectedPeriod,
                dashboardData[selectedPeriod] || []
            );

            setFilteredData(newFilteredData);
            setLabels(newLabels);
        }
    }, [selectedPeriod, dashboardData, months, daysOfWeek]);

    const filterDataByPeriod = (period: 'day' | 'week' | 'month', transactions: any[]) => {
        const filteredData: any[] = [];
        const labels: string[] = [];
        const currentDate = new Date();

        if (period === 'day') {
            const hours = Array.from({ length: 24 }, (_, i) => i);
            hours.forEach(hour => {
                const localDate = new Date();
                localDate.setHours(hour, 0, 0, 0);

                const hourTransactions = transactions.filter(tx => {
                    const txDate = new Date(tx.created_at);
                    return txDate.getHours() === hour &&
                        txDate.getDate() === currentDate.getDate() &&
                        txDate.getMonth() === currentDate.getMonth() &&
                        txDate.getFullYear() === currentDate.getFullYear();
                });

                const totalAmount = hourTransactions.reduce((sum, tx) => sum + tx.amount_usd, 0);

                filteredData.push({ time: hour, amount: totalAmount, amountValues: hourTransactions.length });
                labels.push(`${String(hour).padStart(2, '0')}`);
            });
        } else if (period === 'week') {
            const currentDate = new Date();
            const currentDay = currentDate.getDay();
            const daysToMonday = (currentDay === 0 ? 6 : currentDay - 1);

            const monday = new Date(currentDate);
            monday.setDate(currentDate.getDate() - daysToMonday);
            monday.setHours(0, 0, 0, 0);

            const sunday = new Date(monday);
            sunday.setDate(monday.getDate() + 6);
            sunday.setHours(23, 59, 59, 999);

            daysOfWeek.forEach((dayName, index) => {
                const dayDate = new Date(monday);
                dayDate.setDate(monday.getDate() + index);

                const dayTransactions = transactions.filter(tx => {
                    const txDate = new Date(tx.created_at);
                    return txDate >= dayDate && txDate < new Date(dayDate.getTime() + 24 * 60 * 60 * 1000);
                });

                const totalAmount = dayTransactions.reduce((sum, tx) => sum + tx.amount_usd, 0);

                filteredData.push({ day: dayName, amount: totalAmount, amountValues: dayTransactions.length });
                labels.push(dayName);
            });
        } else if (period === 'month') {
            const daysInMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();

            for (let day = 1; day <= daysInMonth; day++) {
                const dayTransactions = transactions.filter(tx => {
                    const txDate = new Date(tx.created_at);
                    return txDate.getDate() === day &&
                        txDate.getMonth() === now.getMonth() &&
                        txDate.getFullYear() === now.getFullYear();
                });

                const totalAmount = dayTransactions.reduce((sum, tx) => sum + tx.amount_usd, 0);

                filteredData.push({ day, amount: totalAmount, amountValues: dayTransactions.length });
                labels.push(String(day));
            }
        }

        return { filteredData, labels };
    };

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const colors = {
        blue: {
            default: "rgba(3, 97, 255, 1)",
            upper: "rgba(0, 98, 255, 1)",
            lower: "rgba(45, 73, 255, 0)",
        }
    };

    if (!ctx) {
        throw new Error('Не удалось получить контекст канваса');
    }

    // Создаем градиент
    const gradient = ctx.createLinearGradient(0, 25, 0, 300);
    gradient.addColorStop(0, colors.blue.upper);
    gradient.addColorStop(.5, colors.blue.lower);

    const chartData = useMemo(() => {
        const data = {
            labels: labels,
            datasets: [
                {
                    data: filteredData.map(item => item.amount),
                    backgroundColor: gradient,
                    pointBackgroundColor: colors.blue.default,
                    borderColor: colors.blue.default,
                    lineTension: 0.1,
                    borderWidth: 2,
                    fill: true,
                    pointRadius: (context) => {
                        if (context.dataIndex === context.dataset.data.length - 1) {
                            return 2;
                        } else {
                            return 0;
                        }
                    },
                },
            ],
        };

        return data;
    }, [i18n.language, filteredData, labels, gradient]);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        elements: {
            point: {
                radius: 3,
                backgroundColor: colors.blue.default,
                borderColor: 'transparent',
                borderWidth: 1,
                hoverRadius: 5,
                hoverBackgroundColor: 'white',
                hoverBorderColor: 'black',
                hoverBorderWidth: 2,
                pointStyle: 'circle',
            }
        },
        interaction: {
            intersect: false,
            mode: 'index' as const,
        },
        plugins: {
            datalabels: {
                display: false,
            },
            legend: {
                display: false,
            },
            tooltip: {
                caretPadding: 10,
                enabled: true,
                position: 'nearest' as const,
                yAlign: 'top' as const,
                displayColors: false,
                backgroundColor: '#93BCFF',
                titleColor: '#000',
                bodyColor: '#000',
                callbacks: {
                    title: (tooltipItems) => {
                        if (tooltipItems.length > 0) {
                            const index = tooltipItems[0].dataIndex;
                            const dataPoint = filteredData[index];
                            if (dataPoint) {
                                const totalAmount = tooltipItems[0].raw;
                                return `$ ${totalAmount.toFixed(2)}`;
                            }
                        }
                        return '';
                    },
                    label: (tooltipItems) => {
                        if (tooltipItems.dataIndex >= 0) {
                            const index = tooltipItems.dataIndex;
                            const dataPoint = filteredData[index];
                            if (dataPoint) {
                                const count = dataPoint.amountValues;
                                return `${i18n.t('topups')}: ${count}`;
                            }
                        }
                        return '';
                    },
                },
                padding: 10,
                display: 'auto',
            },
        },
        scales: {
            x: {
                border: {
                    display: false,
                },
                ticks: {
                    padding: 25,
                    min: 0,
                    max: labels.length - 1,
                },
                grid: {
                    display: false,
                },
            },
            y: {
                beginAtZero: true,
                border: {
                    dash: [4, 5],
                    display: false
                },
                ticks: {
                    padding: 15
                },
                grid: {
                    display: true,
                    tickLength: 0,
                    color: '#202020',
                    lineWidth: 1
                },
            }
        },
    };

    useEffect(() => {
        if (chartRef.current) {
            chartRef.current.clear();
        }
    }, [chartData]);

    const periodText = {
        day: { label: i18n.t('day'), subtitle: i18n.t('dailyStatistic') },
        week: { label: i18n.t('week'), subtitle: i18n.t('weeklyStatistic') },
        month: { label: i18n.t('month'), subtitle: i18n.t('moonthlyStatistic') },
    };

    const currentPeriodText = periodText[selectedPeriod] || periodText.day;

    return (
        <div className={`${styles.graph} ${mediaStyles.graph}`}>
            <div className={`${styles.graphContainer} ${mediaStyles.graphContainer}`}>
                <div className={`${styles.graphWrapper} ${mediaStyles.graphWrapper}`}>
                    <div className={`${styles.graphTitle} ${mediaStyles.graphTitle}`}>{i18n.t('transactions')}</div>
                    <div className={`${styles.graphSubtitle} ${mediaStyles.graphSubtitle}`}>{currentPeriodText.subtitle}</div>
                </div>
                <div style={{ height: "210px", width: "100%" }}>
                    {isLoading ? (
                        <div style={{ textAlign: 'center', padding: '40px' }}>Loading...</div>
                    ) : error ? (
                        <div style={{ textAlign: 'center', padding: '40px', color: 'red' }}>
                            {error}
                        </div>
                    ) : (
                        <Line data={chartData} key={i18n.language} options={options} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Graph;
