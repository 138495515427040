import React, { useState } from 'react';
import { CrownSvg, AdvantageSvg, TronSvg, ErcSvg, BnbSvg } from '../../../../assets/svg-files/SvgIcons.ts';
// @ts-ignore
import styles from './Subscriptions.module.scss';
// @ts-ignore
import mediaStyles from './Subscriptions-media.module.scss';

const Subscriptions = () => {
    const [activePeriod, setActivePeriod] = useState<'Monthly' | 'Yearly'>('Monthly');
    const [activeSubscription, setActiveSubscription] = useState<'Free' | 'Pro' | 'Enterprise'>('Free');
    const [selectedPayment, setSelectedPayment] = useState<string | null>(null);

    const handlePaymentSelection = (paymentType: string) => {
        setSelectedPayment(prev => (prev === paymentType ? null : paymentType));
    };

    return (
        <div className={`${styles.subscriptions} ${mediaStyles.subscriptions}`}>
            <div className={`${styles.subscriptionsWrapperTitle} ${mediaStyles.subscriptionsWrapperTitle}`}>
                <h3 className={`${styles.subscriptionsTitleTitle} ${mediaStyles.subscriptionsTitleTitle}`}>Подписки</h3>
                <button
                    className={`${styles.subscriptionsTitlePeriod} ${mediaStyles.subscriptionsTitlePeriod} ${activePeriod === 'Monthly' ? styles.subscriptionsTitlePeriodActive : ''}`}
                    onClick={() => setActivePeriod('Monthly')}>
                    Monthly
                </button>
                <button
                    className={`${styles.subscriptionsTitlePeriod} ${mediaStyles.subscriptionsTitlePeriod} ${activePeriod === 'Yearly' ? styles.subscriptionsTitlePeriodActive : ''}`}
                    onClick={() => setActivePeriod('Yearly')}>
                    Yearly
                </button>
            </div>

            <div className={`${styles.subscriptionsWrapperMain} ${mediaStyles.subscriptionsWrapperMain}`}>
                <div
                    className={`${styles.subscriptionsItem} ${mediaStyles.subscriptionsItem} ${activeSubscription === 'Free' ? styles.subscriptionsItemActive : ''}`}
                    onClick={() => setActiveSubscription('Free')}>
                    <div className={`${styles.subscriptionsInfo} ${mediaStyles.subscriptionsInfo}`}>
                        <h5 className={`${styles.subscriptionsInfoTitle} ${mediaStyles.subscriptionsInfoTitle}`}>Free</h5>
                        <p className={`${styles.subscriptionsInfoText} ${mediaStyles.subscriptionsInfoText}`}>Try all the essential features gor free and experience the conviniencee of nord pay</p>
                        <p className={`${styles.subscriptionsInfoPrice} ${mediaStyles.subscriptionsInfoPrice}`}><span className={`${styles.subscriptionsSpan} ${mediaStyles.subscriptionsSpan}`}>$0</span>Forever</p>
                    </div>
                    <div className={`${styles.subscriptionsAdvantages} ${mediaStyles.subscriptionsAdvantages}`}>
                        <p className={`${styles.subscriptionsAdvantage} ${mediaStyles.subscriptionsAdvantage}`}><AdvantageSvg className={`${styles.subscriptionsAdvantageSvg} ${mediaStyles.subscriptionsAdvantageSvg}`} />Max API keys: 3</p>
                        <p className={`${styles.subscriptionsAdvantage} ${mediaStyles.subscriptionsAdvantage}`}><AdvantageSvg className={`${styles.subscriptionsAdvantageSvg} ${mediaStyles.subscriptionsAdvantageSvg}`} />Max Wallets: 1000 per month</p>
                    </div>
                </div>
                <div className={`${styles.subscriptionsItem} ${mediaStyles.subscriptionsItem} ${activeSubscription === 'Pro' ? styles.subscriptionsItemActive : ''}`}
                    onClick={() => setActiveSubscription('Pro')}>
                    <div className={`${styles.subscriptionsInfo} ${mediaStyles.subscriptionsInfo}`}>
                        <h5 className={`${styles.subscriptionsInfoTitle2} ${mediaStyles.subscriptionsInfoTitle2}`}>Pro <span className={`${styles.subscriptionsInfoTitleSpan} ${mediaStyles.subscriptionsInfoTitleSpan}`}><CrownSvg className={`${styles.subscriptionsCrownSvg} ${mediaStyles.subscriptionsCrownSvg}`} />RECOMMENDED</span></h5>
                        <p className={`${styles.subscriptionsInfoText} ${mediaStyles.subscriptionsInfoText}`}>Designed for professionals and small businesses.</p>
                        <p className={`${styles.subscriptionsInfoPrice} ${mediaStyles.subscriptionsInfoPrice}`}>
                            <span className={`${styles.subscriptionsSpan} ${mediaStyles.subscriptionsSpan}`}>
                                ${activePeriod === 'Monthly' ? '32' : '350'}
                            </span>
                            {activePeriod === 'Monthly' ? 'Per month' : 'Per year'}</p>
                    </div>
                    <div className={`${styles.subscriptionsAdvantages} ${mediaStyles.subscriptionsAdvantages}`}>
                        <p className={`${styles.subscriptionsAdvantage} ${mediaStyles.subscriptionsAdvantage}`}><AdvantageSvg className={`${styles.subscriptionsAdvantageSvg} ${mediaStyles.subscriptionsAdvantageSvg}`} />Customizable invoice</p>
                        <p className={`${styles.subscriptionsAdvantage} ${mediaStyles.subscriptionsAdvantage}`}><AdvantageSvg className={`${styles.subscriptionsAdvantageSvg} ${mediaStyles.subscriptionsAdvantageSvg}`} />Max API keys: 10</p>
                        <p className={`${styles.subscriptionsAdvantage} ${mediaStyles.subscriptionsAdvantage}`}><AdvantageSvg className={`${styles.subscriptionsAdvantageSvg} ${mediaStyles.subscriptionsAdvantageSvg}`} />Unlimited invoices</p>
                    </div>
                </div>
                <div className={`${styles.subscriptionsItem} ${mediaStyles.subscriptionsItem} ${activeSubscription === 'Enterprise' ? styles.subscriptionsItemActive : ''}`}
                    onClick={() => setActiveSubscription('Enterprise')}>
                    <div className={`${styles.subscriptionsInfo} ${mediaStyles.subscriptionsInfo}`}>
                        <h5 className={`${styles.subscriptionsInfoTitle3} ${mediaStyles.subscriptionsInfoTitle3}`}>Enterprise</h5>
                        <p className={`${styles.subscriptionsInfoText} ${mediaStyles.subscriptionsInfoText}`}>For large businesses and organizations with unique requiements.</p>
                        <span className={`${styles.subscriptionsSpan} ${mediaStyles.subscriptionsSpan}`}>Availible upon request only</span>
                    </div>
                    <div className={`${styles.subscriptionsAdvantages} ${mediaStyles.subscriptionsAdvantages}`}>
                        <p className={`${styles.subscriptionsAdvantage} ${mediaStyles.subscriptionsAdvantage}`}><AdvantageSvg className={`${styles.subscriptionsAdvantageSvg} ${mediaStyles.subscriptionsAdvantageSvg}`} />Individual tech. support</p>
                        <p className={`${styles.subscriptionsAdvantage} ${mediaStyles.subscriptionsAdvantage}`}><AdvantageSvg className={`${styles.subscriptionsAdvantageSvg} ${mediaStyles.subscriptionsAdvantageSvg}`} />Custom percentage</p>
                        <p className={`${styles.subscriptionsAdvantage} ${mediaStyles.subscriptionsAdvantage}`}><AdvantageSvg className={`${styles.subscriptionsAdvantageSvg} ${mediaStyles.subscriptionsAdvantageSvg}`} />Personalized integration</p>
                        <p className={`${styles.subscriptionsAdvantage} ${mediaStyles.subscriptionsAdvantage}`}><AdvantageSvg className={`${styles.subscriptionsAdvantageSvg} ${mediaStyles.subscriptionsAdvantageSvg}`} />Special terms and conditions</p>
                    </div>
                </div>
            </div>
            {activeSubscription === 'Pro' && (
                <>
                    <h4 className={`${styles.subscriptionsTitle} ${mediaStyles.subscriptionsTitle}`}>Выберите способ оплаты</h4>

                    <div className={`${styles.subscriptionsWrapperPayment} ${mediaStyles.subscriptionsWrapperPayment}`}>
                        <div className={`${styles.subscriptionsPaymentItem} ${mediaStyles.subscriptionsPaymentItem} ${selectedPayment === 'TRC20' ? styles.subscriptionsPaymentItemActive : ''}`}
                            onClick={() => handlePaymentSelection('TRC20')}>
                            <div className={`${styles.subscriptionsPaymentText} ${mediaStyles.subscriptionsPaymentText}`}>
                                <h5 className={`${styles.subscriptionsPaymentTitle} ${mediaStyles.subscriptionsPaymentTitle}`}>USDT TRC20</h5>
                                <p className={`${styles.subscriptionsPaymentSubtitle} ${mediaStyles.subscriptionsPaymentSubtitle}`}>TRON Network</p>
                            </div>
                            <TronSvg className={`${styles.subscriptionsSvg} ${mediaStyles.subscriptionsSvg}`} />
                        </div>
                        <div className={`${styles.subscriptionsPaymentItem} ${mediaStyles.subscriptionsPaymentItem} ${selectedPayment === 'ERC20' ? styles.subscriptionsPaymentItemActive : ''}`}
                            onClick={() => handlePaymentSelection('ERC20')}>
                            <div className={`${styles.subscriptionsPaymentText} ${mediaStyles.subscriptionsPaymentText}`}>
                                <h5 className={`${styles.subscriptionsPaymentTitle} ${mediaStyles.subscriptionsPaymentTitle}`}>USDT ERC20</h5>
                                <p className={`${styles.subscriptionsPaymentSubtitle} ${mediaStyles.subscriptionsPaymentSubtitle}`}>Ethereum Network</p>
                            </div>
                            <ErcSvg className={`${styles.subscriptionsSvg} ${mediaStyles.subscriptionsSvg}`} />
                        </div>
                        <div className={`${styles.subscriptionsPaymentItem} ${mediaStyles.subscriptionsPaymentItem} ${selectedPayment === 'BEP20' ? styles.subscriptionsPaymentItemActive : ''}`}
                            onClick={() => handlePaymentSelection('BEP20')}>
                            <div className={`${styles.subscriptionsPaymentText} ${mediaStyles.subscriptionsPaymentText}`}>
                                <h5 className={`${styles.subscriptionsPaymentTitle} ${mediaStyles.subscriptionsPaymentTitle}`}>USDT BEP20</h5>
                                <p className={`${styles.subscriptionsPaymentSubtitle} ${mediaStyles.subscriptionsPaymentSubtitle}`}>BNB Network</p>
                            </div>
                            <BnbSvg className={`${styles.subscriptionsSvg} ${mediaStyles.subscriptionsSvg}`} />
                        </div>
                    </div>

                    <button className={`${styles.subscriptionsButton} ${mediaStyles.subscriptionsButton}`}>Оплатить</button>
                </>
            )}
        </div>
    );
};

export default Subscriptions;