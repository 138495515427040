import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MainProfile, Subscriptions, Safety, Notifications, Users, Addresses } from '../Pages.ts';
import { ProfileSvg, StarSvg, LockSvg, NotificationsSvg, UsersSvg, AddressesSvg, PencilSvg } from '../../assets/svg-files/SvgIcons.ts';
import { profileImage } from '../../assets/images/images.ts';
import { apiRequest } from '../../data/Api.ts';
// @ts-ignore
import styles from './Profile.module.scss';
// @ts-ignore
//import mediaStyles from './Profile-media.module.scss';

// Интерфейс для данных профиля пользователя
interface UserProfile {
    id: number;
    photo?: string;
    name?: string;
    email: string;
    country: string;
    status: string;
}

const Profile = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const storedTab = localStorage.getItem('activeTab') || 'main';
    const [activeTab, setActiveTab] = useState<string>(storedTab);
    const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const tabs = [
        { key: "main", name: <><ProfileSvg className={`${styles.profileSvg}`} />{t('profile.tabs.main')}</>, component: <MainProfile /> },
        { key: "subscriptions", name: <><StarSvg className={`${styles.profileSvg}`} /> {t('profile.tabs.subscriptions')}</>, component: <Subscriptions /> },
        { key: "safety", name: <><LockSvg className={`${styles.profileSvg}`} />{t('profile.tabs.safety')}</>, component: <Safety /> },
        { key: "notifications", name: <><NotificationsSvg className={`${styles.profileSvg}`} />{t('profile.tabs.notifications')}</>, component: <Notifications /> },
        //{ key: "users", name: <><UsersSvg className={`${styles.profileSvg}`} />Пользователи</>, component: <Users /> },
        { key: "addresses", name: <><AddressesSvg className={`${styles.profileSvg}`} />{t('profile.tabs.addresses')}</>, component: <Addresses /> },
    ];

    // Получение данных профиля пользователя
    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                setIsLoading(true);
                const response = await apiRequest<UserProfile>('/pay/user/profile/', 'GET');
                if (response) {
                    setUserProfile(response);
                }
            } catch (error) {
                console.error(t('profile.error.fetchProfile'), error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchUserProfile();
    }, [t]);

    useEffect(() => {
        localStorage.setItem('activeTab', activeTab);
    }, [activeTab]);

    useEffect(() => {
        const tabFromLocalStorage = localStorage.getItem('activeTab');
        if (tabFromLocalStorage && tabFromLocalStorage !== activeTab) {
            setActiveTab(tabFromLocalStorage);
        }
    }, [location]);

    const handleTabClick = (key: string) => {
        setActiveTab(key);
        navigate('/pages/Profile', { replace: true });
    };

    return (
        <div className={`${styles.profile}`}>
            <div className={`${styles.profileWrapper}`}>
                <img src={profileImage.test} className={`${styles.profileAvatar}`} alt="avatar" />

                <div className={`${styles.profileTitle}`}>{t('profile.welcome')} <span className={`${styles.profileUsername}`}>
                    {isLoading ? t('profile.loading') : (userProfile?.name || userProfile?.email || t('profile.unknownUser'))}
                </span></div>
            </div>
            <nav className={`${styles.profileNav}`}>
                <ul >
                    {tabs.map((tab) => (
                        <li key={tab.key}>
                            <button
                                className={`${styles.profileButton} ${activeTab === tab.key ? styles.active : ""}`}
                                onClick={() => handleTabClick(tab.key)}
                            >
                                {tab.name}
                            </button>
                        </li>
                    ))}
                </ul>
            </nav>
            <div className={`${styles.profileContent}`}>
                {tabs.find(tab => tab.key === activeTab)?.component || <MainProfile />}
            </div>
        </div >
    );
};
export default Profile;