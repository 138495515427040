import React, { useState, useEffect } from 'react';
import { apiRequest, getUserProfile, UserProfile } from '../../../../data/Api.ts';
// @ts-ignore
import styles from './Notifications.module.scss';
// @ts-ignore
import mediaStyles from './Notifications-media.module.scss';

// Компонент Checkbox
const Checkbox = ({ id, label, checked, onChange, disabled }) => (
    <div className={`${styles.checkboxWrapper}`}>
        <input
            type="checkbox"
            className={`${styles.checkboxCheckbox}`}
            id={id}
            checked={checked}
            onChange={onChange}
            disabled={disabled}
        />
        <label className={`${styles.checkboxLabel}`} htmlFor={id}>
            <span className={`${styles.checkboxIcon}`}>
                <svg width="12px" height="10px">
                    <use xlinkHref="#check-4"></use>
                </svg>
            </span>
        </label>
        <svg className={`${styles.checkboxSvg}`}>
            <symbol id="check-4" viewBox="0 0 12 10">
                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
            </symbol>
        </svg>
        <span className={`${styles.checkboxSpan}`}>{label}</span>
    </div>
);

const Notifications = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
    const [profileLoading, setProfileLoading] = useState(true);
    const [telegramConnected, setTelegramConnected] = useState(false);

    // Загрузка профиля пользователя
    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                setProfileLoading(true);
                const data = await getUserProfile();
                setUserProfile(data);

                // Проверяем подключен ли Telegram
                const isTelegramConnected = data.telegram_code !== null;
                setTelegramConnected(isTelegramConnected);

                if (isTelegramConnected) {
                    // Если Telegram подключен, активируем все Telegram уведомления автоматически
                    const newTelegramState = {
                        authorizationTelegram: true,
                        withdrawalTelegram: true,
                        invoicePartiallyPaidTelegram: true,
                        invoicePaidTelegram: true,
                    };
                    setTelegramEnabled(true);
                    setTelegramCheckboxes(newTelegramState);

                    // Сохраняем в localStorage обновленные настройки
                    localStorage.setItem('telegramEnabled', JSON.stringify(true));
                    localStorage.setItem('telegramCheckboxes', JSON.stringify(newTelegramState));

                    // Отправляем на сервер обновленные настройки
                    const currentEmailState = loadFromLocalStorage().emailCheckboxes;
                    sendNotificationsToServer(currentEmailState, newTelegramState);
                } else {
                    // Если Telegram не подключен, отключаем все Telegram уведомления
                    const disabledTelegramState = {
                        authorizationTelegram: false,
                        withdrawalTelegram: false,
                        invoicePartiallyPaidTelegram: false,
                        invoicePaidTelegram: false,
                    };
                    setTelegramEnabled(false);
                    setTelegramCheckboxes(disabledTelegramState);

                    // Сохраняем в localStorage обновленные настройки
                    localStorage.setItem('telegramEnabled', JSON.stringify(false));
                    localStorage.setItem('telegramCheckboxes', JSON.stringify(disabledTelegramState));

                    // Отправляем на сервер обновленные настройки
                    const currentEmailState = loadFromLocalStorage().emailCheckboxes;
                    sendNotificationsToServer(currentEmailState, disabledTelegramState);
                }
            } catch (error) {
                console.error('Ошибка при получении профиля пользователя:', error);
            } finally {
                setProfileLoading(false);
            }
        };

        fetchUserProfile();
    }, []);

    const loadFromLocalStorage = () => {
        const emailEnabled = JSON.parse(localStorage.getItem('emailEnabled') || 'true');
        const telegramEnabled = JSON.parse(localStorage.getItem('telegramEnabled') || 'true');

        const emailCheckboxes = JSON.parse(localStorage.getItem('emailCheckboxes') || '{"authorizationEmail":true,"withdrawalEmail":true,"invoicePartiallyPaidEmail":true,"invoicePaidEmail":true}');
        const telegramCheckboxes = JSON.parse(localStorage.getItem('telegramCheckboxes') || '{"authorizationTelegram":true,"withdrawalTelegram":true,"invoicePartiallyPaidTelegram":true,"invoicePaidTelegram":true}');

        return { emailEnabled, telegramEnabled, emailCheckboxes, telegramCheckboxes };
    };

    const { emailEnabled, telegramEnabled, emailCheckboxes, telegramCheckboxes } = loadFromLocalStorage();

    const [emailEnabledState, setEmailEnabled] = useState(emailEnabled);
    const [emailCheckboxesState, setEmailCheckboxes] = useState(emailCheckboxes);
    const [telegramEnabledState, setTelegramEnabled] = useState(telegramEnabled);
    const [telegramCheckboxesState, setTelegramCheckboxes] = useState(telegramCheckboxes);

    // Функция для отправки настроек уведомлений на сервер
    const sendNotificationsToServer = async (emailCheckboxes, telegramCheckboxes) => {
        try {
            setIsLoading(true);

            const requestData = {
                email_auth: emailCheckboxes.authorizationEmail,
                email_withdraw: emailCheckboxes.withdrawalEmail,
                email_invoice: emailCheckboxes.invoicePaidEmail,
                email_partially_invoice: emailCheckboxes.invoicePartiallyPaidEmail,
                tg_auth: telegramCheckboxes.authorizationTelegram,
                tg_withdraw: telegramCheckboxes.withdrawalTelegram,
                tg_invoice: telegramCheckboxes.invoicePaidTelegram,
                tg_partially_invoice: telegramCheckboxes.invoicePartiallyPaidTelegram
            };

            await apiRequest('/pay/user/profile/notifications', 'PATCH', requestData);

        } catch (error) {
            console.error('Error updating notification settings:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleMainCheckboxChange = (type, isEnabled) => {
        if (type === 'email') {
            const newEmailState = {
                authorizationEmail: isEnabled,
                withdrawalEmail: isEnabled,
                invoicePartiallyPaidEmail: isEnabled,
                invoicePaidEmail: isEnabled,
            };

            setEmailEnabled(isEnabled);
            setEmailCheckboxes(newEmailState);

            // Отправить запрос на сервер после обновления состояния
            sendNotificationsToServer(newEmailState, telegramCheckboxesState);
        } else if (type === 'telegram' && telegramConnected) {
            // Только если Telegram подключен, разрешаем изменение настроек
            const newTelegramState = {
                authorizationTelegram: isEnabled,
                withdrawalTelegram: isEnabled,
                invoicePartiallyPaidTelegram: isEnabled,
                invoicePaidTelegram: isEnabled,
            };

            setTelegramEnabled(isEnabled);
            setTelegramCheckboxes(newTelegramState);

            // Отправить запрос на сервер после обновления состояния
            sendNotificationsToServer(emailCheckboxesState, newTelegramState);
        }
    };

    const handleCheckboxChange = (type, key) => {
        if (type === 'email') {
            setEmailCheckboxes(prevState => {
                const newState = {
                    ...prevState,
                    [key]: !prevState[key],
                };

                const allUnchecked = Object.values(newState).every(value => !value);
                setEmailEnabled(!allUnchecked);

                // Отправить запрос на сервер после обновления состояния
                const updatedEmailState = { ...newState };
                sendNotificationsToServer(updatedEmailState, telegramCheckboxesState);

                return newState;
            });
        } else if (type === 'telegram' && telegramConnected) {
            // Только если Telegram подключен, разрешаем изменение настроек
            setTelegramCheckboxes(prevState => {
                const newState = {
                    ...prevState,
                    [key]: !prevState[key],
                };
                const allUnchecked = Object.values(newState).every(value => !value);
                setTelegramEnabled(!allUnchecked);

                // Отправить запрос на сервер после обновления состояния
                const updatedTelegramState = { ...newState };
                sendNotificationsToServer(emailCheckboxesState, updatedTelegramState);

                return newState;
            });
        }
    };

    useEffect(() => {
        localStorage.setItem('emailEnabled', JSON.stringify(emailEnabledState));
        localStorage.setItem('telegramEnabled', JSON.stringify(telegramEnabledState));
        localStorage.setItem('emailCheckboxes', JSON.stringify(emailCheckboxesState));
        localStorage.setItem('telegramCheckboxes', JSON.stringify(telegramCheckboxesState));
    }, [emailEnabledState, telegramEnabledState, emailCheckboxesState, telegramCheckboxesState]);

    return (
        <div className={`${styles.main} ${mediaStyles.main}`}>
            <h3 className={`${styles.mainTitle}`}>Notification</h3>
            {profileLoading ? (
                <div className={`${styles.loading}`}>Loading...</div>
            ) : (
                <div className={`${styles.mainContainer} ${mediaStyles.mainContainer}`}>
                    {/* Email блок */}
                    <div className={`${styles.mainWrapper} ${mediaStyles.mainWrapper}`}>
                        <div className={`${styles.mainBlock}`}>
                            <label className={`${styles.mainSwitch}`}>
                                <input
                                    className={`${styles.mainInput}`}
                                    type="checkbox"
                                    checked={emailEnabledState}
                                    onChange={() => handleMainCheckboxChange('email', !emailEnabledState)}
                                    disabled={isLoading}
                                />
                                <span className={`${styles.mainSlider}`}></span>
                            </label>
                            <p className={`${styles.mainSocial}`}>Email</p>
                        </div>
                        <div className={`${styles.mainCheckboxes}`}>
                            {Object.keys(emailCheckboxesState).map((key) => (
                                <Checkbox
                                    key={key}
                                    id={key}
                                    label={key
                                        .replace(/([A-Z])/g, ' $1')
                                        .replace(/^./, str => str.toUpperCase())
                                        .replace(/Email$/, '')}
                                    checked={emailCheckboxesState[key]}
                                    onChange={() => handleCheckboxChange('email', key)}
                                    disabled={!emailEnabledState || isLoading}
                                />
                            ))}
                        </div>
                    </div>

                    {/* Telegram блок */}
                    <div className={`${styles.mainWrapper} ${mediaStyles.mainWrapper}`}>
                        <div className={`${styles.mainBlock}`}>
                            <label className={`${styles.mainSwitch}`}>
                                <input
                                    className={`${styles.mainInput}`}
                                    type="checkbox"
                                    checked={telegramEnabledState}
                                    onChange={() => handleMainCheckboxChange('telegram', !telegramEnabledState)}
                                    disabled={!telegramConnected || isLoading}
                                />
                                <span className={`${styles.mainSlider}`}></span>
                            </label>
                            <p className={`${styles.mainSocial}`}>Telegram</p>
                        </div>
                        <div className={`${styles.mainCheckboxes}`}>
                            {Object.keys(telegramCheckboxesState).map((key) => (
                                <Checkbox
                                    key={key}
                                    id={key}
                                    label={key
                                        .replace(/([A-Z])/g, ' $1')
                                        .replace(/^./, str => str.toUpperCase())
                                        .replace(/Telegram$/, '')}
                                    checked={telegramCheckboxesState[key]}
                                    onChange={() => handleCheckboxChange('telegram', key)}
                                    disabled={!telegramEnabledState || !telegramConnected || isLoading}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Notifications;
