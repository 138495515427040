// @ts-ignore
import profileDefaultImage from "./avatar-placeholder.webp";
// @ts-ignore
import invoiceBanner from "./InvoiceCardImages/invoice_banner.png";
// @ts-ignore
import invoiceBackground from "./InvoiceCardImages/invoice_bg.png";

export const profileImage = {
    test: profileDefaultImage,
};

export const invoiceImages = {
    banner: invoiceBanner,
    background: invoiceBackground
};