import React, { useState, useRef, useEffect } from 'react';
import { PiEyeBold, PiEyeClosedBold } from "react-icons/pi";
import zxcvbn from "zxcvbn";
import { useTranslation } from 'react-i18next';
import { apiRequest, getUserProfile, UserProfile } from '../../../../data/Api.ts';
// @ts-ignore
import styles from './MainProfile.module.scss';
// @ts-ignore
import mediaStyles from './MainProfile-media.module.scss';

const MainProfile = () => {
    const { t } = useTranslation();
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const dialogRef = useRef<HTMLDialogElement | null>(null);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordErrors, setPasswordErrors] = useState<string[]>([]);
    const [passwordChanged, setPasswordChanged] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState(0);
    const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const passwordStrengthColors = ["#FF0000", "#FF0000", "#FFBF00", "#ffb136", "#00FF2B"];

    // Получение данных профиля пользователя
    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                setIsLoading(true);
                const data = await getUserProfile();
                setUserProfile(data);
            } catch (error) {
                console.error('Ошибка при получении данных профиля:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchUserProfile();
    }, []);

    const hasPasswordConditionsError =
        passwordErrors.includes(t('2condition')) ||
        passwordErrors.includes(t('3condition')) ||
        passwordErrors.includes(t('4condition'));

    const showConditionsError = formSubmitted && hasPasswordConditionsError && !passwordErrors.includes(t('1condition'));
    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newPassword = event.target.value;
        setPassword(newPassword);
        validatePassword(newPassword);
        setPasswordChanged(true);
    };
    const validatePassword = (password: string) => {
        const strength = zxcvbn(password).score;
        setPasswordStrength(strength);

        const errors: string[] = [];
        const latinLettersRegex = /^[A-Za-z0-9!@#$%^&*()_+=-]+$/;


        if (!latinLettersRegex.test(password)) {
            errors.push(t('1condition'));
        }
        if (password.length < 8) {
            errors.push(t('2condition'));
        }
        if (!/[A-Z]/.test(password)) {
            errors.push(t('3condition'));
        }
        if (!/\d/.test(password)) {
            errors.push(t('4condition'));
        }

        if (strength < 3 && password.length >= 8 && /[A-Z]/.test(password) && /\d/.test(password)) {
            errors.push(t('5condition'));
        }

        setPasswordErrors(errors);
    };
    const togglePasswordVisibility = (type: 'old' | 'new' | 'confirm') => {
        if (type === 'old') {
            setShowOldPassword((prev) => !prev);
        } else if (type === 'new') {
            setShowNewPassword((prev) => !prev);
        } else if (type === 'confirm') {
            setShowConfirmPassword((prev) => !prev);
        }
    };

    const openDialog = () => {
        if (dialogRef.current) {
            dialogRef.current.showModal();
        }
    };

    const closeDialog = () => {
        if (dialogRef.current) {
            dialogRef.current.close();
        }
    };

    const handleBackdropClick = (e) => {
        if (e.target === dialogRef.current) {
            closeDialog();
        }
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setFormSubmitted(true);

        if (!oldPassword.trim()) {
            alert(t('Введите текущий пароль'));
            return;
        }
        if (oldPassword === password) {
            alert(t('Текущий и новый пароли не должны совпадать'));
            return;
        }

        if (!password.trim()) {
            setPasswordErrors([t('createPass')]);
            return;
        }
        if (passwordErrors.length > 0 || hasPasswordConditionsError) {
            setPasswordErrors(passwordErrors);
            return;
        } else if (!confirmPassword.trim()) {
            alert(t('Подтвердите пароль'));
            return;
        } else if (password !== confirmPassword) {
            alert(t('Пароли не совпадают'));
            return;
        }

        try {
            // Отправка запроса на изменение пароля
            const requestData = {
                "old_password": oldPassword,
                "new_password": password
            };

            await apiRequest('/pay/user/profile/password', 'PATCH', requestData);

            alert(t('Пароль успешно изменен'));
            closeDialog();

            // Сброс полей формы
            setOldPassword('');
            setPassword('');
            setConfirmPassword('');
            setPasswordErrors([]);
            setFormSubmitted(false);
            setPasswordChanged(false);

        } catch (error) {
            console.error('Ошибка при изменении пароля:', error);
            alert(t('Не удалось изменить пароль. Возможно, текущий пароль указан неверно.'));
        }
    };


    return (
        <>
            <div className={`${styles.mainProfile} ${mediaStyles.mainProfile}`}>
                <div className={`${styles.mainProfileWrapper} ${mediaStyles.mainProfileWrapper}`}>
                    <h3 className={`${styles.mainProfileTitle} ${mediaStyles.mainProfileTitle}`}>User data</h3>

                    <div className={`${styles.mainProfileBlock} ${mediaStyles.mainProfileBlock}`}>
                        <div className={`${styles.mainProfileEmailBlock} ${mediaStyles.mainProfileEmailBlock}`}>
                            <h3 className={`${styles.mainProfileTitle} ${mediaStyles.mainProfileTitle}`}>Email address</h3>
                            <div className={`${styles.mainProfileEmail} ${mediaStyles.mainProfileEmail}`}>
                                {isLoading ? 'Загрузка...' : userProfile?.email || 'Email не найден'}
                            </div>
                        </div>
                        <div className={`${styles.mainProfileItem} ${mediaStyles.mainProfileItem}`}>
                            <h3 className={`${styles.mainProfileTitle} ${mediaStyles.mainProfileTitle}`}>Change password</h3>
                            <button onClick={openDialog} className={`${styles.mainProfileButton} ${mediaStyles.mainProfileButton}`}>
                                Поменять пароль
                            </button>
                        </div>
                    </div>
                </div>
            </div>


            {/* Модалка для изменения пароля */}

            <dialog ref={dialogRef} className={`${styles.dialog} ${mediaStyles.dialog}`} onClick={handleBackdropClick}>
                <div className={`${styles.dialogFormWrapper} ${mediaStyles.dialogFormWrapper}`}>
                    <form className={`${styles.dialogContainer} ${mediaStyles.dialogContainer}`} onSubmit={handleSubmit}>
                        <div className={`${styles.dialogHeader} ${mediaStyles.dialogHeader}`}>
                            <h2 className={`${styles.dialogTitle} ${mediaStyles.dialogTitle}`}>Change password</h2>
                            <button type="button" onClick={closeDialog}>X</button>
                        </div>
                        <div className={`${styles.dialogContent} ${mediaStyles.dialogContent}`}>
                            <div className={`${styles.dialogLabel} ${mediaStyles.dialogLabel}`}>
                                <span className={`${styles.dialogSpan} ${mediaStyles.dialogSpan}`}>Old password</span>
                                <label className={`${styles.dialogWrapper} ${mediaStyles.dialogWrapper}`}>
                                    <input
                                        className={`${styles.dialogInputPass} ${mediaStyles.dialogInputPass}`}
                                        type={showOldPassword ? "text" : "password"}
                                        value={oldPassword}
                                        onChange={(e) => setOldPassword(e.target.value)}
                                        placeholder="Enter old password"
                                    />
                                    <button
                                        type="button"
                                        onClick={() => togglePasswordVisibility('old')}
                                        className={`${styles.dialogEye} ${mediaStyles.dialogEye} ${passwordErrors.length > 0 ? styles.invalid : ""}`}
                                    >
                                        {showOldPassword ? <PiEyeBold size={20} /> : <PiEyeClosedBold size={20} />}
                                    </button>
                                </label>
                            </div>
                            <div className={`${styles.dialogLabel} ${mediaStyles.dialogLabel}`}>
                                <span className={`${styles.dialogSpan} ${mediaStyles.dialogSpan}`}>New password</span>
                                <div style={{ position: "relative" }}>
                                    {formSubmitted && password === "" && (
                                        <span className={`${styles.tooltip} ${mediaStyles.tooltip}`}>{t('createPass')}</span>
                                    )}
                                    {passwordErrors.length > 0 && password !== "" && (
                                        <span className={`${styles.tooltip} ${mediaStyles.tooltip}`}>{passwordErrors[0]}</span>
                                    )}
                                    {(formSubmitted || passwordChanged) && passwordErrors.includes(t('5condition')) && (
                                        <span className={`${styles.tooltip} ${mediaStyles.tooltip}`}>{t('5condition')}</span>
                                    )}
                                    <label className={`${styles.dialogWrapper} ${mediaStyles.dialogWrapper}`}>
                                        <input
                                            className={`${styles.dialogInputPass} ${mediaStyles.dialogInputPass} ${passwordErrors.length > 0 ? styles.invalid : ""}`}
                                            type={showNewPassword ? "text" : "password"}
                                            value={password}
                                            onChange={handlePasswordChange}
                                            placeholder="Пароль"
                                        />
                                        <button
                                            type="button"
                                            onClick={() => togglePasswordVisibility('new')}
                                            className={`${styles.dialogEye} ${mediaStyles.dialogEye} ${passwordErrors.length > 0 ? styles.invalid : ""}`}
                                        >
                                            {showNewPassword ? <PiEyeBold size={20} /> : <PiEyeClosedBold size={20} />}
                                        </button>
                                    </label>
                                </div>
                            </div>
                            <div className={`${styles.dialogLabel} ${mediaStyles.dialogLabel}`}>
                                <span className={`${styles.dialogSpan} ${mediaStyles.dialogSpan}`}>Confirm password</span>
                                <label className={`${styles.dialogWrapper} ${mediaStyles.dialogWrapper}`}>
                                    <input
                                        className={`${styles.dialogInputPass} ${mediaStyles.dialogInputPass}`}
                                        type={showConfirmPassword ? "text" : "password"}
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        placeholder="Confirm new password"
                                        onPaste={(e) => e.preventDefault()}
                                    />
                                    <button
                                        type="button"
                                        onClick={() => togglePasswordVisibility('confirm')}
                                        className={`${styles.dialogEye} ${mediaStyles.dialogEye} ${passwordErrors.length > 0 ? styles.invalid : ""}`}
                                    >
                                        {showConfirmPassword ? <PiEyeBold size={20} /> : <PiEyeClosedBold size={20} />}
                                    </button>
                                </label>
                            </div>
                        </div>
                        <button type="submit" className={`${styles.dialogButton} ${mediaStyles.dialogButton}`}>
                            Change password
                        </button>
                    </form>
                </div>
            </dialog>

        </>
    );
};

export default MainProfile;
