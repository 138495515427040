import React from 'react';
// @ts-ignore
import styles from './Topups.module.scss';
// @ts-ignore
import mediaStyles from './Topups-media.module.scss';

interface TopupItemProps {
  id: string;
  title: string;
  amount: string;
  percent: string | null;
  percentClass: string;
  itemClass?: string;
}


const TopupItem: React.FC<TopupItemProps> = ({ title, amount, percent, id, percentClass, itemClass = '' }) => {
  return (
    <div className={`${styles.topupsItem} ${mediaStyles.topupsItem} ${itemClass}`} data-type={id}>
      <div className={styles.topupsTitle}>{title}</div>
      <div className={`${styles.topupsInfo} ${mediaStyles.topupsInfo}`}>
        <span className={styles.topupsSum} id={`${id}-amount`}>{amount}</span>
        {percent && (
          <span className={`${styles.topupsPercent} ${mediaStyles.topupsPercent} ${percentClass}`} id={`${id}-percent`}>
            {percent}
          </span>
        )}
      </div>
    </div>
  );
};

export default TopupItem;
