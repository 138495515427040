import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import zxcvbn from "zxcvbn";
import { useTranslation } from 'react-i18next';
import { PiEyeBold, PiEyeClosedBold } from "react-icons/pi";
import Select from 'react-select';
import { BASE_URL } from "../../../data/config.js";
import { apiRequest } from "../../../data/Api.ts";

// @ts-ignore
import styles from '../Auth.module.scss';
// @ts-ignore
import mediaStyles from '../Auth-media.module.scss';


const Registration = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordStrength, setPasswordStrength] = useState(0);
    const [passwordErrors, setPasswordErrors] = useState<string[]>([]);
    const [showPassword, setShowPassword] = useState(false);
    const [passwordChanged, setPasswordChanged] = useState(false);
    const [errors, setErrors] = useState<{ email: boolean | string, password: string | boolean }>({
        email: false,
        password: false
    });
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [showError, setShowError] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState<{ value: string; label: string } | null>(null);
    const [countryError, setCountryError] = useState(false);
    const { t } = useTranslation();


    const passwordStrengthColors = ["#FF0000", "#FF0000", "#FFBF00", "#ffb136", "#00FF2B"];

    const options = [
        { value: 'ru', label: t('ru') },
        { value: 'ge', label: t('ge') },
        { value: 'ua', label: t('ua') },
        { value: 'by', label: t('by') },
    ];

    const validatePassword = (password: string) => {
        const strength = zxcvbn(password).score;
        setPasswordStrength(strength);

        const errors: string[] = [];
        const latinLettersRegex = /^[A-Za-z0-9!@#$%^&*()_+=-]+$/;

        if (!latinLettersRegex.test(password)) {
            errors.push(t('1condition'));
        }

        // Проверка длины пароля
        if (password.length < 8) {
            errors.push(t('2condition'));
        }
        if (!/[A-Z]/.test(password)) {
            errors.push(t('3condition'));
        }
        if (!/\d/.test(password)) {
            errors.push(t('4condition'));
        }

        if (strength < 3 && password.length >= 8 && /[A-Z]/.test(password) && /\d/.test(password)) {
            errors.push(t('5condition'));
        }

        setPasswordErrors(errors);
    };
    const togglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };
    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newPassword = event.target.value;
        setPassword(newPassword);
        validatePassword(newPassword);
        setPasswordChanged(true);
    };
    const isValidEmail = (email: string) => {
        const emailRegex = /^.+@[a-zA-Z]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };
    const validateEmail = (email: string) => {
        const latinLettersRegex = /^[A-Za-z0-9@._%+-]*$/;
        const emailRegex = /^.+@[a-zA-Z]+\.[a-zA-Z]{2,}$/;

        if (email.trim().length === 0) {
            return t('enterEmail');
        } else if (!latinLettersRegex.test(email)) {
            return t('6condition');
        } else if (!emailRegex.test(email)) {
            return t('incompleteEmail');
        }
        return false;
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newEmail = event.target.value;
        setEmail(newEmail);

        if (formSubmitted) {
            const emailError = validateEmail(newEmail);
            setErrors((prev) => ({ ...prev, email: emailError }));
        }
    };


    const handleCountryChange = (selectedOption: { value: string; label: string } | null) => {
        setSelectedCountry(selectedOption);
        if (selectedOption) setCountryError(false);
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setFormSubmitted(true);

        const emailError = validateEmail(email);
        if (emailError) {
            setErrors((prev) => ({ ...prev, email: emailError }));
        }

        const hasPasswordErrors = passwordErrors.length > 0 || passwordStrength < 3;
        if (!password.trim()) {
            setPasswordErrors([t('createPass')]);
        }

        if (!selectedCountry) {
            setCountryError(true);
        } else {
            setCountryError(false);
        }

        if (!isChecked) {
            setShowError(true);
        } else {
            setShowError(false);
        }

        if (emailError || hasPasswordErrors || !selectedCountry) {
            return;
        }
        if (!isFormValid) return;

        const formData = {
            tg_id: null,
            name: null,
            email,
            password,
            country: selectedCountry.value
        };

        try {
            await apiRequest('/pay/user/auth/sign-up', 'POST', formData);

            document.cookie = 'tg_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
            setTimeout(() => {
                window.location.href = '/sign-in';
            }, 2000);
        } catch (error) {
            alert(`Error: ${error.message}`);
        }

        console.log("Отправляемые данные:\nEmail:", email, "\nПароль:", password, "\nСтрана:", selectedCountry.value, "\Подтверждение:");
    };


    const isFormValid =
        email.trim() &&
        password.trim() &&
        isValidEmail(email) &&
        passwordStrength > 2 &&
        password.length >= 8 &&
        /\d/.test(password) &&
        /[A-Z]/.test(password) &&
        passwordErrors.length === 0 &&
        !passwordErrors.includes(t('1condition')) &&
        selectedCountry !== null &&
        isChecked;

    const hasPasswordConditionsError =
        passwordErrors.includes(t('2condition')) ||
        passwordErrors.includes(t('3condition')) ||
        passwordErrors.includes(t('4condition'));

    const showConditionsError = formSubmitted && hasPasswordConditionsError && !passwordErrors.includes(t('1condition'));

    useEffect(() => {
        if (passwordChanged) {
            setPasswordChanged(false);
        }
    }, [password]);

    return (
        <div className={`${styles.auth} ${mediaStyles.auth}`}>
            <div className={`${styles.authContainer} ${mediaStyles.authContainer}`}>
                <header className={`${styles.authHeader} ${mediaStyles.authHeader}`}>
                    <h2 className={`${styles.authTitle} ${mediaStyles.authTitle}`}>{t('createAcc')}</h2>
                </header>

                <main className={`${styles.authMain} ${mediaStyles.authMain}`}>
                    <form className={`${styles.authForm} ${mediaStyles.authForm}`} onSubmit={handleSubmit}>
                        {/* Поле Email */}
                        <div className={styles.inputWrapper}>
                            {errors.email && <span className={styles.tooltip}>{errors.email}</span>}
                            <input
                                className={`${styles.authInput} ${mediaStyles.authInput} ${errors.email ? styles.invalid : ""}`}
                                type="text"
                                placeholder={t('email')}
                                value={email}
                                onChange={handleEmailChange}
                            />
                        </div>

                        {/* Поле Пароль */}
                        <div style={{ position: "relative" }}>
                            {formSubmitted && password === "" && (
                                <span className={styles.tooltip}>{t('createPass')}</span>
                            )}
                            {passwordErrors.length > 0 && passwordErrors[0] === t('1condition') && password !== "" && (
                                <span className={styles.tooltip}>{passwordErrors[0]}</span>
                            )}

                            {(formSubmitted || passwordChanged) && passwordErrors.includes(t('5condition')) && (
                                <span className={styles.tooltip}>{t('5condition')}</span>
                            )}



                            <div className={`${styles.authWrapper} ${mediaStyles.authWrapper}`}>
                                <input
                                    className={`${styles.authInputPass} ${mediaStyles.authInputPass} ${passwordErrors.length > 0 ? styles.invalid : ""}`}
                                    type={showPassword ? "text" : "password"}
                                    value={password}
                                    onChange={handlePasswordChange}
                                    placeholder="Пароль"
                                />
                                <button
                                    type="button"
                                    onClick={togglePasswordVisibility}
                                    className={`${styles.authEye} ${mediaStyles.authEye} ${passwordErrors.length > 0 ? styles.invalid : ""}`}
                                >
                                    {showPassword ? <PiEyeBold size={20} /> : <PiEyeClosedBold size={20} />}
                                </button>
                            </div>

                            <div className={`${styles.authStatus} ${mediaStyles.authStatus}`}>
                                {[...Array(4)].map((_, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            width: "100%",
                                            height: "3px",
                                            backgroundColor: index < passwordStrength ? passwordStrengthColors[passwordStrength] : "#333333",
                                            borderRadius: "3px",
                                        }}
                                    />
                                ))}
                            </div>

                            {passwordErrors.length > 0 && (
                                <div className={`${styles.authErrors} ${mediaStyles.authErrors}`}>
                                    {passwordErrors
                                        .filter((error) => error !== t('1condition'))
                                        .filter(error => error !== t('5condition'))
                                        .filter(error => error !== t('createPass'))
                                        .map((error, index) => (
                                            <p key={index}>{error}</p>
                                        ))}
                                </div>
                            )}

                            {showConditionsError && (
                                <span className={styles.tooltip}>{t('6condition')}</span>
                            )}
                        </div>

                        {/* Выбор страны */}
                        <div className={styles.inputWrapper}>
                            {countryError && <span className={styles.tooltip}>{t('required')}</span>}
                            <Select
                                //@ts-ignore
                                options={options}
                                placeholder='Выберите вашу страну'
                                value={selectedCountry}
                                onChange={handleCountryChange}
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        borderColor: countryError ? 'red' : '#2D2D2D',
                                        backgroundColor: '#121212',
                                        color: '#8b8b8b',
                                        padding: '5px',
                                        borderRadius: '10px',
                                        cursor: 'pointer',
                                        height: '48px',
                                        boxShadow: 'none',
                                        ':hover': {
                                            borderColor: countryError ? 'red' : '#2D2D2D',
                                        },
                                    }),
                                    singleValue: (provided, state) => ({
                                        ...provided,
                                        color: "#8b8b8b", // Меняет цвет текста выбранного пункта
                                    }),
                                    menu: (provided) => ({
                                        ...provided,
                                        backgroundColor: '#121212',
                                        borderRadius: '13px',
                                        border: '1px solid #2D2D2D',
                                    }),
                                    menuList: (provided) => ({
                                        ...provided,
                                        padding: 0,  // Убираем отступы внутри списка
                                        borderRadius: '10px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: "2px"
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isSelected ? "#2D2D2D" : "#121212",
                                        color: "#8b8b8b",  // Цвет текста
                                        padding: '15px 19px',  // Добавляем отступы для элементов
                                        borderRadius: '10px',
                                        minHeight: '48px',
                                        transition: "background-color 0.2s ease",
                                        '&:hover': {
                                            backgroundColor: '#2d2d2d',
                                        },
                                    }),
                                    indicatorSeparator: (provided) => ({
                                        ...provided,
                                        display: 'none', // Скрывает разделитель стрелки
                                    }),
                                    dropdownIndicator: (provided) => ({
                                        ...provided,
                                        display: 'none', // Скрывает саму стрелку
                                    })

                                }}
                                onFocus={(e) => e.target.readOnly = true}

                            />
                        </div>

                        {/* Чекбокс */}
                        <div className={styles.checkboxWrapper}>
                            {showError && !isChecked && <span className={styles.tooltip}>{t('required')}</span>}

                            <label className={styles.checkboxContainer}>
                                <input
                                    type="checkbox"
                                    checked={isChecked}
                                    onChange={() => setIsChecked(!isChecked)}
                                />
                                <span className={styles.customCheckbox}></span>
                                <span className={styles.textCheckbox}>
                                    {t('agree')} <a className={styles.linkCheckbox} href="#">Terms of Service</a>, <a className={styles.linkCheckbox} href="#">AML Policy</a> {t('&')} <a className={styles.linkCheckbox} href="#">Privacy Policy</a>
                                </span>
                            </label>

                        </div>

                        {/* Кнопка регистрации */}
                        <button
                            className={`${styles.authButton} ${mediaStyles.authButton} ${isFormValid ? styles.valid : styles.invalid}`}
                            type="submit"
                        >
                            {t('createAcc')}
                        </button>
                    </form>
                </main>

                <footer className={`${styles.authFooter} ${mediaStyles.authFooter}`}>
                    <p className={`${styles.authText} ${mediaStyles.authText}`}>
                        {t('alreadyAcc')}{" "}
                        <Link className={`${styles.authLink} ${mediaStyles.authLink}`} to="/Authorization/Login">
                            {t('enter')}
                        </Link>
                    </p>
                </footer>
            </div>
        </div>
    );
};

export default Registration;
