import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from "framer-motion";
import { useTranslation } from 'react-i18next';
import Select, { SingleValue } from 'react-select';
import { TwoFactorInput, ExportModal } from '../Pages.ts';
import Calendar from 'react-calendar';
import { format } from 'date-fns';
import { apiRequest, getCurrencies, Currency } from '../../data/Api.ts';
import { useNavigate } from 'react-router-dom';

import { LupaSvg, CrossSvg, ArrowSvg, CalendarSvg, ExportSvg, SettingsSvg, TrashSvg, OstentatiousCrossSvg } from '../../assets/svg-files/SvgIcons.ts';
import LocalizedCalendar from '../../components/common/LocalizedCalendar.tsx';
// @ts-ignore
import styles from './Invoices.module.scss';
// @ts-ignore
import mediaStyles from './Invoices-media.module.scss';

interface NewCurrency {
    address: string;
    label: string;
    currency: string;
    addressesList: string;
    amount: string;
}

// Интерфейс для инвойсов из API
interface Invoice {
    id: number;
    uuid: string;
    amount: number;
    amount_usd: number;
    recieved_amount: number;
    recieved_amount_usd: number;
    api_label: string;
    label: string;
    postback_url: string;
    success_url: string;
    currency: {
        name: string;
        token: string;
        network: string;
        contract: string;
        decimals: number;
        confirmations: number;
        network_fee: number;
        min_deposit: number;
    };
    address: string;
    confirmations: number;
    required_confirmations: number;
    is_paid: boolean;
    is_expired: boolean;
    is_postback_sended: boolean;
    created_at: string;
    expires_at: string;
}

// Добавим интерфейс Project как в компоненте Wallets
interface Project {
    id: number;
    label: string;
    api_key: string;
    api_id: number;
    percent: number;
    is_user_payer: boolean;
    company_name: string;
    banner: string;
    background: string;
    created_at: string;
}

// Добавим тип ProjectOption для использования в Select
type ProjectOption = { value: number; label: string; projectId: number }; // value для Select, projectId - это id проекта
type CurrencyOption = { value: string; label: string };

const filterOptions = {
    Currency: [] as string[], // Будет заполнен из API
    Project: ['Project A', 'Project B', 'Project C'],
    Type: ['Paid', 'Unpaid', 'Pending', 'Expired', 'Sent', 'Not sent'],
    Other: ['Option 1', 'Option 2', 'Option 3'],
};

const Invoices: React.FC = () => {
    const { i18n } = useTranslation();
    const navigate = useNavigate();
    const getYesterday = (): Date => {
        const today = new Date();
        today.setDate(today.getDate() - 1);
        return today;
    };
    const [selectedCurrency, setSelectedCurrency] = useState<CurrencyOption | null>(null);

    const handleChange = (newValue: SingleValue<CurrencyOption>) => {
        setSelectedCurrency(newValue);
    };
    const yesterday = getYesterday();
    const today = new Date();
    const [showOptionalFields, setShowOptionalFields] = useState(false);
    const [invoices, setInvoices] = useState<Invoice[]>([]);
    const [isLoadingInvoices, setIsLoadingInvoices] = useState(false);
    const [startDate, setStartDate] = useState<Date | null>(yesterday);  // Устанавливаем начальное значение
    const [endDate, setEndDate] = useState<Date | null>(today);  // Устанавливаем начальное значение
    const [isAllTime, setIsAllTime] = useState(false);
    const [isCalendarVisible, setIsCalendarVisible] = useState(false);
    const [selectedDates, setSelectedDates] = useState<Date[]>([yesterday, today]);
    const [isFilterDropdownVisible, setIsFilterDropdownVisible] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedOptions, setSelectedOptions] = useState<{ [key: string]: Set<string> }>({});
    const [activeCategory, setActiveCategory] = useState<string | null>(null);
    const createWalletDialog = useRef<HTMLDialogElement | null>(null);
    const calendarButtonRef = useRef<HTMLButtonElement | null>(null);
    const calendarContainerRef = useRef<HTMLDivElement | null>(null);
    const downloadsDialog = useRef<HTMLDialogElement>(null);
    const hasActiveFilters = Object.values(selectedOptions).some((options) => options.size > 0);
    const filterButtonRef = useRef<HTMLButtonElement | null>(null);
    const filterDropdownRef = useRef<HTMLDivElement | null>(null);

    // Новые состояния для работы с валютами
    const [currencyOptions, setCurrencyOptions] = useState<CurrencyOption[]>([]);
    const [isLoadingCurrencies, setIsLoadingCurrencies] = useState(false);

    // Добавляем новые состояния для работы с проектами
    const [projects, setProjects] = useState<Project[]>([]);
    const [projectOptions, setProjectOptions] = useState<ProjectOption[]>([]);
    const [selectedProject, setSelectedProject] = useState<ProjectOption | null>(null);
    const [isLoadingProjects, setIsLoadingProjects] = useState(false);

    // Обновляем состояние формы инвойса, добавляя api_id
    const [invoiceFormData, setInvoiceFormData] = useState({
        api_id: 0, // Добавляем поле api_id для хранения id проекта
        currency: '',
        amount: '',
        expires_time: '15',
        label: '',
        postback_url: '',
        success_url: ''
    });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const handleClearFilters = () => {
        setSelectedOptions({});
    };
    const handleExportToEmail = () => {
        // Логика экспорта на почту
        console.log('Экспорт на почту с выбранными фильтрами:', selectedOptions);
    };
    const handleExportToExcel = () => {
        // Логика скачивания данных в формате Excel
        console.log('Скачивание Excel с выбранными фильтрами:', selectedOptions);
    };

    // Добавляем функцию для обработки изменения выбранного проекта
    const handleProjectChange = (newValue: SingleValue<ProjectOption>) => {
        setSelectedProject(newValue);
        if (newValue) {
            setInvoiceFormData(prev => ({
                ...prev,
                api_id: newValue.projectId // Используем id проекта как api_id
            }));
        }
    };

    const openCreateWalletDialog = () => {
        // Сбрасываем форму при открытии диалога
        setInvoiceFormData({
            api_id: 0,
            currency: '',
            amount: '',
            expires_time: '15',
            label: '',
            postback_url: '',
            success_url: ''
        });
        setSelectedProject(null);
        setSelectedCurrency(null);
        setError(null);
        setShowOptionalFields(false);

        createWalletDialog.current?.showModal();
    };

    const settingsDialog = useRef<HTMLDialogElement>(null);
    const handleBackdropClick = (e: React.MouseEvent) => {
        if (e.target === e.currentTarget) {
            closeDialog();
        }

    };
    const closeDialog = () => {
        if (settingsDialog.current) {
            settingsDialog.current?.close();
        }
        if (createWalletDialog.current) {
            createWalletDialog.current?.close();
        }
        if (settingsDialog.current) {
            settingsDialog.current.close();
        }
    };
    interface VisibleColumns {
        id: boolean;
        uuid: boolean;
        api_label: boolean;
        label: boolean;
        amount: boolean;
        amount_usd: boolean;
        is_paid: boolean;
        is_postback_sended: boolean;
        created_at: boolean;
    }
    const [visibleColumnsInvoices, setVisibleColumns] = useState<VisibleColumns>({
        id: true,
        uuid: true,
        api_label: true,
        label: true,
        amount: true,
        amount_usd: true,
        is_paid: true,
        is_postback_sended: true,
        created_at: true,
    });
    const [tempVisibleColumns, setTempVisibleColumns] = useState<VisibleColumns>(visibleColumnsInvoices);

    // Загружаем настройки видимости из localStorage при первом рендере
    useEffect(() => {
        const savedSettings = localStorage.getItem("visibleColumnsInvoices");
        if (savedSettings) {
            const savedColumns = JSON.parse(savedSettings);
            setVisibleColumns(savedColumns);
            setTempVisibleColumns(savedColumns); // Инициализируем tempVisibleColumns значениями из localStorage
        }
    }, []);

    // Функция для изменения видимости столбца
    const toggleColumn = (column: string) => {
        if (column === "id" || column === "created_at") {
            return; // Заблокированные столбцы нельзя изменить
        }

        setTempVisibleColumns((prev: VisibleColumns) => ({
            ...prev,
            [column]: !prev[column],
        }));
    };

    // Функция для изменения всех столбцов (выбрать все / отменить все)
    const toggleAllColumns = () => {
        const areAllSelected = Object.keys(tempVisibleColumns)
            .filter((key) => key !== "id" && key !== "created_at") // Исключаем id и created_at
            .every((key) => tempVisibleColumns[key]); // Проверяем, все ли остальные выбраны

        setTempVisibleColumns((prev: VisibleColumns) => {
            const newVisibleColumns: VisibleColumns = {
                id: true,
                uuid: !areAllSelected,
                api_label: !areAllSelected,
                label: !areAllSelected,
                amount: !areAllSelected,
                amount_usd: !areAllSelected,
                is_paid: !areAllSelected,
                is_postback_sended: !areAllSelected,
                created_at: true,
            };
            return newVisibleColumns;
        });
    };

    // Сохраняем изменения в localStorage
    const saveSettings = () => {
        // Обновляем видимость столбцов
        setVisibleColumns(tempVisibleColumns);

        // Сохраняем изменения в localStorage
        localStorage.setItem("visibleColumnsInvoices", JSON.stringify(tempVisibleColumns));
        if (settingsDialog.current) {
            settingsDialog.current.close();
        }
    };



    const handleFilterToggle = () => {
        setIsFilterDropdownVisible(!isFilterDropdownVisible);
        setSearchQuery('');
        setActiveCategory(null);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    const handleCategoryClick = (category: string) => {
        setActiveCategory(category);
        setSearchQuery('');
    };

    const handleClickOutside = (event: MouseEvent | React.MouseEvent) => {
        if (
            filterDropdownRef.current &&
            !filterDropdownRef.current.contains(event.target as Node) &&
            filterButtonRef.current &&
            !filterButtonRef.current.contains(event.target as Node)
        ) {
            setIsFilterDropdownVisible(false);
        }
        if (calendarContainerRef.current &&
            !calendarContainerRef.current.contains(event.target as Node) &&
            calendarButtonRef.current &&
            !calendarButtonRef.current.contains(event.target as Node)
        ) {
            setIsCalendarVisible(false);
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const handleCheckboxChange = (category: string, option: string) => {
        setSelectedOptions((prev) => {
            const updatedSet = new Set(prev[category] || []);
            if (updatedSet.has(option)) {
                updatedSet.delete(option);
            } else {
                updatedSet.add(option);
            }
            return { ...prev, [category]: updatedSet };
        });
    };
    const handleRemoveFilter = (category: string, option: string) => {
        setSelectedOptions((prev) => {
            const updatedSet = new Set(prev[category] || []);
            updatedSet.delete(option);
            return { ...prev, [category]: updatedSet };
        });
    };

    const filteredResults = Object.entries(filterOptions).flatMap(([category, options]) =>
        options.filter((option) => option.toLowerCase().includes(searchQuery.toLowerCase()))
            .map((option) => ({ category, option }))
    );

    useEffect(() => {
        setInvoices([]);
    }, []);

    // Функция для загрузки инвойсов
    const fetchInvoices = async () => {
        setIsLoadingInvoices(true);
        try {
            const response = await apiRequest<Invoice[]>('/pay/user/invoices/', 'GET');
            if (response && Array.isArray(response)) {
                setInvoices(response);
            } else {
                console.error('Unexpected response format for invoices:', response);
                setInvoices([]);
            }
        } catch (error) {
            console.error('Failed to fetch invoices:', error);
            setInvoices([]);
        } finally {
            setIsLoadingInvoices(false);
        }
    };

    // Добавляем эффект для загрузки инвойсов при первом рендере
    useEffect(() => {
        fetchInvoices();
    }, []);

    const handleCalendarToggle = () => {
        setIsCalendarVisible(!isCalendarVisible);
    };

    const handleDateChange = (dates: Date[]) => {
        if (dates.length === 1) {
            setSelectedDates([dates[0]]);
            setStartDate(dates[0]);
            setEndDate(null);
            setIsAllTime(false); // Сбрасываем флаг "всё время"
        } else if (dates.length === 2) {
            setSelectedDates(dates);
            setStartDate(dates[0]);
            setEndDate(dates[1]);
            setIsAllTime(false); // Сбрасываем флаг "всё время"
        }
    };


    const handleApplyFilter = () => {
        if (selectedDates.length === 1) {
            setStartDate(selectedDates[0]);
            setEndDate(null);
        } else if (selectedDates.length === 2) {
            const [start, end] = selectedDates;
            setStartDate(start);
            setEndDate(end);
        }

        // Убираем флаг "показать всё время", если выбран конкретный диапазон
        setIsAllTime(false);

        setIsCalendarVisible(false);
    };


    const filteredInvoices = invoices.filter((invoice) => {
        const createdAt = new Date(invoice.created_at);

        // Фильтрация по диапазону дат
        let dateMatch = false;
        if (startDate && endDate) {
            dateMatch = createdAt >= startDate && createdAt <= endDate;
        } else if (startDate) {
            const endOfDay = new Date(startDate);
            endOfDay.setHours(23, 59, 59, 999);
            dateMatch = createdAt >= startDate && createdAt <= endOfDay;
        } else {
            dateMatch = true;
        }

        // Фильтрация по текстовому поиску в uuid, label, api_label, address
        const textMatch = [invoice.uuid, invoice.label, invoice.api_label, invoice.address].some((field) =>
            field?.toLowerCase().includes(searchQuery.toLowerCase())
        );

        // Фильтрация по выбранным опциям
        const matchesFilters = Object.entries(selectedOptions).every(([category, selectedValues]) => {
            if (selectedValues.size === 0) return true; // Если в категории нет выбранных значений, пропускаем фильтрацию

            switch (category) {
                case 'Currency':
                    return selectedValues.has(invoice.currency.name);
                case 'Project':
                    return selectedValues.has(invoice.api_label);
                case 'Type':
                    if (selectedValues.has('Paid') && invoice.is_paid) return true;
                    if (selectedValues.has('Unpaid') && !invoice.is_paid) return true;
                    if (selectedValues.has('Pending') && invoice.is_paid === false && !invoice.is_expired) return true;
                    if (selectedValues.has('Expired') && invoice.is_expired) return true;
                    if (selectedValues.has('Sent') && invoice.is_postback_sended) return true;
                    if (selectedValues.has('Not sent') && !invoice.is_postback_sended) return true;
                    return false;
                case 'Other':
                    return selectedValues.has('Option 1'); // Тут добавьте правильное соответствие
                default:
                    return true;
            }
        });

        return dateMatch && matchesFilters && textMatch;
    });

    const [sortConfig, setSortConfig] = useState<{ key: string | null; direction: 'asc' | 'desc' }>({
        key: 'created_at',
        direction: 'desc',
    });

    const handleSort = (key: string) => {
        setSortConfig((prev) => ({
            key,
            direction: prev.key === key && prev.direction === 'desc' ? 'asc' : 'desc',
        }));
    };

    const sortedInvoices = [...filteredInvoices].sort((a, b) => {
        if (!sortConfig.key) return 0;
        const { key, direction } = sortConfig;

        // Специальная обработка для поля currency, которое теперь объект
        if (key === 'currency') {
            const aValue = a.currency.name;
            const bValue = b.currency.name;
            if (aValue < bValue) return direction === 'asc' ? -1 : 1;
            if (aValue > bValue) return direction === 'asc' ? 1 : -1;
            return 0;
        }

        let aValue = a[key];
        let bValue = b[key];

        if (!isNaN(Number(aValue)) && !isNaN(Number(bValue))) {
            aValue = Number(aValue);
            bValue = Number(bValue);
        }

        if (typeof aValue === 'boolean' && typeof bValue === 'boolean') {
            return direction === 'asc' ? Number(aValue) - Number(bValue) : Number(bValue) - Number(aValue);
        }
        if (aValue < bValue) return direction === 'asc' ? -1 : 1;
        if (aValue > bValue) return direction === 'asc' ? 1 : -1;
        return 0;
    });

    // Обработчик изменения полей формы
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setInvoiceFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    // Добавляем эффект для загрузки проектов
    useEffect(() => {
        const fetchProjects = async () => {
            setIsLoadingProjects(true);
            try {
                const response = await apiRequest<Project[]>('/pay/user/projects/', 'GET');
                console.log('Данные проектов:', response);
                if (response && Array.isArray(response)) {
                    setProjects(response);

                    // Преобразуем данные для Select, сохраняя id проекта в отдельном поле
                    const options = response.map(project => ({
                        value: project.api_id, // Значение для компонента Select
                        label: project.label, // Текст для отображения
                        projectId: project.id // Сохраняем id проекта для использования в api_id
                    }));
                    setProjectOptions(options);
                }
            } catch (error) {
                console.error('Failed to fetch projects:', error);
            } finally {
                setIsLoadingProjects(false);
            }
        };

        fetchProjects();
    }, []);

    // Добавляем эффект для получения списка валют
    useEffect(() => {
        const fetchCurrencies = async () => {
            try {
                setIsLoadingCurrencies(true);
                const currencies = await getCurrencies();

                // Преобразуем данные для Select
                const options = currencies.map(currency => ({
                    value: currency.name, // Используем оригинальное имя валюты из API
                    label: currency.name
                }));

                setCurrencyOptions(options);
            } catch (error) {
                console.error('Ошибка при получении данных о валютах:', error);
            } finally {
                setIsLoadingCurrencies(false);
            }
        };

        fetchCurrencies();
    }, []);

    // Добавляем эффект для обновления фильтров валют
    useEffect(() => {
        const fetchCurrenciesForFilters = async () => {
            try {
                const currencies = await getCurrencies();
                // Извлекаем только имена валют для фильтрации
                const currencyNames = currencies.map(currency => currency.name);
                // Обновляем filterOptions с новыми валютами
                filterOptions.Currency = currencyNames;
            } catch (error) {
                console.error('Ошибка при получении данных о валютах для фильтров:', error);
            }
        };

        fetchCurrenciesForFilters();
    }, []);

    // Обработчик изменения валюты
    const handleCurrencyChange = (newValue: SingleValue<CurrencyOption>) => {
        setSelectedCurrency(newValue);
        if (newValue) {
            setInvoiceFormData(prev => ({
                ...prev,
                currency: newValue.value
            }));
        }
    };

    // Функция для создания инвойса
    const createInvoice = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);
        setError(null);

        try {
            // Проверяем наличие обязательных полей
            if (!invoiceFormData.api_id) {
                throw new Error('Выберите API ключ');
            }
            if (!invoiceFormData.currency) {
                throw new Error('Выберите валюту');
            }
            if (!invoiceFormData.amount || parseFloat(invoiceFormData.amount) <= 0) {
                throw new Error('Введите корректную сумму');
            }
            if (!invoiceFormData.label.trim()) {
                throw new Error('Введите название инвойса');
            }

            // Преобразуем числовые значения
            const invoiceData = {
                api_id: invoiceFormData.api_id, // Используем id проекта как api_id
                currency: invoiceFormData.currency,
                amount: parseFloat(invoiceFormData.amount),
                expires_time: parseInt(invoiceFormData.expires_time, 10),
                label: invoiceFormData.label,
                postback_url: invoiceFormData.postback_url || null,
                success_url: invoiceFormData.success_url || null
            };

            console.log('Sending invoice with api_id (project.id):', invoiceData.api_id);

            // Используем правильный путь для API
            const response = await apiRequest('/pay/user/invoices/create', 'POST', invoiceData);
            console.log('Invoice created:', response);

            createWalletDialog.current?.close();

            // Очистить форму
            setInvoiceFormData({
                api_id: 0,
                currency: '',
                amount: '',
                expires_time: '15',
                label: '',
                postback_url: '',
                success_url: ''
            });
            setSelectedProject(null);
            setSelectedCurrency(null);

            // После успешного создания обновляем список инвойсов
            await fetchInvoices();
        } catch (err) {
            setError(err instanceof Error ? err.message : 'Произошла ошибка при создании инвойса');
            console.error('Error creating invoice:', err);
        } finally {
            setIsLoading(false);
        }
    };

    const handleUuidClick = (uuid: string) => {
        navigate(`/invoice-card/${uuid}`);
    };

    return (
        <div className={`${styles.invoices} ${mediaStyles.invoices}`}>

            <div className={`${styles.invoicesCreatе} ${mediaStyles.invoicesCreatе}`}>
                <div className={`${styles.invoicesCreatеText}`}>Вы можете моментально вручную создать счет на оплату в любой из предложенных сервисом криптовалют на необходимую сумму.</div>
                <button className={`${styles.invoicesCreatеButton} ${mediaStyles.invoicesCreatеButton}`} onClick={openCreateWalletDialog}>Создать инвойс</button>
                <dialog ref={createWalletDialog} onClick={handleBackdropClick} className={`${styles.dialog} ${mediaStyles.dialog}`}>
                    <div className={`${styles.dialogBlock} ${mediaStyles.dialogBlock}`}>
                        <div className={`${styles.dialogContainer} ${mediaStyles.dialogContainer} ${styles.dialogContainerInvoices}`}>
                            <div className={`${styles.dialogHeader} ${mediaStyles.dialogHeader}`}>
                                <h2 className={`${styles.dialogTitle} ${mediaStyles.dialogTitle}`}>Create Invoice</h2>
                                <button onClick={() => createWalletDialog.current?.close()} className={`${styles.invoicesCreatеCross}`}>X</button>
                            </div>
                            <form className={`${styles.dialogContent}`} onSubmit={createInvoice}>
                                <label className={`${styles.dialogLabel}`}>
                                    <span className={`${styles.dialogSpan}`}>API Key <span style={{ color: 'red' }}>*</span></span>
                                    {isLoadingProjects ? (
                                        <div className={`${styles.loading}`}>Loading projects...</div>
                                    ) : (
                                        <Select
                                            placeholder='Select API'
                                            value={selectedProject}
                                            onChange={handleProjectChange}
                                            options={projectOptions}
                                            isSearchable={false}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    borderColor: 'light-dark(var(--border-color-light), var(--border-color-dark))',
                                                    backgroundColor: 'light-dark(var(--second-color-light), var(--second-color-dark))',
                                                    color: 'light-dark(var(--text-color-light), var(--text-color-dark));',
                                                    padding: '5px',
                                                    borderRadius: '10px',
                                                    cursor: 'pointer',
                                                    height: '48px',
                                                    boxShadow: 'none',
                                                    ':hover': {
                                                        borderColor: 'light-dark(var(--border-color-light), var(--border-color-dark))',
                                                    },
                                                }),
                                                singleValue: (provided) => ({
                                                    ...provided,
                                                    color: "light-dark(var(--text-color-light), var(--text-color-dark));",
                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    backgroundColor: 'light-dark(var(--second-color-light), var(--second-color-dark))',
                                                    borderRadius: '13px',
                                                    border: '1px solid light-dark(var(--border-color-light), var(--border-color-dark))',
                                                }),
                                                menuList: (provided) => ({
                                                    ...provided,
                                                    padding: 0,
                                                    borderRadius: '10px',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: "2px"
                                                }),
                                                option: (provided) => ({
                                                    ...provided,
                                                    backgroundColor: "light-dark(var(--second-color-light), var(--second-color-dark))",
                                                    color: 'light-dark(var(--text-color-light), var(--text-color-dark))',
                                                    padding: '15px 19px',
                                                    borderRadius: '10px',
                                                    minHeight: '48px',
                                                    transition: "background-color 0.2s ease",
                                                    cursor: 'pointer',
                                                    '&:hover': {
                                                        backgroundColor: 'light-dark(var(--second-color-light), var(--second-color-dark))',
                                                    },
                                                }),
                                                indicatorSeparator: (provided) => ({
                                                    ...provided,
                                                    display: 'none',
                                                }),
                                                dropdownIndicator: (provided) => ({
                                                    ...provided,
                                                    display: 'none',
                                                })
                                            }}
                                        />
                                    )}
                                </label>
                                <label className={`${styles.dialogLabel}`} htmlFor="currency">
                                    <span className={`${styles.dialogSpan}`}>Currency <span style={{ color: 'red' }}>*</span></span>
                                    {isLoadingCurrencies ? (
                                        <div className={`${styles.loading}`}>Loading currencies...</div>
                                    ) : (
                                        <Select
                                            options={currencyOptions}
                                            onChange={handleCurrencyChange}
                                            value={currencyOptions.find(option => option.value === invoiceFormData.currency) || null}
                                            placeholder='Currency'
                                            isSearchable={false}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    borderColor: 'light-dark(var(--border-color-light), var(--border-color-dark))',
                                                    backgroundColor: 'light-dark(var(--second-color-light), var(--second-color-dark))',
                                                    color: 'light-dark(var(--text-color-light), var(--text-color-dark));',
                                                    padding: '5px',
                                                    borderRadius: '10px',
                                                    cursor: 'pointer',
                                                    height: '48px',
                                                    boxShadow: 'none',
                                                    ':hover': {
                                                        borderColor: 'light-dark(var(--border-color-light), var(--border-color-dark))',
                                                    },
                                                }),
                                                singleValue: (provided) => ({
                                                    ...provided,
                                                    color: "light-dark(var(--text-color-light), var(--text-color-dark));",
                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    backgroundColor: 'light-dark(var(--second-color-light), var(--second-color-dark))',
                                                    borderRadius: '13px',
                                                    border: '1px solid light-dark(var(--border-color-light), var(--border-color-dark))',
                                                }),
                                                menuList: (provided) => ({
                                                    ...provided,
                                                    padding: 0,
                                                    borderRadius: '10px',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: "2px"
                                                }),
                                                option: (provided) => ({
                                                    ...provided,
                                                    backgroundColor: "light-dark(var(--second-color-light), var(--second-color-dark))",
                                                    color: 'light-dark(var(--text-color-light), var(--text-color-dark))',
                                                    padding: '15px 19px',
                                                    borderRadius: '10px',
                                                    minHeight: '48px',
                                                    transition: "background-color 0.2s ease",
                                                    cursor: 'pointer',
                                                    '&:hover': {
                                                        backgroundColor: 'light-dark(var(--second-color-light), var(--second-color-dark))',
                                                    },
                                                }),
                                                indicatorSeparator: (provided) => ({
                                                    ...provided,
                                                    display: 'none',
                                                }),
                                                dropdownIndicator: (provided) => ({
                                                    ...provided,
                                                    display: 'none',
                                                })
                                            }}
                                        />
                                    )}
                                </label>
                                <label className={`${styles.dialogLabel}`} htmlFor="amount">
                                    <span className={`${styles.dialogSpan}`}>Amount <span style={{ color: 'red' }}>*</span></span>
                                    <input
                                        className={`${styles.dialogInput}`}
                                        type="number"
                                        placeholder="100"
                                        name="amount"
                                        value={invoiceFormData.amount}
                                        onChange={handleInputChange}
                                        required
                                        min="0.01"
                                        step="0.01"
                                    />
                                </label>
                                <label className={`${styles.dialogLabel}`} htmlFor="expires_time">
                                    <span className={`${styles.dialogSpan}`}>Expires time (minutes)</span>
                                    <input
                                        className={`${styles.dialogInput}`}
                                        type="number"
                                        placeholder="15"
                                        name="expires_time"
                                        value={invoiceFormData.expires_time}
                                        onChange={handleInputChange}
                                        required
                                        min="5"
                                        max="60"
                                    />
                                </label>
                                <label className={`${styles.dialogLabel}`} htmlFor="label">
                                    <span className={`${styles.dialogSpan}`}>Label <span style={{ color: 'red' }}>*</span></span>
                                    <input
                                        className={`${styles.dialogInput}`}
                                        type="text"
                                        placeholder="USERID:PERIOD"
                                        name="label"
                                        value={invoiceFormData.label}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </label>
                                <div className={`${styles.dialogDrop}`}>
                                    <button onClick={() => setShowOptionalFields(!showOptionalFields)} className={`${styles.dialogDropButton}`}>
                                        Optional <ArrowSvg className={`${styles.arrow} ${showOptionalFields ? styles.arrowRotated : ""}`} />
                                    </button>
                                    <AnimatePresence>
                                        {showOptionalFields && (
                                            <motion.div
                                                initial={{ height: 0, opacity: 0 }}
                                                animate={{ height: showOptionalFields ? "auto" : 0, opacity: showOptionalFields ? 1 : 0 }}
                                                exit={{ height: 0, opacity: 0 }}
                                                transition={{ duration: 0.3, ease: "easeInOut" }}
                                                style={{ overflow: "hidden" }}
                                            >
                                                <div className={`${styles.dialogDropContent} ${showOptionalFields ? styles.show : ""}`}>
                                                    <label className={`${styles.dialogLabel} ${styles.dialogMargin}`} htmlFor="postback_url">
                                                        <span className={`${styles.dialogSpan}`}>IPN URL (optional)</span>
                                                        <input
                                                            className={`${styles.dialogInput}`}
                                                            type="url"
                                                            placeholder="https://example.com/ipn"
                                                            name="postback_url"
                                                            value={invoiceFormData.postback_url}
                                                            onChange={handleInputChange}
                                                        />
                                                    </label>
                                                    <label className={`${styles.dialogLabel}`} htmlFor="success_url">
                                                        <span className={`${styles.dialogSpan}`}>Success URL (optional)</span>
                                                        <input
                                                            className={`${styles.dialogInput}`}
                                                            type="url"
                                                            placeholder="https://example.com/success"
                                                            name="success_url"
                                                            value={invoiceFormData.success_url}
                                                            onChange={handleInputChange}
                                                        />
                                                    </label>
                                                </div>
                                            </motion.div>
                                        )}
                                    </AnimatePresence>
                                </div>

                                {/* Добавляем отображение ошибки перед кнопкой создания */}
                                {error && <div className={`${styles.dialogError}`} style={{ color: 'red', marginBottom: '10px' }}>{error}</div>}

                                <button
                                    type="submit"
                                    className={`${styles.invoicesCreatеDialogButton}`}
                                    disabled={isLoading}
                                >
                                    {isLoading ? 'Создание...' : 'Создать'}
                                </button>
                            </form>
                        </div>
                    </div>
                </dialog>
            </div>


            <div className={`${styles.invoicesContainer} ${mediaStyles.invoicesContainer}`}>
                <div className={`${styles.invoicesWrapper} ${mediaStyles.invoicesWrapper}`}>
                    <div className={`${styles.invoicesTitle} ${mediaStyles.invoicesTitle}`}>History of operations</div>
                    <div className={`${styles.invoicesItem}`}>
                        <button className={`${styles.invoicesButton}`} onClick={() => downloadsDialog.current?.showModal()}>Экспорт <ExportSvg /></button>
                        <ExportModal
                            closeDialog={closeDialog}
                            downloadsDialog={downloadsDialog}
                            filterOptions={filterOptions}
                            selectedOptions={selectedOptions}
                            i18n={i18n}
                            handleCheckboxChange={handleCheckboxChange}
                            handleCategoryClick={handleCategoryClick}
                            handleSearchChange={handleSearchChange}
                            searchQuery={searchQuery}
                            filteredResults={filteredResults}
                            isAllTime={isAllTime}
                            startDate={startDate}
                            endDate={endDate}
                            handleDateChange={handleDateChange}
                            selectedDates={selectedDates}
                            isCalendarVisible={isCalendarVisible}
                            handleCalendarToggle={handleCalendarToggle}
                            calendarButtonRef={calendarButtonRef}
                            handleExportToEmail={handleExportToEmail}
                            handleExportToExcel={handleExportToExcel}
                        />
                        <button className={`${styles.invoicesButton}`} onClick={() => settingsDialog.current?.showModal()}><SettingsSvg /></button>
                        <dialog ref={settingsDialog} onClick={handleBackdropClick} className={`${styles.dialog} ${mediaStyles.dialog}`}>
                            <div className={`${styles.dialogBlock} ${mediaStyles.dialogBlock}`}>
                                <div className={`${styles.dialogContainer} ${mediaStyles.dialogContainer}`}>

                                    <div className={`${styles.invoicesDialogHeader} ${mediaStyles.invoicesDialogHeader}`}>
                                        <h3 className={`${styles.invoicesDialogTitle} ${mediaStyles.invoicesDialogTitle}`}>Settings</h3>
                                        <button onClick={closeDialog} className={`${styles.invoicesDialogCross}`}>X</button>
                                    </div>

                                    <div className={`${styles.invoicesDialogWrapper}`}>
                                        Parameters
                                        <label className={`${styles.invoicesDialogSub}`}>
                                            Status
                                            <input
                                                type="checkbox"
                                                checked={Object.keys(tempVisibleColumns)
                                                    .filter((key) => key !== "id" && key !== "created_at")
                                                    .every((key) => tempVisibleColumns[key])}
                                                onChange={toggleAllColumns}
                                                className={`${styles.invoicesDialogCheckbox}`}
                                            />
                                        </label>
                                    </div>

                                    <ul className={`${styles.invoicesDialogUl}`}>
                                        {Object.keys(tempVisibleColumns).map((key) => (
                                            <li key={key}>
                                                <label className={`${styles.invoicesDialogLabel}`}>
                                                    {key}
                                                    <input
                                                        type="checkbox"
                                                        checked={tempVisibleColumns[key]}
                                                        onChange={() => toggleColumn(key)}
                                                        disabled={key === "id" || key === "created_at"}
                                                        className={`${styles.invoicesDialogCheckbox}`}
                                                    />

                                                </label>
                                            </li>
                                        ))}
                                    </ul>
                                    <div className={`${styles.invoicesDialogButtons} ${mediaStyles.invoicesDialogButtons}`}>
                                        <button className={`${styles.invoicesDialogClose} ${mediaStyles.invoicesDialogClose}`} onClick={closeDialog}>Close</button>
                                        <button className={`${styles.invoicesDialogSave} ${mediaStyles.invoicesDialogSave}`} onClick={saveSettings}>Save</button>
                                    </div>
                                </div>
                            </div>
                        </dialog>
                    </div>
                </div>
                <div className={`${styles.invoicesSearchWrapper} ${mediaStyles.invoicesSearchWrapper}`}>
                    <div className={`${styles.invoicesSearch} ${mediaStyles.invoicesSearch}`}>
                        <div className={`${styles.invoicesSearchInput} ${mediaStyles.invoicesSearchInput}`}>
                            <LupaSvg />
                            <input
                                className={`${styles.invoicesInput}`}
                                type="text"
                                placeholder='Search hash, api, wallet etc'
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                        </div>
                        <button ref={calendarButtonRef} className={`${styles.invoicesСalendar} ${mediaStyles.invoicesСalendar}`} onClick={handleCalendarToggle}>
                            <CalendarSvg className={`${styles.invoicesСalendarSvg}`} />
                            <>{isAllTime
                                ? ''
                                : (startDate && endDate
                                    ? (format(startDate, 'dd.MM.yy') === format(endDate, 'dd.MM.yy')
                                        ? format(startDate, 'dd.MM.yy')
                                        : `${format(startDate, 'dd.MM.yy')} - ${format(endDate, 'dd.MM.yy')}`)
                                    : `${format(yesterday, 'dd.MM.yy')} - ${format(today, 'dd.MM.yy')}`
                                )
                            }</>
                        </button>
                        {isCalendarVisible && (
                            <div ref={calendarContainerRef} className={`${styles.calendarContainer} ${mediaStyles.calendarContainer}`}>
                                <LocalizedCalendar
                                    selectRange={true}
                                    onChange={handleDateChange}
                                    value={selectedDates}
                                    tileGroupCount={3}
                                    tileDisabled={({ date }) => date > new Date()}
                                    minDetail="year"
                                />
                                <div className={styles.calendarContainerButtons}>
                                    <button
                                        onClick={() => {
                                            setIsAllTime(true);
                                            setStartDate(null);
                                            setEndDate(null);
                                            setSelectedDates([]);
                                            setIsCalendarVisible(false);
                                        }}
                                        className={styles.calendarContainerallTimeButton}
                                    >
                                        {i18n.t('calendar.buttons.allTime')}
                                    </button>
                                    <button
                                        onClick={() => {
                                            setIsCalendarVisible(false);
                                        }}
                                        className={styles.calendarContainercloseButton}
                                    >
                                        {i18n.t('calendar.buttons.close')}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className={`${styles.invoicesPreferences}`}>
                    {hasActiveFilters && (
                        <button className={`${styles.invoicesClearButton}`} onClick={handleClearFilters}>
                            <TrashSvg />
                        </button>
                    )}
                    {Object.entries(selectedOptions).map(([filter, options]) =>
                        Array.from(options).map((option) => (
                            <div key={`${filter}-${option}`} className={styles.invoicesFilterTag}>
                                {option}
                                <button className={styles.invoicesCrossButton} onClick={() => handleRemoveFilter(filter, option)}>
                                    <OstentatiousCrossSvg className={styles.invoicesCross} />
                                </button>
                            </div>
                        ))
                    )}

                    <button className={`${styles.invoicesPreferencesButton}`} ref={filterButtonRef} onClick={handleFilterToggle}>Add filter +</button>
                    {isFilterDropdownVisible && (
                        <div ref={filterDropdownRef} className={styles.invoicesFilterDropdown}>
                            <div className={styles.invoicesFilterSearch}>
                                <LupaSvg className={styles.invoicesLupa} />
                                <input
                                    className={styles.invoicesFilterInput}
                                    type="text"
                                    placeholder="Search filters..."
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                />
                            </div>

                            {searchQuery ? (
                                <ul className={styles.invoicesFilterUl}>
                                    {filteredResults.map(({ category, option }) => (
                                        <li key={option} className={styles.invoicesFilterItem}>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    checked={selectedOptions[category]?.has(option) || false}
                                                    onChange={() => handleCheckboxChange(category, option)}
                                                />
                                                {option} <span className={styles.filterCategory}>({category})</span>
                                            </label>
                                        </li>
                                    ))}
                                </ul>
                            ) : activeCategory ? (
                                <>
                                    <ul className={styles.invoicesFilterUl}>
                                        {filterOptions[activeCategory].map((option) => (
                                            <li key={option}>
                                                <label className={styles.invoicesFilterItem}>
                                                    {option}
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedOptions[activeCategory]?.has(option) || false}
                                                        onChange={() => handleCheckboxChange(activeCategory, option)}
                                                        className={`${styles.invoicesDialogFilterCheckbox}`}
                                                    />
                                                </label>
                                            </li>
                                        ))}
                                    </ul>
                                    <button className={styles.invoicesBackButton} onClick={() => setActiveCategory(null)}>
                                        <ArrowSvg className={styles.invoicesBackArrow} />Back
                                    </button>
                                </>
                            ) : (
                                <ul className={styles.invoicesFilterUl}>
                                    {Object.keys(filterOptions).map((category) => (
                                        <li key={category} className={styles.invoicesFilterItem} onClick={() => handleCategoryClick(category)}>
                                            {category}
                                            <ArrowSvg className={styles.invoicesFilterArrow} />
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    )}
                </div>

                <div className={`${styles.invoicesBlock} ${mediaStyles.invoicesBlock}`}>
                    <div className={`${styles.invoicesLine} ${mediaStyles.invoicesLine}`}>
                        {visibleColumnsInvoices.id && <div className={`${styles.invoicesText} ${styles.listId}`} onClick={() => handleSort('id')}>ID {sortConfig.key === 'id' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</div>}
                        {visibleColumnsInvoices.uuid && <div className={`${styles.invoicesText} ${styles.listHash}`} onClick={() => handleSort('uuid')}>UUID {sortConfig.key === 'uuid' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</div>}
                        {visibleColumnsInvoices.api_label && <div className={`${styles.invoicesText} ${styles.listApi}`} onClick={() => handleSort('api_label')}>API {sortConfig.key === 'api_label' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</div>}
                        {visibleColumnsInvoices.label && <div className={`${styles.invoicesText} ${styles.listLabel}`} onClick={() => handleSort('label')}>{i18n.t('label')} {sortConfig.key === 'label' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</div>}
                        {visibleColumnsInvoices.amount && <div className={`${styles.invoicesText} ${styles.listToken}`} onClick={() => handleSort('amount')}>{i18n.t('amount')} {sortConfig.key === 'amount' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</div>}
                        {visibleColumnsInvoices.amount_usd && <div className={`${styles.invoicesText} ${styles.listAmount}`} onClick={() => handleSort('amount_usd')}>{i18n.t('amount')} USD {sortConfig.key === 'amount_usd' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</div>}
                        {visibleColumnsInvoices.is_paid && <div className={`${styles.invoicesText} ${styles.listPaid}`} onClick={() => handleSort('is_paid')}>{i18n.t('payed')} {sortConfig.key === 'is_paid' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</div>}
                        {visibleColumnsInvoices.is_postback_sended && <div className={`${styles.invoicesText} ${styles.listSent}`} onClick={() => handleSort('is_postback_sended')}>IPN {i18n.t('sent')} {sortConfig.key === 'is_postback_sended' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</div>}
                        {visibleColumnsInvoices.created_at && <div className={`${styles.invoicesText} ${styles.listTime}`} onClick={() => handleSort('created_at')}>{i18n.t('created')} {sortConfig.key === 'created_at' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</div>}
                    </div>

                    <ul className={`${styles.list} ${mediaStyles.list}`}>
                        {isLoadingInvoices ? (
                            <div className={styles.listLoading}>Загрузка инвойсов...</div>
                        ) : sortedInvoices.length === 0 ? (
                            <div className={styles.listNoTransactions}>
                                <h3 className={styles.listNoTransactionsTitle}>{i18n.t('noTransactionsTitle')}</h3>
                                <p className={styles.listNoTransactionsSubtitle}>{i18n.t('noTransactionsSubtitle')}</p>
                            </div>
                        ) : (
                            sortedInvoices.map((invoice) => (
                                <li key={invoice.id} className={`${styles.listItem} ${mediaStyles.listItem}`}>
                                    {visibleColumnsInvoices.id && (
                                        <div className={`${styles.listWrapper} ${styles.listId}`} onClick={() => handleSort('id')}>
                                            {invoice.id}
                                        </div>
                                    )}
                                    {visibleColumnsInvoices.uuid && (
                                        <div
                                            className={`${styles.listWrapper} ${styles.listHash}`}
                                            onClick={() => handleUuidClick(invoice.uuid)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {invoice.uuid}
                                        </div>
                                    )}
                                    {visibleColumnsInvoices.api_label && (
                                        <div className={`${styles.listWrapper} ${styles.listApi}`}>{invoice.api_label}</div>
                                    )}
                                    {visibleColumnsInvoices.label && (
                                        <div className={`${styles.listWrapper} ${styles.listLabel}`}>{invoice.label}</div>
                                    )}
                                    {visibleColumnsInvoices.amount && (
                                        <div className={`${styles.listWrapper} ${styles.listToken}`}>
                                            {invoice.amount % 1 === 0 ? invoice.amount : invoice.amount.toFixed(2)} {invoice.currency.name}
                                        </div>
                                    )}
                                    {visibleColumnsInvoices.amount_usd && (
                                        <div className={`${styles.listWrapper} ${styles.listAmount}`}>
                                            {invoice.amount_usd % 1 === 0 ? invoice.amount_usd : invoice.amount_usd.toFixed(2)} USDT
                                        </div>
                                    )}
                                    {visibleColumnsInvoices.is_paid && (
                                        <div className={`${styles.listWrapper} ${styles.listPaid}`}>
                                            {invoice.is_paid ? (
                                                <span className={styles.listStatusTrue}>{i18n.t('paid')}</span>
                                            ) : invoice.is_expired ? (
                                                <span className={styles.listStatusFalse}>{i18n.t('expired')}</span>
                                            ) : (
                                                <span className={styles.listStatusFalse}>{i18n.t('pending')}</span>
                                            )}
                                        </div>
                                    )}
                                    {visibleColumnsInvoices.is_postback_sended && (
                                        <div className={`${styles.listWrapper} ${styles.listSent}`}>
                                            {invoice.is_postback_sended ? (
                                                <span className={styles.listStatusTrue}>{i18n.t('sent')}</span>
                                            ) : (
                                                <span className={styles.listStatusFalse}>{i18n.t('notsent')}</span>
                                            )}
                                        </div>
                                    )}
                                    {visibleColumnsInvoices.created_at && (
                                        <div className={`${styles.listWrapper} ${styles.listTime}`}>
                                            {new Date(invoice.created_at).toLocaleString('ru-RU', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                            })}
                                        </div>
                                    )}
                                </li>
                            ))
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Invoices;
