import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
//@ts-ignore
import styles from "../Auth.module.scss";
//@ts-ignore
import mediaStyles from "../Auth-media.module.scss";

const ResetPassword = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState<{ email: boolean | string }>({ email: false });
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [loading, setLoading] = useState(false); // Добавляем состояние загрузки

    const isValidEmail = (email: string) => {
        const emailRegex = /^.+@[a-zA-Z]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newEmail = event.target.value;
        setEmail(newEmail);

        if (formSubmitted) {
            setErrors((prev) => ({
                ...prev,
                email: newEmail.trim().length === 0
                    ? t("enterEmail")
                    : !isValidEmail(newEmail)
                        ? t("incompleteEmail")
                        : false
            }));
        }
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setFormSubmitted(true);

        if (!isValidEmail(email)) {
            setErrors({ email: t("incompleteEmail") });
            return;
        }

        try {
            setLoading(true);
            // Запрос к API для отправки кода на почту
            const response = await fetch("/api/send-reset-code", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email })
            });

            if (!response.ok) throw new Error("Failed to send code");

            // Перенаправление на страницу ввода кода
            navigate("/confirm-code", { state: { email } });
        } catch (error) {
            setErrors({ email: t("errorSendingCode") });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={`${styles.auth} ${mediaStyles.auth}`}>
            <div className={`${styles.authContainer} ${mediaStyles.authContainer}`}>
                <header className={`${styles.authHeader} ${mediaStyles.authHeader}`}>
                    <h2 className={`${styles.authTitle} ${mediaStyles.authTitle}`}>{t("resetpass")}</h2>
                </header>
                <main className={`${styles.authMain} ${mediaStyles.authMain}`}>
                    <form className={`${styles.authForm} ${mediaStyles.authForm}`} onSubmit={handleSubmit} noValidate>
                        <div className={styles.inputWrapper}>
                            {errors.email && <span className={styles.tooltip}>{errors.email}</span>}
                            <input
                                className={`${styles.authInput} ${mediaStyles.authInput} ${errors.email ? styles.invalid : ""}`}
                                type="email"
                                placeholder={t("email")}
                                value={email}
                                onChange={handleEmailChange}
                                disabled={loading}
                            />
                        </div>
                        <button
                            className={`${styles.authButton} ${mediaStyles.authButton} ${email.trim() && isValidEmail(email) ? styles.valid : styles.invalid}`}
                            type="submit"
                            disabled={loading}
                        >
                            {loading ? t("sending") : t("continue")}
                        </button>
                    </form>
                </main>
                <footer className={`${styles.authFooter} ${mediaStyles.authFooter}`}>
                    <p className={`${styles.authText} ${mediaStyles.authText}`}>
                        <Link className={`${styles.authLink} ${mediaStyles.authLink}`} style={{ textDecoration: "none" }} to="/Authorization/Login">
                            {t("back")}
                        </Link>
                    </p>
                </footer>
            </div>
        </div>
    );
};

export default ResetPassword;
