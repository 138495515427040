import React from 'react';
import Calendar from 'react-calendar';
import { useTranslation } from 'react-i18next';
// Удаляем стандартные стили, так как у нас есть свои кастомные
// import 'react-calendar/dist/Calendar.css';

interface LocalizedCalendarProps {
    value?: Date | Date[] | null;
    onChange?: (value: any) => void;
    selectRange?: boolean;
    tileDisabled?: ({ date }: { date: Date }) => boolean;
    minDetail?: 'month' | 'year' | 'decade' | 'century';
    tileGroupCount?: number;
    className?: string;
}

const LocalizedCalendar: React.FC<LocalizedCalendarProps> = ({
    value,
    onChange,
    selectRange = false,
    tileDisabled,
    minDetail = 'month',
    tileGroupCount,
    className
}) => {
    const { t } = useTranslation();

    // Создаем локализованные форматы для календаря
    const formatMonthYear = (locale: string | undefined, date: Date) => {
        const month = date.getMonth();
        const monthNames = [
            t('calendar.months.january'),
            t('calendar.months.february'),
            t('calendar.months.march'),
            t('calendar.months.april'),
            t('calendar.months.may'),
            t('calendar.months.june'),
            t('calendar.months.july'),
            t('calendar.months.august'),
            t('calendar.months.september'),
            t('calendar.months.october'),
            t('calendar.months.november'),
            t('calendar.months.december')
        ];
        return `${monthNames[month]} ${date.getFullYear()}`;
    };

    const formatShortWeekday = (locale: string | undefined, date: Date) => {
        const dayOfWeek = date.getDay();
        const weekdayNames = [
            t('calendar.weekdays.sunday'),
            t('calendar.weekdays.monday'),
            t('calendar.weekdays.tuesday'),
            t('calendar.weekdays.wednesday'),
            t('calendar.weekdays.thursday'),
            t('calendar.weekdays.friday'),
            t('calendar.weekdays.saturday')
        ];
        return weekdayNames[dayOfWeek];
    };

    return (
        <Calendar
            // @ts-ignore - игнорируем ошибку типизации value и onChange
            value={value}
            onChange={onChange}
            selectRange={selectRange}
            tileDisabled={tileDisabled}
            minDetail={minDetail}
            // @ts-ignore
            tileGroupCount={tileGroupCount}
            className={className}
            formatMonthYear={formatMonthYear}
            formatShortWeekday={formatShortWeekday}
            navigationLabel={({ date }) => formatMonthYear(undefined, date)}
            nextLabel={t('calendar.navigation.nextLabel')}
            next2Label={t('calendar.navigation.next2Label')}
            prevLabel={t('calendar.navigation.prevLabel')}
            prev2Label={t('calendar.navigation.prev2Label')}
        />
    );
};

export default LocalizedCalendar; 