export const fakeTickets = [
    {
        id: 1,
        category: 'Technical Issues',
        subject: 'Unable to login',
        status: 'in_progress',
        created_at: "2025-02-09T07:21:21Z",
    },
    {
        id: 2,
        category: 'Account and Access',
        subject: 'Password reset not working',
        status: 'in_progress',
        created_at: "2025-02-25T10:21:21Z",
    },
    {
        id: 3,
        category: 'General Questions',
        subject: 'How to update profile?',
        status: 'closed',
        created_at: new Date().toISOString()
    }
];
export const initialMessages = [
    { id: 1, message: 'Здравствуйте, чем могу помочь?', fromSupport: true, createdAt: '2025-03-15 10:30' },
    { id: 2, message: 'Мне нужно проверить баланс', fromSupport: false, createdAt: '2025-03-15 10:32' },
    { id: 3, message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.', fromSupport: true, createdAt: '2025-03-15 10:34' },
    { id: 4, message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.', fromSupport: true, createdAt: '2025-03-15 10:34' },
    { id: 5, message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.', fromSupport: true, createdAt: '2025-03-15 10:34' },
    { id: 6, message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.', fromSupport: true, createdAt: '2025-03-15 10:34' },
];

export const dataBalance = {
    USDTBEP20: 200.02,
    USDTERC20: 13595.05,
    USDTTRC20: 1.01
}
export const authorizationHistory = [
    {
        time: "2025-03-04T15:00:01Z",
        device: "Mobile",
        ip: "192.168.1.1",
        country: "Russia",
    },
    {
        time: "2025-02-03T10:30:00Z",
        device: "PC",
        ip: "203.0.113.45",
        country: "USA",
    },
    {
        time: "2025-03-04T15:00:00Z",
        device: "Mobile",
        ip: "192.168.1.1",
        country: "Russia",
    },
    {
        time: "2025-03-03T10:30:00Z",
        device: "PC",
        ip: "203.0.113.45",
        country: "USA",
    },
    {
        time: "2025-03-04T15:00:00Z",
        device: "Mobile",
        ip: "192.168.1.1",
        country: "Russia",
    },
    {
        time: "2025-03-03T10:30:00Z",
        device: "PC",
        ip: "203.0.113.45",
        country: "USA",
    },
    {
        time: "2025-03-04T15:00:00Z",
        device: "Mobile",
        ip: "192.168.1.1",
        country: "Russia",
    },
    {
        time: "2025-03-03T10:30:00Z",
        device: "PC",
        ip: "203.0.113.45",
        country: "USA",
    },
    {
        time: "2025-03-04T15:00:00Z",
        device: "Mobile",
        ip: "192.168.1.1",
        country: "Russia",
    },
    {
        time: "2025-03-03T10:30:00Z",
        device: "PC",
        ip: "203.0.113.45",
        country: "USA",
    },
    {
        time: "2025-03-04T15:00:00Z",
        device: "Mobile",
        ip: "192.168.1.1",
        country: "Russia",
    },
    {
        time: "2025-03-03T10:30:00Z",
        device: "PC",
        ip: "203.0.113.45",
        country: "USA",
    },
];
export const exchangeRates = {
    USDTBEP20: 1.5,
    USDTERC20: 1.5,
    USDTTRC20: 1.5
};
export const convertedBalance = Object.fromEntries(
    Object.entries(dataBalance).map(([key, value]) => [key, value * exchangeRates[key]])
);
export const dataInvoices = {
    paid: 1200.02,
    partially: 195.05,
    unpaid: 519.01
}


export const topupsData = {
    day: 45.00,
    day_percent: 20.00,
    week: 258.00,
    week_percent: 20.00,
    month: 1644.00,
    month_percent: -20.00,
    all_time: 3597.00,
    all_time_percent: 20.00,
};
export const dataPaydUnpayd = {
    day: {
        wallets: { payed: 100, unpayed: 50 },
        invoices: { payed: 30, unpayed: 110 }
    },
    week: {
        wallets: { payed: 60, unpayed: 40 },
        invoices: { payed: 40, unpayed: 60 }
    },
    month: {
        wallets: { payed: 75, unpayed: 25 },
        invoices: { payed: 50, unpayed: 50 }
    },
    all_time: {
        wallets: { payed: 80, unpayed: 20 },
        invoices: { payed: 60, unpayed: 40 }
    }
};
export const transactionTypeData = {
    day: {
        trx: { amount: 400 },
        bnb: { amount: 333 },
        eth: { amount: 750 }
    },
    week: {
        trx: { amount: 700 },
        bnb: { amount: 500 },
        eth: { amount: 1550 }
    },
    month: {
        trx: { amount: 923 },
        bnb: { amount: 865 },
        eth: { amount: 2300 }
    },
    all_time: {
        trx: { amount: 1005 },
        bnb: { amount: 977 },
        eth: { amount: 4000 }
    }
};
export const dataTransactions = [
    { id: 1, tx_hash: "0xDE6F06AB...92A0B5496A", api_label: "API Label", wallet_label: "Wallet Label", amount: 7.0, amount_usd: 7.0, token: "USDT", chain: "BNB", is_payed: false, ipn_is_sended: false, created_at: "2025-02-09T07:21:21Z" },
    { id: 2, tx_hash: "1xDE6F06AB...92A0B5496A", api_label: "API Label", wallet_label: "Wallet Label", amount: 3.25, amount_usd: 3.20, token: "USDT", chain: "TRX", is_payed: true, ipn_is_sended: false, created_at: "2025-02-10T17:16:34Z" },
    { id: 3, tx_hash: "2xDE6F06AB...92A0B5496A", api_label: "API Label", wallet_label: "Wallet Label", amount: 12.56, amount_usd: 12.56, token: "USDT", chain: "ETH", is_payed: true, ipn_is_sended: true, created_at: "2025-02-10T14:43:54Z" },
    { id: 4, tx_hash: "3xDE6F06AB...92A0B5496A", api_label: "API Label", wallet_label: "Wallet Label", amount: 10.0, amount_usd: 10.0, token: "USDT", chain: "BNB", is_payed: false, ipn_is_sended: false, created_at: "2024-11-15T21:57:11Z" },
    { id: 5, tx_hash: "0xDE6F06AB...92A0B5496A", api_label: "API Label", wallet_label: "Wallet Label", amount: 7.0, amount_usd: 7.0, token: "USDT", chain: "BNB", is_payed: false, ipn_is_sended: false, created_at: "2024-01-01T07:21:21Z" },
    { id: 6, tx_hash: "1xDE6F06AB...92A0B5496A", api_label: "API Label", wallet_label: "Wallet Label", amount: 3.25, amount_usd: 3.20, token: "USDT", chain: "TRX", is_payed: true, ipn_is_sended: false, created_at: "2024-01-01T19:16:34Z" },
    { id: 71, tx_hash: "2xDE6F06AB...92A0B5496A", api_label: "API Label", wallet_label: "Wallet Label", amount: 12.56, amount_usd: 12.56, token: "USDT", chain: "ETH", is_payed: true, ipn_is_sended: true, created_at: "2024-01-01T14:43:54Z" },
    { id: 72, tx_hash: "2xDE6F06AB...92A0B5496A", api_label: "API Label", wallet_label: "Wallet Label", amount: 12.56, amount_usd: 12.56, token: "USDT", chain: "ETH", is_payed: true, ipn_is_sended: true, created_at: "2024-01-01T14:43:54Z" },
    { id: 73, tx_hash: "2xDE6F06AB...92A0B5496A", api_label: "API Label", wallet_label: "Wallet Label", amount: 12.56, amount_usd: 12.56, token: "USDT", chain: "ETH", is_payed: true, ipn_is_sended: true, created_at: "2024-01-01T14:43:54Z" },
    { id: 74, tx_hash: "2xDE6F06AB...92A0B5496A", api_label: "API Label", wallet_label: "Wallet Label", amount: 12.56, amount_usd: 12.56, token: "USDT", chain: "ETH", is_payed: true, ipn_is_sended: true, created_at: "2024-01-01T14:43:54Z" },
    { id: 75, tx_hash: "2xDE6F06AB...92A0B5496A", api_label: "API Label", wallet_label: "Wallet Label", amount: 12.56, amount_usd: 12.56, token: "USDT", chain: "ETH", is_payed: true, ipn_is_sended: true, created_at: "2024-01-01T14:43:54Z" },
    { id: 76, tx_hash: "2xDE6F06AB...92A0B5496A", api_label: "API Label", wallet_label: "Wallet Label", amount: 12.56, amount_usd: 12.56, token: "USDT", chain: "ETH", is_payed: true, ipn_is_sended: true, created_at: "2024-01-01T14:43:54Z" },
    { id: 77, tx_hash: "2xDE6F06AB...92A0B5496A", api_label: "API Label", wallet_label: "Wallet Label", amount: 12.56, amount_usd: 12.56, token: "USDT", chain: "ETH", is_payed: true, ipn_is_sended: true, created_at: "2024-01-01T14:43:54Z" },
    { id: 78, tx_hash: "2xDE6F06AB...92A0B5496A", api_label: "API Label", wallet_label: "Wallet Label", amount: 12.56, amount_usd: 12.56, token: "USDT", chain: "ETH", is_payed: true, ipn_is_sended: true, created_at: "2024-01-01T14:43:54Z" },
    { id: 79, tx_hash: "2xDE6F06AB...92A0B5496A", api_label: "API Label", wallet_label: "Wallet Label", amount: 12.56, amount_usd: 12.56, token: "USDT", chain: "ETH", is_payed: true, ipn_is_sended: true, created_at: "2024-01-01T14:43:54Z" },
    { id: 70, tx_hash: "2xDE6F06AB...92A0B5496A", api_label: "API Label", wallet_label: "Wallet Label", amount: 12.56, amount_usd: 12.56, token: "USDT", chain: "ETH", is_payed: true, ipn_is_sended: true, created_at: "2024-01-01T14:43:54Z" },
    { id: 67, tx_hash: "2xDE6F06AB...92A0B5496A", api_label: "API Label", wallet_label: "Wallet Label", amount: 12.56, amount_usd: 12.56, token: "USDT", chain: "ETH", is_payed: true, ipn_is_sended: true, created_at: "2024-01-01T14:43:54Z" },
    { id: 66, tx_hash: "2xDE6F06AB...92A0B5496A", api_label: "API Label", wallet_label: "Wallet Label", amount: 12.56, amount_usd: 12.56, token: "USDT", chain: "ETH", is_payed: true, ipn_is_sended: true, created_at: "2024-01-01T14:43:54Z" },
    { id: 65, tx_hash: "2xDE6F06AB...92A0B5496A", api_label: "API Label", wallet_label: "Wallet Label", amount: 12.56, amount_usd: 12.56, token: "USDT", chain: "ETH", is_payed: true, ipn_is_sended: true, created_at: "2024-01-01T14:43:54Z" },
    { id: 64, tx_hash: "2xDE6F06AB...92A0B5496A", api_label: "API Label", wallet_label: "Wallet Label", amount: 12.56, amount_usd: 12.56, token: "USDT", chain: "ETH", is_payed: true, ipn_is_sended: true, created_at: "2024-01-01T14:43:54Z" },
    { id: 43, tx_hash: "2xDE6F06AB...92A0B5496A", api_label: "API Label", wallet_label: "Wallet Label", amount: 12.56, amount_usd: 12.56, token: "USDT", chain: "ETH", is_payed: true, ipn_is_sended: true, created_at: "2024-01-01T14:43:54Z" },
    { id: 32, tx_hash: "2xDE6F06AB...92A0B5496A", api_label: "API Label", wallet_label: "Wallet Label", amount: 12.56, amount_usd: 12.56, token: "USDT", chain: "ETH", is_payed: true, ipn_is_sended: true, created_at: "2024-01-01T14:43:54Z" },
    { id: 12, tx_hash: "2xDE6F06AB...92A0B5496A", api_label: "API Label", wallet_label: "Wallet Label", amount: 12.56, amount_usd: 12.56, token: "USDT", chain: "ETH", is_payed: true, ipn_is_sended: true, created_at: "2024-01-01T14:43:54Z" },
    { id: 31, tx_hash: "2xDE6F06AB...92A0B5496A", api_label: "API Label", wallet_label: "Wallet Label", amount: 12.56, amount_usd: 12.56, token: "USDT", chain: "ETH", is_payed: true, ipn_is_sended: true, created_at: "2024-01-01T14:43:54Z" },
    { id: 51, tx_hash: "2xDE6F06AB...92A0B5496A", api_label: "API Label", wallet_label: "Wallet Label", amount: 12.56, amount_usd: 12.56, token: "USDT", chain: "ETH", is_payed: true, ipn_is_sended: true, created_at: "2024-01-01T14:43:54Z" },
    { id: 8, tx_hash: "3xDE6F06AB...92A0B5496A", api_label: "API Label", wallet_label: "Wallet Label", amount: 10.0, amount_usd: 10.0, token: "USDT", chain: "BNB", is_payed: false, ipn_is_sended: false, created_at: "2025-01-12T14:57:11Z" }
];
export const dataBase = [
    {
        date: '2025-02-09',
        entries: [
            { time: '10:00', amount: 150 },
            { time: '16:30', amount: 200 },
            { time: '20:00', amount: 50 }
        ]
    },
    {
        date: '2025-02-10',
        entries: [
            { time: '09:15', amount: 100 },
            { time: '14:45', amount: 250 }
        ]
    },
    {
        date: '2025-01-07',
        entries: [
            { time: '09:15', amount: 1000 },
            { time: '14:45', amount: 2500 }
        ]
    },
    {
        date: '2024-11-06',
        entries: [
            { time: '09:15', amount: 576 },
            { time: '14:45', amount: 832 }
        ]
    },
    {
        date: '2024-10-03',
        entries: [
            { time: '11:00', amount: 180 },
            { time: '17:20', amount: 120 }
        ]
    },
    {
        date: '2024-09-08',
        entries: [
            { time: '11:00', amount: 180 },
            { time: '17:20', amount: 120 },
            { time: '20:00', amount: 50 }
        ]
    },
    {
        date: '2024-08-08',
        entries: [
            { time: '11:00', amount: 180 },
            { time: '17:20', amount: 120 },
            { time: '20:00', amount: 50 }
        ]
    },
    {
        date: '2024-07-08',
        entries: [
            { time: '11:00', amount: 180 },
            { time: '17:20', amount: 120 },
            { time: '20:00', amount: 50 }
        ]
    },
    {
        date: '2024-06-08',
        entries: [
            { time: '11:00', amount: 180 },
            { time: '17:20', amount: 120 },
            { time: '20:00', amount: 50 }
        ]
    },
    {
        date: '2024-05-08',
        entries: [
            { time: '11:00', amount: 180 },
            { time: '17:20', amount: 120 },
            { time: '20:00', amount: 50 }
        ]
    },
    {
        date: '2024-04-08',
        entries: [
            { time: '11:00', amount: 180 },
            { time: '17:20', amount: 120 },
            { time: '20:00', amount: 50 }
        ]
    },
    {
        date: '2024-03-08',
        entries: [
            { time: '11:00', amount: 180 },
            { time: '17:20', amount: 120 },
            { time: '20:00', amount: 50 }
        ]
    },
    {
        date: '2024-02-08',
        entries: [
            { time: '11:00', amount: 180 },
            { time: '17:20', amount: 120 },
            { time: '20:00', amount: 50 }
        ]
    },
    {
        date: '2024-02-09',
        entries: [
            { time: '11:00', amount: 180 },
            { time: '17:20', amount: 120 },
            { time: '20:00', amount: 50 },
            { time: '21:00', amount: 50 }
        ]
    },
];

export const dataWallets = [
    { id: "6153", value: "0xfD4B78B608C76F6867", type: "USDT-BNB" },
    { id: "6153", value: "0xDE6F06AB...92A0B5496A", type: "USDT-ETH" },
    { id: "6153", value: "TM5W609WBX...NnPft5CQFt", type: "USDT-TRX" },
    { id: "6166", value: "0x7E0c2591...FFbB998631", type: "USDT-BNB" },
    { id: "6166", value: "0x3e9De831...089ac30f84", type: "USDT-ETH" },
    { id: "6166", value: "TL9k8W4sBr...5cBmFG8FpD", type: "USDT-TRX" },
    { id: "6138", value: "0x3E1b793D...a870Ec63c1", type: "USDT-BNB" },
    { id: "6138", value: "0x618a8de9...97aC234D05", type: "USDT-ETH" },
    { id: "6138", value: "TQrqFyYitm...qS5SCxRapj", type: "USDT-TRX" },
];

