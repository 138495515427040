import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { SingleValue } from 'react-select';
import Calendar from 'react-calendar';
import { format } from 'date-fns';
import { apiRequest, getCurrencies, Currency } from '../../data/Api.ts';

import { LupaSvg, CrossSvg, ArrowSvg, CalendarSvg, ExportSvg, SettingsSvg, OstentatiousCrossSvg, TrashSvg } from '../../assets/svg-files/SvgIcons.ts';
import LocalizedCalendar from '../../components/common/LocalizedCalendar.tsx';
//@ts-ignore
import styles from './Wallets.module.scss';
//@ts-ignore
import mediaStyles from './Wallets-media.module.scss';

// Обновленный интерфейс, соответствующий данным с API
interface Wallet {
    id: number;
    api_label: string;
    label: string;
    address: string;
    currency: string;
    postback_url: string | null;
    created_at: string;
}

// Обновленный интерфейс Project с добавлением api_id
interface Project {
    id: number;
    label: string;
    api_key: string;
    api_id: number; // Добавляем поле api_id
    percent: number;
    is_user_payer: boolean;
    company_name: string;
    banner: string;
    background: string;
    created_at: string;
}

type CurrencyOption = { value: string; label: string };
// Изменим тип ProjectOption, чтобы хранить id проекта вместо api_id или api_key
type ProjectOption = { value: number; label: string; projectId: number }; // value для Select, projectId - это id проекта

const filterOptions = {
    Currency: [] as string[], // Будет заполнен из API
    Project: [] as string[], // Будет заполнен из API
};

const Wallets: React.FC = () => {
    const [projects, setProjects] = useState<Project[]>([]);
    const [projectOptions, setProjectOptions] = useState<ProjectOption[]>([]);
    const [selectedProject, setSelectedProject] = useState<ProjectOption | null>(null);
    const [currencyOptions, setCurrencyOptions] = useState<CurrencyOption[]>([]);
    const [selectedCurrency, setSelectedCurrency] = useState<CurrencyOption | null>(null);
    const [wallets, setWallets] = useState<Wallet[]>([]);
    const [isLoadingWallets, setIsLoadingWallets] = useState(false);
    const [isLoadingProjects, setIsLoadingProjects] = useState(false);
    const [isLoadingCurrencies, setIsLoadingCurrencies] = useState(false);

    const handleCurrencyChange = (newValue: SingleValue<CurrencyOption>) => {
        setSelectedCurrency(newValue);
        if (newValue) {
            setWalletFormData(prev => ({
                ...prev,
                currency: newValue.value
            }));
        }
    };

    const handleProjectChange = (newValue: SingleValue<ProjectOption>) => {
        setSelectedProject(newValue);
        if (newValue) {
            setWalletFormData(prev => ({
                ...prev,
                api_id: newValue.projectId // Используем id проекта как api_id
            }));
        }
    };

    // Получение списка проектов
    useEffect(() => {
        const fetchProjects = async () => {
            setIsLoadingProjects(true);
            try {
                const response = await apiRequest<Project[]>('/pay/user/projects/', 'GET');
                console.log('Данные проектов:', response);
                if (response && Array.isArray(response)) {
                    setProjects(response);

                    // Преобразуем данные для Select, сохраняя id проекта в отдельном поле
                    const options = response.map(project => ({
                        value: project.api_id, // Значение для компонента Select
                        label: project.label, // Текст для отображения
                        projectId: project.id // Сохраняем id проекта для использования в api_id
                    }));
                    setProjectOptions(options);

                    // Заполняем фильтры проектов
                    filterOptions.Project = response.map(project => project.label);
                }
            } catch (error) {
                console.error('Failed to fetch projects:', error);
            } finally {
                setIsLoadingProjects(false);
            }
        };

        fetchProjects();
    }, []);

    // Получение списка валют
    useEffect(() => {
        const fetchCurrencies = async () => {
            setIsLoadingCurrencies(true);
            try {
                const data = await getCurrencies();

                // Преобразуем данные для Select
                const options = data.map(currency => ({
                    value: currency.name, // Используем оригинальное name вместо toLowerCase
                    label: currency.name
                }));

                setCurrencyOptions(options);
            } catch (error) {
                console.error('Ошибка при получении данных о валютах:', error);
            } finally {
                setIsLoadingCurrencies(false);
            }
        };

        fetchCurrencies();
    }, []);

    // В функциональном компоненте добавляем загрузку валют для фильтров
    // Добавляем эффект для обновления фильтров валют
    useEffect(() => {
        const fetchCurrenciesForFilters = async () => {
            try {
                const currencies = await getCurrencies();
                // Извлекаем только имена валют для фильтрации
                const currencyNames = currencies.map(currency => currency.name);
                // Обновляем filterOptions с новыми валютами
                filterOptions.Currency = currencyNames;
            } catch (error) {
                console.error('Ошибка при получении данных о валютах для фильтров:', error);
            }
        };

        fetchCurrenciesForFilters();
    }, []);

    useEffect(() => {
        const fetchWallets = async () => {
            setIsLoadingWallets(true);
            try {
                const response = await apiRequest<Wallet[]>('/pay/user/wallets/', 'GET');
                console.log('Данные кошельков:', response);
                if (response && Array.isArray(response)) {
                    setWallets(response);
                } else {
                    console.error('Unexpected response format:', response);
                    setWallets([]);
                }
            } catch (error) {
                console.error('Failed to fetch wallets:', error);
                setWallets([]);
            } finally {
                setIsLoadingWallets(false);
            }
        };

        fetchWallets();
    }, []);

    const { i18n, t } = useTranslation();
    const getYesterday = (): Date => {
        const today = new Date();
        today.setDate(today.getDate() - 1);
        return today;
    };

    const yesterday = getYesterday();
    const today = new Date();

    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [isAllTime, setIsAllTime] = useState(false);
    const [isCalendarVisible, setIsCalendarVisible] = useState(false);
    const [selectedDates, setSelectedDates] = useState<Date[]>([yesterday, today]);
    const [isFilterDropdownVisible, setIsFilterDropdownVisible] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedOptions, setSelectedOptions] = useState<{ [key: string]: Set<string> }>({});
    const [activeCategory, setActiveCategory] = useState<string | null>(null);
    const createWalletDialog = useRef<HTMLDialogElement | null>(null);
    const calendarContainerRef = useRef<HTMLDivElement | null>(null);
    const calendarButtonRef = useRef<HTMLButtonElement | null>(null);
    const filterDropdownRef = useRef<HTMLDivElement | null>(null);
    const filterButtonRef = useRef<HTMLButtonElement | null>(null);
    const hasActiveFilters = Object.values(selectedOptions).some((options) => options.size > 0);

    // Обновим состояние формы для хранения api_id вместо api_key
    const [walletFormData, setWalletFormData] = useState({
        api_id: 0, // Используем числовой тип
        currency: '',
        label: '',
        postback_url: ''
    });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const openCreateWalletDialog = () => {
        // Сбрасываем форму при открытии диалога
        setWalletFormData({
            api_id: 0,
            currency: '',
            label: '',
            postback_url: ''
        });
        setSelectedProject(null);
        setSelectedCurrency(null);
        setError(null);

        createWalletDialog.current?.showModal();
    };

    const settingsDialog = useRef<HTMLDialogElement>(null);
    const handleBackdropClick = (event: React.MouseEvent<HTMLDialogElement>) => {
        if (event.target === settingsDialog.current) {
            settingsDialog.current?.close();
        }
        if (event.target === createWalletDialog.current) {
            createWalletDialog.current?.close();
        }
    };

    interface VisibleColumns {
        id: boolean;
        api_label: boolean;
        wallet_label: boolean;
        currency: boolean;
        address: boolean;
        created_at: boolean;
    }
    const [visibleColumns, setVisibleColumns] = useState<VisibleColumns>({
        id: true,
        api_label: true,
        wallet_label: true,
        currency: true,
        address: true,
        created_at: true
    });
    const [tempVisibleColumns, setTempVisibleColumns] = useState<VisibleColumns>({
        id: true,
        api_label: true,
        wallet_label: true,
        currency: true,
        address: true,
        created_at: true
    });

    // Загружаем настройки видимости из localStorage при первом рендере
    useEffect(() => {
        const savedSettings = localStorage.getItem("visibleColumns");
        if (savedSettings) {
            const savedColumns = JSON.parse(savedSettings);
            setVisibleColumns(savedColumns);
            setTempVisibleColumns(savedColumns); // Инициализируем tempVisibleColumns значениями из localStorage
        }
    }, []);

    // Функция для изменения видимости столбца
    const toggleColumn = (column: string) => {
        if (column === "id" || column === "created_at") {
            return; // Заблокированные столбцы нельзя изменить
        }

        setTempVisibleColumns((prev: VisibleColumns) => ({
            ...prev,
            [column]: !prev[column],
        }));
    };

    // Функция для изменения всех столбцов (выбрать все / отменить все)
    const toggleAllColumns = () => {
        const areAllSelected = Object.keys(tempVisibleColumns)
            .filter((key) => key !== "id" && key !== "created_at") // Исключаем id и created_at
            .every((key) => tempVisibleColumns[key]); // Проверяем, все ли остальные выбраны

        setTempVisibleColumns((prev: VisibleColumns) => {
            const newVisibleColumns: VisibleColumns = {
                id: true,
                api_label: !areAllSelected,
                wallet_label: !areAllSelected,
                currency: !areAllSelected,
                address: !areAllSelected,
                created_at: true,
            };
            return newVisibleColumns;
        });
    };

    // Сохраняем изменения в localStorage
    const saveSettings = () => {
        // Обновляем видимость столбцов
        setVisibleColumns(tempVisibleColumns);

        // Сохраняем изменения в localStorage
        localStorage.setItem("visibleColumns", JSON.stringify(tempVisibleColumns));
        if (settingsDialog.current) {
            settingsDialog.current.close();
        }
    };

    const handleFilterToggle = () => {
        setIsFilterDropdownVisible(!isFilterDropdownVisible);
        setSearchQuery('');
        setActiveCategory(null);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    const handleCategoryClick = (category: string) => {
        setActiveCategory(category);
        setSearchQuery('');
    };

    const handleCheckboxChange = (category: string, option: string) => {
        setSelectedOptions((prev) => {
            const updatedSet = new Set(prev[category] || []);
            if (updatedSet.has(option)) {
                updatedSet.delete(option);
            } else {
                updatedSet.add(option);
            }
            return { ...prev, [category]: updatedSet };
        });
    };

    const handleRemoveFilter = (category: string, option: string) => {
        setSelectedOptions((prev) => {
            const updatedSet = new Set(prev[category] || []);
            updatedSet.delete(option);
            return { ...prev, [category]: updatedSet };
        });
    };

    const filteredResults = Object.entries(filterOptions).flatMap(([category, options]) =>
        options.filter((option) => option.toLowerCase().includes(searchQuery.toLowerCase()))
            .map((option) => ({ category, option }))
    );

    const handleCalendarToggle = () => {
        setIsCalendarVisible(!isCalendarVisible);
    };

    const handleDateChange = (dates: Date[]) => {
        if (dates.length === 1) {
            setSelectedDates([dates[0]]);
            setStartDate(dates[0]);
            setEndDate(null);
            setIsAllTime(false); // Сбрасываем флаг "всё время"
        } else if (dates.length === 2) {
            setSelectedDates(dates);
            setStartDate(dates[0]);
            setEndDate(dates[1]);
            setIsAllTime(false); // Сбрасываем флаг "всё время"
        }
    };

    const handleApplyFilter = () => {
        setIsAllTime(false);

        setIsCalendarVisible(false);
    };

    const [sortConfig, setSortConfig] = useState<{ key: string | null; direction: 'asc' | 'desc' }>({
        key: 'created_at',
        direction: 'desc',
    });

    const handleSort = (key: string) => {
        setSortConfig((prev) => ({
            key,
            direction: prev.key === key && prev.direction === 'desc' ? 'asc' : 'desc',
        }));
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setWalletFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const createWallet = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);
        setError(null);

        try {
            // Проверяем наличие обязательных полей
            if (!walletFormData.api_id) {
                throw new Error('Выберите API ключ');
            }
            if (!walletFormData.currency) {
                throw new Error('Выберите валюту');
            }
            if (!walletFormData.label.trim()) {
                throw new Error('Введите название кошелька');
            }

            // Формируем данные запроса
            // Здесь api_id содержит значение id из интерфейса Project
            const requestData = {
                api_id: walletFormData.api_id, // Используем id проекта как api_id
                currency: walletFormData.currency,
                label: walletFormData.label,
                postback_url: walletFormData.postback_url || null
            };

            console.log('Sending request with api_id (project.id):', requestData.api_id);

            // Отправляем запрос на создание кошелька
            const response = await apiRequest('/pay/user/wallets/create', 'POST', requestData);
            console.log('Wallet created:', response);

            createWalletDialog.current?.close();

            // Сбрасываем форму
            setWalletFormData({
                api_id: 0,
                currency: '',
                label: '',
                postback_url: ''
            });
            setSelectedProject(null);
            setSelectedCurrency(null);

            // После успешного создания обновляем список кошельков
            try {
                const updatedWallets = await apiRequest<Wallet[]>('/pay/user/wallets/', 'GET');
                if (updatedWallets && Array.isArray(updatedWallets)) {
                    setWallets(updatedWallets);
                }
            } catch (err) {
                console.error('Error fetching updated wallets:', err);
            }

        } catch (err) {
            setError(err instanceof Error ? err.message : 'Произошла ошибка при создании кошелька');
            console.error('Error creating wallet:', err);
        } finally {
            setIsLoading(false);
        }
    };

    const handleClickOutside = (event: MouseEvent | React.MouseEvent) => {
        if (
            filterDropdownRef.current &&
            !filterDropdownRef.current.contains(event.target as Node) &&
            filterButtonRef.current &&
            !filterButtonRef.current.contains(event.target as Node)
        ) {
            setIsFilterDropdownVisible(false);
        }
        if (calendarContainerRef.current &&
            !calendarContainerRef.current.contains(event.target as Node) &&
            calendarButtonRef.current &&
            !calendarButtonRef.current.contains(event.target as Node)
        ) {
            setIsCalendarVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleClearFilters = () => {
        setSelectedOptions({});
    };

    // Добавляю функцию фильтрации кошельков
    const filteredWallets = wallets.filter((wallet) => {
        const createdAt = new Date(wallet.created_at);

        // Фильтрация по диапазону дат
        let dateMatch = false;
        if (startDate && endDate) {
            dateMatch = createdAt >= startDate && createdAt <= endDate;
        } else if (startDate) {
            const endOfDay = new Date(startDate);
            endOfDay.setHours(23, 59, 59, 999);
            dateMatch = createdAt >= startDate && createdAt <= endOfDay;
        } else {
            dateMatch = true;
        }

        // Фильтрация по текстовому поиску в api_label, label, address, currency
        const textMatch = [wallet.api_label, wallet.label, wallet.address, wallet.currency].some((field) =>
            field?.toLowerCase().includes(searchQuery.toLowerCase())
        );

        // Фильтрация по выбранным опциям
        const matchesFilters = Object.entries(selectedOptions).every(([category, selectedValues]) => {
            if (selectedValues.size === 0) return true; // Если в категории нет выбранных значений, пропускаем фильтрацию

            switch (category) {
                case 'Currency':
                    return selectedValues.has(wallet.currency);
                case 'Project':
                    return selectedValues.has(wallet.api_label);
                // Для остальных фильтров можно добавить логику по мере необходимости
                default:
                    return true;
            }
        });

        return dateMatch && matchesFilters && textMatch;
    });

    // Сортировка кошельков
    const sortedWallets = [...filteredWallets].sort((a, b) => {
        if (!sortConfig.key) return 0;
        const { key, direction } = sortConfig;

        // Используем индексную нотацию для доступа к свойствам
        // @ts-ignore
        let aValue = a[key];
        // @ts-ignore
        let bValue = b[key];

        if (key === 'created_at') {
            aValue = new Date(aValue).getTime();
            bValue = new Date(bValue).getTime();
        }

        if (aValue < bValue) return direction === 'asc' ? -1 : 1;
        if (aValue > bValue) return direction === 'asc' ? 1 : -1;
        return 0;
    });

    return (
        <div className={`${styles.wallets} ${mediaStyles.wallets}`}>

            <div className={`${styles.walletsCreatе} ${mediaStyles.walletsCreatе}`}>
                <div className={`${styles.walletsCreatеText} ${mediaStyles.walletsCreatеText}`}>Вы можете моментально вручную создать счет на оплату в любой из предложенных сервисом криптовалют на необходимую сумму.</div>
                <button className={`${styles.walletsCreatеButton} ${mediaStyles.walletsCreatеButton}`} onClick={openCreateWalletDialog}>Создать кошелек</button>
                <dialog ref={createWalletDialog} onClick={handleBackdropClick} className={`${styles.dialog} ${mediaStyles.dialog}`}>
                    <div className={`${styles.dialogBlock} ${mediaStyles.dialogBlock}`}>
                        <div className={`${styles.dialogContainer} ${mediaStyles.dialogContainer} ${styles.dialogContainerWallet}`}>
                            <div className={`${styles.dialogHeader} ${mediaStyles.dialogHeader}`}>
                                <h2 className={`${styles.dialogTitle} ${mediaStyles.dialogTitle}`}>Создать новый кошелек</h2>
                                <button onClick={() => createWalletDialog.current?.close()} className={`${styles.walletsCreatеCross} ${mediaStyles.walletsCreatеCross}`}>X</button>
                            </div>
                            <div className={`${styles.dialogContent} ${mediaStyles.dialogContent}`}>
                                <label className={`${styles.dialogLabel} ${mediaStyles.dialogLabel}`}>
                                    <span className={`${styles.dialogSpan} ${mediaStyles.dialogSpan}`}>API Key</span>
                                    {isLoadingProjects ? (
                                        <div className={`${styles.loading} ${mediaStyles.loading}`}>Loading projects...</div>
                                    ) : (
                                        <Select
                                            placeholder='Select API'
                                            value={selectedProject}
                                            onChange={handleProjectChange}
                                            options={projectOptions}
                                            isSearchable={false}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    borderColor: 'light-dark(var(--border-color-light), var(--border-color-dark))',
                                                    backgroundColor: 'light-dark(var(--second-color-light), var(--second-color-dark))',
                                                    color: 'light-dark(var(--text-color-light), var(--text-color-dark));',
                                                    padding: '5px',
                                                    borderRadius: '10px',
                                                    cursor: 'pointer',
                                                    height: '48px',
                                                    boxShadow: 'none',
                                                    ':hover': {
                                                        borderColor: 'light-dark(var(--border-color-light), var(--border-color-dark))',
                                                    },
                                                }),
                                                singleValue: (provided) => ({
                                                    ...provided,
                                                    color: "light-dark(var(--text-color-light), var(--text-color-dark));",
                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    backgroundColor: 'light-dark(var(--second-color-light), var(--second-color-dark))',
                                                    borderRadius: '13px',
                                                    border: '1px solid light-dark(var(--border-color-light), var(--border-color-dark))',
                                                }),
                                                menuList: (provided) => ({
                                                    ...provided,
                                                    padding: 0,
                                                    borderRadius: '10px',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: "2px"
                                                }),
                                                option: (provided) => ({
                                                    ...provided,
                                                    backgroundColor: "light-dark(var(--second-color-light), var(--second-color-dark))",
                                                    color: 'light-dark(var(--text-color-light), var(--text-color-dark))',
                                                    padding: '15px 19px',
                                                    borderRadius: '10px',
                                                    minHeight: '48px',
                                                    transition: "background-color 0.2s ease",
                                                    cursor: 'pointer',
                                                    '&:hover': {
                                                        backgroundColor: 'light-dark(var(--second-color-light), var(--second-color-dark))',
                                                    },
                                                }),
                                                indicatorSeparator: (provided) => ({
                                                    ...provided,
                                                    display: 'none',
                                                }),
                                                dropdownIndicator: (provided) => ({
                                                    ...provided,
                                                    display: 'none',
                                                })
                                            }}
                                        />
                                    )}
                                </label>
                                <label className={`${styles.dialogLabel} ${mediaStyles.dialogLabel}`} htmlFor="currency">
                                    <span className={`${styles.dialogSpan} ${mediaStyles.dialogSpan}`}>Currency</span>
                                    {isLoadingCurrencies ? (
                                        <div className={`${styles.loading} ${mediaStyles.loading}`}>Loading currencies...</div>
                                    ) : (
                                        <Select
                                            options={currencyOptions}
                                            onChange={handleCurrencyChange}
                                            value={selectedCurrency}
                                            placeholder='Currency'
                                            isSearchable={false}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    borderColor: 'light-dark(var(--border-color-light), var(--border-color-dark))',
                                                    backgroundColor: 'light-dark(var(--second-color-light), var(--second-color-dark))',
                                                    color: 'light-dark(var(--text-color-light), var(--text-color-dark));',
                                                    padding: '5px',
                                                    borderRadius: '10px',
                                                    cursor: 'pointer',
                                                    height: '48px',
                                                    boxShadow: 'none',
                                                    ':hover': {
                                                        borderColor: 'light-dark(var(--border-color-light), var(--border-color-dark))',
                                                    },
                                                }),
                                                singleValue: (provided) => ({
                                                    ...provided,
                                                    color: "light-dark(var(--text-color-light), var(--text-color-dark));",
                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    backgroundColor: 'light-dark(var(--second-color-light), var(--second-color-dark))',
                                                    borderRadius: '13px',
                                                    border: '1px solid light-dark(var(--border-color-light), var(--border-color-dark))',
                                                }),
                                                menuList: (provided) => ({
                                                    ...provided,
                                                    padding: 0,
                                                    borderRadius: '10px',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: "2px"
                                                }),
                                                option: (provided) => ({
                                                    ...provided,
                                                    backgroundColor: "light-dark(var(--second-color-light), var(--second-color-dark))",
                                                    color: 'light-dark(var(--text-color-light), var(--text-color-dark))',
                                                    padding: '15px 19px',
                                                    borderRadius: '10px',
                                                    minHeight: '48px',
                                                    transition: "background-color 0.2s ease",
                                                    cursor: 'pointer',
                                                    '&:hover': {
                                                        backgroundColor: 'light-dark(var(--second-color-light), var(--second-color-dark))',
                                                    },
                                                }),
                                                indicatorSeparator: (provided) => ({
                                                    ...provided,
                                                    display: 'none',
                                                }),
                                                dropdownIndicator: (provided) => ({
                                                    ...provided,
                                                    display: 'none',
                                                })
                                            }}
                                        />
                                    )}
                                </label>
                                <label className={`${styles.dialogLabel} ${mediaStyles.dialogLabel}`} htmlFor="label">
                                    <span className={`${styles.dialogSpan} ${mediaStyles.dialogSpan}`}>Label</span>
                                    <input
                                        className={`${styles.dialogInput} ${mediaStyles.dialogInput}`}
                                        type="text"
                                        placeholder="My wallet"
                                        name="label"
                                        value={walletFormData.label}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </label>
                                <label className={`${styles.dialogLabel} ${mediaStyles.dialogLabel}`} htmlFor="postback_url">
                                    <span className={`${styles.dialogSpan} ${mediaStyles.dialogSpan}`}>IPN (optional)</span>
                                    <input
                                        className={`${styles.dialogInput} ${mediaStyles.dialogInput}`}
                                        type="url"
                                        placeholder="https://example.com/ipn"
                                        name="postback_url"
                                        value={walletFormData.postback_url}
                                        onChange={handleInputChange}
                                    />
                                </label>
                                {error && <div className={`${styles.dialogError} ${mediaStyles.dialogError}`}>{error}</div>}
                                <button
                                    className={`${styles.walletsCreatеDialogButton} ${mediaStyles.walletsCreatеDialogButton}`}
                                    onClick={createWallet}
                                    disabled={isLoading}
                                >
                                    {isLoading ? 'Создание...' : 'Создать'}
                                </button>
                            </div>
                        </div>
                    </div>
                </dialog>
            </div>

            <div className={`${styles.walletsContainer} ${mediaStyles.walletsContainer}`}>
                <div className={`${styles.walletsWrapper} ${mediaStyles.walletsWrapper}`}>
                    <div className={`${styles.walletsTitle} ${mediaStyles.walletsTitle}`}>History of operations</div>
                    <div className={`${styles.walletsItem}`}>
                        <button className={`${styles.walletsButton}`}>Экспорт <ExportSvg /></button>
                        <button className={`${styles.walletsButton}`} onClick={() => settingsDialog.current?.showModal()}><SettingsSvg /></button>
                        <dialog ref={settingsDialog} onClick={handleBackdropClick} className={`${styles.dialog} ${mediaStyles.dialog}`}>
                            <div className={`${styles.dialogBlock} ${mediaStyles.dialogBlock}`}>
                                <div className={`${styles.dialogContainer} ${mediaStyles.dialogContainer}`}>
                                    <div className={`${styles.walletsDialogHeader} ${mediaStyles.walletsDialogHeader}`}>
                                        <h3 className={`${styles.walletsDialogTitle}`}>Settings</h3>
                                        <button onClick={() => settingsDialog.current?.close()} className={`${styles.walletsDialogCross}`}>X</button>
                                    </div>

                                    <div className={`${styles.walletsDialogWrapper} ${mediaStyles.walletsDialogWrapper}`}>
                                        Parameters
                                        <label className={`${styles.walletsDialogSub}`}>
                                            Status
                                            <input
                                                type="checkbox"
                                                checked={Object.keys(tempVisibleColumns)
                                                    .filter((key) => key !== "id" && key !== "created_at")
                                                    .every((key) => tempVisibleColumns[key])}
                                                onChange={toggleAllColumns}
                                                className={`${styles.walletsDialogCheckbox}`}
                                            />
                                        </label>
                                    </div>

                                    <ul className={`${styles.walletsDialogUl} ${mediaStyles.walletsDialogUl}`}>
                                        <li>
                                            <label className={`${styles.walletsDialogLabel}`}>
                                                ID
                                                <input
                                                    type="checkbox"
                                                    checked={tempVisibleColumns.id}
                                                    disabled={true}
                                                    className={`${styles.walletsDialogCheckbox}`}
                                                />
                                            </label>
                                        </li>
                                        <li>
                                            <label className={`${styles.walletsDialogLabel}`}>
                                                Project
                                                <input
                                                    type="checkbox"
                                                    checked={tempVisibleColumns.api_label}
                                                    onChange={() => toggleColumn('api_label')}
                                                    className={`${styles.walletsDialogCheckbox}`}
                                                />
                                            </label>
                                        </li>
                                        <li>
                                            <label className={`${styles.walletsDialogLabel}`}>
                                                Wallet
                                                <input
                                                    type="checkbox"
                                                    checked={tempVisibleColumns.wallet_label}
                                                    onChange={() => toggleColumn('wallet_label')}
                                                    className={`${styles.walletsDialogCheckbox}`}
                                                />
                                            </label>
                                        </li>
                                        <li>
                                            <label className={`${styles.walletsDialogLabel}`}>
                                                Currency
                                                <input
                                                    type="checkbox"
                                                    checked={tempVisibleColumns.currency}
                                                    onChange={() => toggleColumn('currency')}
                                                    className={`${styles.walletsDialogCheckbox}`}
                                                />
                                            </label>
                                        </li>
                                        <li>
                                            <label className={`${styles.walletsDialogLabel}`}>
                                                Address
                                                <input
                                                    type="checkbox"
                                                    checked={tempVisibleColumns.address}
                                                    onChange={() => toggleColumn('address')}
                                                    className={`${styles.walletsDialogCheckbox}`}
                                                />
                                            </label>
                                        </li>
                                        <li>
                                            <label className={`${styles.walletsDialogLabel}`}>
                                                Created
                                                <input
                                                    type="checkbox"
                                                    checked={tempVisibleColumns.created_at}
                                                    disabled={true}
                                                    className={`${styles.walletsDialogCheckbox}`}
                                                />
                                            </label>
                                        </li>
                                    </ul>
                                    <div className={`${styles.walletsDialogButtons} ${mediaStyles.walletsDialogButtons}`}>
                                        <button className={`${styles.walletsDialogClose} ${mediaStyles.walletsDialogClose}`} onClick={() => settingsDialog.current?.close()}>Close</button>
                                        <button className={`${styles.walletsDialogSave} ${mediaStyles.walletsDialogSave}`} onClick={saveSettings}>Save</button>
                                    </div>
                                </div>
                            </div>
                        </dialog>
                    </div>
                </div>
                <div className={`${styles.walletsSearchWrapper} ${mediaStyles.walletsSearchWrapper}`}>
                    <div className={`${styles.walletsSearch} ${mediaStyles.walletsSearch}`}>
                        <div className={`${styles.walletsSearchInput}`}>
                            <LupaSvg />
                            <input
                                className={`${styles.walletsInput}`}
                                type="text"
                                placeholder='Search hash, api, wallet etc'
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                        </div>
                        <button ref={calendarButtonRef} className={`${styles.walletsСalendar} ${mediaStyles.walletsСalendar}`} onClick={handleCalendarToggle}>
                            <CalendarSvg className={`${styles.walletsСalendarSvg}`} />
                            <>{isAllTime
                                ? ''
                                : (startDate && endDate
                                    ? (format(startDate, 'dd.MM.yy') === format(endDate, 'dd.MM.yy')
                                        ? format(startDate, 'dd.MM.yy')
                                        : `${format(startDate, 'dd.MM.yy')} - ${format(endDate, 'dd.MM.yy')}`)
                                    : `${format(yesterday, 'dd.MM.yy')} - ${format(today, 'dd.MM.yy')}`
                                )
                            }</>
                        </button>
                        {isCalendarVisible && (
                            <div ref={calendarContainerRef} className={`${styles.calendarContainer} ${mediaStyles.calendarContainer}`}>
                                <LocalizedCalendar
                                    selectRange={true}
                                    onChange={handleDateChange}
                                    value={selectedDates}
                                    tileGroupCount={3}
                                    tileDisabled={({ date }) => date > new Date()}
                                    minDetail="year"
                                />
                                <div className={styles.calendarContainerButtons}>
                                    <button
                                        onClick={() => {
                                            setIsAllTime(true);
                                            setStartDate(null);
                                            setEndDate(null);
                                            setSelectedDates([]);
                                            setIsCalendarVisible(false);
                                        }}
                                        className={styles.calendarContainerallTimeButton}
                                    >
                                        {t('calendar.buttons.allTime')}
                                    </button>
                                    <button
                                        onClick={() => {
                                            setIsCalendarVisible(false);
                                        }}
                                        className={styles.calendarContainercloseButton}
                                    >
                                        {t('calendar.buttons.close')}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className={`${styles.walletsPreferences}`}>
                    {hasActiveFilters && (
                        <button className={`${styles.walletsClearButton}`} onClick={handleClearFilters}>
                            <TrashSvg />
                        </button>
                    )}
                    {Object.entries(selectedOptions).map(([filter, options]) =>
                        Array.from(options).map((option) => (
                            <div key={`${filter}-${option}`} className={styles.walletsFilterTag}>
                                {option}
                                <button className={styles.walletsCrossButton} onClick={() => handleRemoveFilter(filter, option)}>
                                    <OstentatiousCrossSvg className={styles.walletsCross} />
                                </button>
                            </div>
                        ))
                    )}

                    <button className={`${styles.walletsPreferencesButton}`} ref={filterButtonRef} onClick={handleFilterToggle}>Add filter +</button>
                    {isFilterDropdownVisible && (
                        <div ref={filterDropdownRef} className={styles.walletsFilterDropdown}>
                            <div className={styles.walletsFilterSearch}>
                                <LupaSvg className={styles.walletsLupa} />
                                <input
                                    className={styles.walletsFilterInput}
                                    type="text"
                                    placeholder="Search filters..."
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                />
                            </div>

                            {searchQuery ? (
                                <ul className={styles.walletsFilterUl}>
                                    {filteredResults.map(({ category, option }) => (
                                        <li key={option} className={styles.walletsFilterItem}>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    checked={selectedOptions[category]?.has(option) || false}
                                                    onChange={() => handleCheckboxChange(category, option)}
                                                />
                                                {option} <span className={styles.filterCategory}>({category})</span>
                                            </label>
                                        </li>
                                    ))}
                                </ul>
                            ) : activeCategory ? (
                                <>
                                    <ul className={styles.walletsFilterUl}>
                                        {filterOptions[activeCategory].map((option) => (
                                            <li key={option}>
                                                <label className={styles.walletsFilterItem}>
                                                    {option}
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedOptions[activeCategory]?.has(option) || false}
                                                        onChange={() => handleCheckboxChange(activeCategory, option)}
                                                        className={`${styles.walletsDialogFilterCheckbox}`}
                                                    />
                                                </label>
                                            </li>
                                        ))}
                                    </ul>
                                    <button className={styles.walletsBackButton} onClick={() => setActiveCategory(null)}>
                                        <ArrowSvg className={styles.walletsBackArrow} />Back
                                    </button>
                                </>
                            ) : (
                                <ul className={styles.walletsFilterUl}>
                                    {Object.keys(filterOptions).map((category) => (
                                        <li key={category} className={styles.walletsFilterItem} onClick={() => handleCategoryClick(category)}>
                                            {category}
                                            <ArrowSvg className={styles.walletsFilterArrow} />
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    )}
                </div>

                <div className={`${styles.walletsBlock} ${mediaStyles.walletsBlock}`}>
                    <div className={`${styles.walletsLine} ${mediaStyles.walletsLine}`}>
                        {visibleColumns.id && <div className={`${styles.walletsText} ${styles.listId}`} onClick={() => handleSort('id')}>ID {sortConfig.key === 'id' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</div>}
                        {visibleColumns.api_label && <div className={`${styles.walletsText} ${styles.listApi}`} onClick={() => handleSort('api_label')}>{i18n.t('project')} {sortConfig.key === 'api_label' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</div>}
                        {visibleColumns.wallet_label && <div className={`${styles.walletsText} ${styles.listWallet}`} onClick={() => handleSort('wallet_label')}>Wallet {sortConfig.key === 'wallet_label' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</div>}
                        {visibleColumns.currency && <div className={`${styles.walletsText} ${styles.listCurrency}`} onClick={() => handleSort('currency')}>Currency {sortConfig.key === 'currency' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</div>}
                        {visibleColumns.address && <div className={`${styles.walletsText} ${styles.listAddress}`} onClick={() => handleSort('address')}>Address {sortConfig.key === 'address' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</div>}
                        {visibleColumns.created_at && <div className={`${styles.walletsText} ${styles.listCreated}`} onClick={() => handleSort('created_at')}>Created {sortConfig.key === 'created_at' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</div>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Wallets;