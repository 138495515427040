import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import zxcvbn from "zxcvbn";
import { useTranslation } from "react-i18next";
import { PiEyeBold, PiEyeClosedBold } from "react-icons/pi";

//@ts-ignore
import styles from "../Auth.module.scss";
//@ts-ignore
import mediaStyles from "../Auth-media.module.scss";

const ChangePassword = () => {
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { email, code } = location.state || {};

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [passwordErrors, setPasswordErrors] = useState<string[]>([]);
    const [passwordChanged, setPasswordChanged] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState(0);

    const passwordStrengthColors = ["#FF0000", "#FF0000", "#FFBF00", "#ffb136", "#00FF2B"];



    const validatePassword = (password: string) => {
        const strength = zxcvbn(password).score;
        setPasswordStrength(strength);

        const errors: string[] = [];
        const latinLettersRegex = /^[A-Za-z0-9!@#$%^&*()_+=-]+$/;

        if (!password.trim()) {
            errors.push(t('createPass')); // Если пустое поле, сразу показываем ошибку
            if (password.length < 8) {
                errors.push(t('2condition'));
            }
            if (!/[A-Z]/.test(password)) {
                errors.push(t('3condition'));
            }
            if (!/\d/.test(password)) {
                errors.push(t('4condition'));
            }
        } else {
            if (!latinLettersRegex.test(password)) {
                errors.push(t('1condition'));
            }
            if (password.length < 8) {
                errors.push(t('2condition'));
            }
            if (!/[A-Z]/.test(password)) {
                errors.push(t('3condition'));
            }
            if (!/\d/.test(password)) {
                errors.push(t('4condition'));
            }
            if (strength < 3 && password.length >= 8 && /[A-Z]/.test(password) && /\d/.test(password)) {
                errors.push(t('5condition'));
            }
        }

        setPasswordErrors(errors);
    };




    const togglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newPassword = event.target.value;
        setPassword(newPassword);
        validatePassword(newPassword);
        setPasswordChanged(true);
    };

    const isFormValid =
        password.trim() &&
        passwordStrength > 2 &&
        password.length >= 8 &&
        /\d/.test(password) &&
        /[A-Z]/.test(password) &&
        passwordErrors.length === 0 &&
        !passwordErrors.includes(t('1condition'));

    useEffect(() => {
        if (passwordChanged) {
            setPasswordChanged(false);
        }
    }, [password]);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setFormSubmitted(true);
        setError("");  // Очистить ошибки перед отправкой

        validatePassword(password);

        if (!isFormValid) return;


        try {
            setLoading(true);  // Включаем состояние загрузки
            // Отправка запроса на сервер для обновления пароля
            const response = await fetch("/api/set-new-password", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email, code, newPassword: password })
            });

            // Проверка ответа от сервера
            if (!response.ok) throw new Error("Failed to update password");

            // Переход на страницу успешного обновления пароля
            navigate("/password-reset-success");

        } catch (error) {
            // В случае ошибки при запросе
            setError(t("passwordResetError"));
        } finally {
            setLoading(false);  // Выключаем состояние загрузки
        }
    };
    useEffect(() => {
        if (passwordErrors.length > 0) {
            validatePassword(password);
        }

    }, [i18n.language]);
    return (
        <div className={`${styles.auth} ${mediaStyles.auth}`}>
            <div className={`${styles.authContainer} ${mediaStyles.authContainer}`}>
                <header className={`${styles.authHeader} ${mediaStyles.authHeader}`}>
                    <h2 className={`${styles.authTitle} ${mediaStyles.authTitle}`}>{t("newPassword")}</h2>
                </header>
                <main className={`${styles.authMain} ${mediaStyles.authMain}`}>
                    <form className={`${styles.authForm} ${mediaStyles.authForm}`} onSubmit={handleSubmit} noValidate>
                        <div style={{ position: "relative" }}>
                            {formSubmitted && password === "" && (
                                <span className={styles.tooltip}>{t('createPass')}</span>
                            )}
                            {passwordErrors.length > 0 && passwordErrors[0] === t('1condition') && password !== "" && (
                                <span className={styles.tooltip}>{passwordErrors[0]}</span>
                            )}

                            {passwordErrors.includes(t('5condition')) && (
                                <span className={styles.tooltip}>{t('5condition')}</span>
                            )}


                            <div className={`${styles.authWrapper} ${mediaStyles.authWrapper}`}>
                                <input
                                    className={`${styles.authInputPass} ${mediaStyles.authInputPass} ${passwordErrors.length > 0 ? styles.invalid : ""}`}
                                    type={showPassword ? "text" : "password"}
                                    value={password}
                                    onChange={handlePasswordChange}
                                    placeholder="Пароль"
                                />
                                <button
                                    type="button"
                                    onClick={togglePasswordVisibility}
                                    className={`${styles.authEye} ${mediaStyles.authEye} ${passwordErrors.length > 0 ? styles.invalid : ""}`}
                                >
                                    {showPassword ? <PiEyeBold size={20} /> : <PiEyeClosedBold size={20} />}
                                </button>
                            </div>

                            <div className={`${styles.authStatus} ${mediaStyles.authStatus}`}>
                                {[...Array(4)].map((_, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            width: "100%",
                                            height: "3px",
                                            backgroundColor: index < passwordStrength ? passwordStrengthColors[passwordStrength] : "#333333",
                                            borderRadius: "3px",
                                        }}
                                    />
                                ))}
                            </div>

                            {passwordErrors.length >= 0 && (
                                <div className={`${styles.authErrors} ${mediaStyles.authErrors}`}>
                                    {passwordErrors
                                        .filter((error) => error !== t('1condition'))
                                        .filter(error => error !== t('5condition'))
                                        .filter(error => error !== t('createPass'))
                                        .map((error, index) => (
                                            <p key={index}>{error}</p>
                                        ))}
                                </div>
                            )}


                        </div>
                        <button
                            className={`${styles.authButton} ${mediaStyles.authButton} ${isFormValid ? styles.valid : styles.invalid}`}
                            type="submit"
                        >
                            {loading ? t("updating") : t("continue")}
                        </button>
                    </form>
                </main>
            </div>
        </div>
    );
};

export default ChangePassword;
