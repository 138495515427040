import React, { useState, useEffect } from 'react';
import TopupItem from './TopupItem.tsx';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import styles from './Topups.module.scss';
// @ts-ignore
import mediaStyles from './Topups-media.module.scss';
import { apiRequest } from "../../../../data/Api.ts";

const Topups: React.FC = () => {
    const [data, setData] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const { t } = useTranslation();

    useEffect(() => {
        const fetchData = async () => {
            const response = await apiRequest(`/pay/user/dashboard/topup_cards`, 'GET');
            setData(response);
            setLoading(false);
        };
        fetchData();
    }, []);

    const getArrow = (percent: number) => {
        if (percent > 0) {
            return '↑';
        } else if (percent < 0) {
            return '↓';
        } else {
            return '';
        }
    };

    const formatPercent = (percent: number) => {
        return Math.abs(percent);
    };

    const getPercentClass = (percent: number) => {
        return percent >= 0 ? `${styles.positive}` : `${styles.negative}`;
    };

    const titles = {
        day: t('dayTopups'),
        week: t('weekTopups'),
        month: t('monthTopups'),
        all_time: t('allTopups'),
    };

    if (loading) {
        return <div>Загрузка...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div className={`${styles.topups} ${mediaStyles.topups}`}>
            {Object.keys(titles).map((key) => {
                const percentValue = data[`${key}_percent`];
                const arrow = getArrow(percentValue);
                const percentClass = getPercentClass(percentValue);

                // Для карточки all_time передаем null вместо пустой строки
                const percentText = key === 'all_time' ? null : `${formatPercent(percentValue)}% ${arrow}`;

                // Добавляем класс для карточки all_time
                const itemClass = key === 'all_time' ? styles.alltime : '';

                return (
                    <TopupItem
                        key={key}
                        id={key}
                        title={titles[key]}
                        amount={`$ ${data[key]}`}
                        percent={percentText}
                        percentClass={percentClass}
                        itemClass={itemClass}
                    />
                );
            })}
        </div>
    );
};

export default Topups;
