import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ExclamationSvg } from "../../../assets/svg-files/SvgIcons.ts";
//@ts-ignore
import styles from "../Auth.module.scss";
//@ts-ignore
import mediaStyles from "../Auth-media.module.scss";

const ConfirmCode = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const email = location.state?.email || "";
    const [code, setCode] = useState("");
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [timer, setTimer] = useState(60);
    const [canResend, setCanResend] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);

    // Таймер обратного отсчета
    useEffect(() => {
        if (timer > 0) {
            const interval = setInterval(() => {
                setTimer((prev) => prev - 1);
            }, 1000);
            return () => clearInterval(interval);
        } else {
            setCanResend(true); // Разрешаем повторную отправку кода
        }
    }, [timer]);

    const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newCode = event.target.value.replace(/\D/g, ""); // Разрешаем только цифры
        setCode(newCode);
        if (newCode.length === 6) setError(false); // Очищаем ошибку, если код стал 6-значным
    };

    const isFormValid =
        code.length == 6 &&
        isChecked;

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setFormSubmitted(true);
        setError(false);

        if (!isChecked) {
            setShowError(true);
        } else {
            setShowError(false);
        }

        if (!isFormValid) return;

        try {
            setLoading(true);
            const response = await fetch("/api/verify-reset-code", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email, code })
            });

            if (!response.ok) throw new Error("Invalid code");

            navigate("/set-new-password", { state: { email, code } });
        } catch (error) {
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    const handleResendCode = async () => {
        setCanResend(false);
        setTimer(60); // Сброс таймера до 60 секунд

        try {
            const response = await fetch("/api/resend-reset-code", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email })
            });

            if (!response.ok) throw new Error("Error resending code");
        } catch (error) {
            alert('Что-то пошло не так'); // Можно добавить текст ошибки
        }
    };

    return (
        <div className={`${styles.auth} ${mediaStyles.auth}`}>
            <div className={`${styles.authContainer} ${mediaStyles.authContainer}`}>
                <header className={`${styles.authHeader} ${mediaStyles.authHeader}`}>
                    <h2 className={`${styles.authTitle} ${mediaStyles.authTitle}`}>{t("confirm")}</h2>
                </header>
                <main className={`${styles.authMain} ${mediaStyles.authMain}`}>
                    <form className={`${styles.authForm} ${mediaStyles.authForm}`} onSubmit={handleSubmit} noValidate>
                        <div className={`${styles.authInfo} ${mediaStyles.authInfo}`}>
                            <ExclamationSvg style={{ minWidth: "26px", minHeight: "26px" }} className={styles.authExclamationSvg} />
                            <p className={styles.authInfoText}>{t("infoSent")}<span style={{ fontWeight: 700 }}>{t("code")}</span>{t("infoSent2")}</p>
                        </div>

                        <div className={styles.inputWrapper}>
                            {formSubmitted && code.length === 0 && <span className={styles.tooltip}>{t('enterCode')}</span>}
                            {formSubmitted && code.length > 0 && code.length < 6 && <span className={styles.tooltip}>{t('incompleteCode')}</span>}
                            {error && <span className={styles.tooltip}>{t("invalidCode")}</span>}
                            <input
                                className={`${styles.authInput} ${mediaStyles.authInput} ${formSubmitted && code.length < 6 ? styles.invalid : ""}`}
                                type="text"
                                placeholder={t("enterCode")}
                                value={code}
                                onChange={handleCodeChange}
                                maxLength={6}
                                disabled={loading}
                            />
                        </div>
                        <div className={`${styles.authExplanation} ${mediaStyles.authExplanation}`}>
                            <ExclamationSvg style={{ minWidth: "26px", minHeight: "26px" }} />
                            <p className={styles.authExplanationText}>{t("trustedDevice")}</p>
                        </div>

                        {/* Блок таймера и повторного запроса */}
                        <div className={styles.resendCodeWrapper}>
                            {canResend ? (
                                <button
                                    className={styles.resendButton}
                                    type="button"
                                    onClick={handleResendCode}>
                                    {t("repeatСode")}
                                </button>
                            ) : (
                                <p className={styles.resendTimer}>
                                    {t("repeatedСode")}{timer}{t("sec")}
                                </p>
                            )}
                        </div>

                        {/* Чекбокс */}
                        <div className={styles.checkboxWrapper}>
                            {showError && !isChecked && <span className={styles.tooltip}>{t('required')}</span>}
                            <label className={styles.checkboxContainer}>
                                <input
                                    type="checkbox"
                                    checked={isChecked}
                                    onChange={() => setIsChecked(!isChecked)}
                                />
                                <span className={styles.customCheckbox}></span>
                                <span className={styles.textCheckbox}>
                                    {t('trustedDevice')}
                                </span>
                            </label>
                        </div>

                        <button
                            className={`${styles.authButton} ${mediaStyles.authButton} ${isFormValid ? styles.valid : styles.invalid}`}
                            type="submit"
                        >
                            {loading ? t("confirm") : t("continue")}
                        </button>
                    </form>
                </main>
                <footer className={`${styles.authFooter} ${mediaStyles.authFooter}`}>
                    <p className={`${styles.authText} ${mediaStyles.authText}`}>
                        <Link className={`${styles.authLink} ${mediaStyles.authLink}`} style={{ textDecoration: "none" }} to="/Authorization/ResetPassword">
                            {t("back")}
                        </Link>
                    </p>
                </footer>
            </div>
        </div>
    );
};

export default ConfirmCode;
