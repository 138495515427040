import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Calendar from 'react-calendar';
import Select from 'react-select';
import { format } from 'date-fns';
import { convertedBalance } from '../../data/data.ts';
import { apiRequest, getCurrencies, Currency } from "../../data/Api.ts";
import { TwoFactorInput, ExportModal } from '../Pages.ts';
import { LupaSvg, CrossSvg, ArrowSvg, BalanceSvg, BtcSvg, CalendarSvg, EthSvg, ExportSvg, OstentatiousCrossSvg, SettingsSvg, SolanaSvg, TrcSvg, WithdrawSvg, TrashSvg } from '../../assets/svg-files/SvgIcons.ts';
import LocalizedCalendar from '../../components/common/LocalizedCalendar.tsx';
// @ts-ignore
import styles from './Balance.module.scss';
// @ts-ignore
import mediaStyles from './Balance-media.module.scss';

interface Balance {
    id: number;
    tx_hash: string;
    api_label: string;
    wallet_label: string;
    amount: number;
    amount_usd: number;
    token: string;
    chain: string;
    is_paid: boolean;
    is_postback_sended: boolean;
    created_at: string;
}

interface NewCurrency {
    address: string;
    label: string;
    currency: string;
    addressesList: string;
    amount: string;
}

// Интерфейс для транзакций из API
interface Transaction {
    id: number;
    tx_type: string;
    api_label: string;
    label: string;
    currency: {
        name: string;
        token: string;
        network: string;
        contract: string;
        decimals: number;
        confirmations: number;
        network_fee: number;
        min_deposit: number;
    };
    amount: number;
    amount_usd: number;
    network_fee: number;
    network_fee_usd: number;
    service_fee: number;
    service_fee_usd: number;
    tx_hash: string;
    is_paid: boolean;
    is_postback_sended: boolean;
    created_at: string;
}

// Интерфейс для инвойсов из API
interface Invoice {
    id: number;
    uuid: string;
    amount: number;
    amount_usd: number;
    recieved_amount: number;
    recieved_amount_usd: number;
    api_label: string;
    label: string;
    postback_url: string;
    success_url: string;
    currency: {
        name: string;
        token: string;
        network: string;
        contract: string;
        decimals: number;
        confirmations: number;
        network_fee: number;
        min_deposit: number;
    };
    address: string;
    confirmations: number;
    required_confirmations: number;
    is_paid: boolean;
    is_expired: boolean;
    is_postback_sended: boolean;
    created_at: string;
    expires_at: string;
}

// Добавляем интерфейс для данных invoice-cards
interface InvoiceCardStats {
    all: { count: number; sum: number };
    unpaid: { count: number; sum: number };
    paid: { count: number; sum: number };
    partial: { count: number; sum: number };
}

const filterOptions = {
    Currency: [] as string[],
    Project: ['Project A', 'Project B', 'Project C'],
    Type: ['Paid', 'Unpaid', 'Pending', 'Expired', 'Sent', 'Not sent'],
    Other: ['Option 1', 'Option 2', 'Option 3'],
};

const EXAMPLE_TEXT = `0x71C7656EC7ab88b098defB751B7401B5f6d8976F, 100.50
TKekjetSdJJRD1tSvxBGZmsQS5QvYnKyya, 75
0x0d8775f648430679a709e98d2b0cb6250d2887ef, 200`;

const Balance: React.FC = () => {
    const { i18n, t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [balance, setBalance] = useState<string>('0');
    const [transactions, setTransactions] = useState<Transaction[]>([]);
    const [balances, setBalances] = useState<{ [key: string]: number }>({});
    const [totalUsdtBalance, setTotalUsdtBalance] = useState<number>(0);
    const [invoices, setInvoices] = useState<Invoice[]>([]);
    const [currencyOptions, setCurrencyOptions] = useState<{ value: string; label: string }[]>([]);
    const [isLoadingCurrencies, setIsLoadingCurrencies] = useState(false);
    const getYesterday = (): Date => {
        const today = new Date();
        today.setDate(today.getDate() - 1);
        return today;
    };
    const [newCurrency, setNewCurrency] = useState<NewCurrency>({
        address: '',
        label: '',
        currency: '',
        addressesList: '',
        amount: '',
    });

    // Состояние для хранения данных invoice-cards
    const [invoiceCardStats, setInvoiceCardStats] = useState<InvoiceCardStats>({
        all: { count: 0, sum: 0 },
        unpaid: { count: 0, sum: 0 },
        paid: { count: 0, sum: 0 },
        partial: { count: 0, sum: 0 }
    });

    // Переместил функцию parseWithdrawalData перед её использованием
    // Функция для обработки данных из textarea и преобразования их в формат для отправки
    const parseWithdrawalData = (): { currency: string; addresses_and_amounts: [string, number][] } | [] => {
        if (!newCurrency.addressesList || !newCurrency.currency) {
            return [];
        }

        // Разбиваем текст по строкам
        const lines = newCurrency.addressesList.split('\n').filter(line => line.trim() !== '');

        // Обрабатываем каждую строку
        const result = lines.map(line => {
            // Разделяем адрес и сумму по запятой
            const parts = line.split(',');
            if (parts.length < 2) return null;

            const address = parts[0].trim();
            const amountStr = parts[1].trim();

            // Пытаемся преобразовать сумму в число
            const amount = parseFloat(amountStr);

            // Проверяем корректность данных
            if (!address || isNaN(amount)) {
                return null;
            }

            // Возвращаем массив [адрес, сумма]
            return [address, amount] as [string, number];
        }).filter((item): item is [string, number] => item !== null);

        if (result.length === 0) {
            return [];
        }

        // Возвращаем объект с выбранной валютой и массивом данных
        return {
            currency: newCurrency.currency,
            addresses_and_amounts: result
        };
    };

    const handleCurrencyChange = (selectedOption) => {
        setNewCurrency({ ...newCurrency, currency: selectedOption.value });
    };
    const totalBalance = Object.values(convertedBalance).reduce((acc, val) => acc + val, 0);
    const currencyIcons = {
        USDTBEP20: BtcSvg,
        USDTERC20: EthSvg,
        USDTTRC20: TrcSvg
    };

    // Обновляем формирование элементов для отображения статистики на основе данных из API
    const invoiceItems = [
        { label: 'Created invoicdes', value: invoiceCardStats.all.sum },
        { label: 'Paid invoices', value: invoiceCardStats.paid.sum, percent: invoiceCardStats.all.sum ? Math.round((invoiceCardStats.paid.sum / invoiceCardStats.all.sum) * 100) : 0 },
        { label: 'Partially paid', value: invoiceCardStats.partial.sum, percent: invoiceCardStats.all.sum ? Math.round((invoiceCardStats.partial.sum / invoiceCardStats.all.sum) * 100) : 0 },
        { label: 'Not paid', value: invoiceCardStats.unpaid.sum, percent: invoiceCardStats.all.sum ? Math.round((invoiceCardStats.unpaid.sum / invoiceCardStats.all.sum) * 100) : 0 }
    ];

    const yesterday = getYesterday();
    const today = new Date();

    const [startDate, setStartDate] = useState<Date | null>(yesterday);
    const [endDate, setEndDate] = useState<Date | null>(today);
    const [isAllTime, setIsAllTime] = useState(false);
    const [isCalendarVisible, setIsCalendarVisible] = useState(false);
    const calendarContainerRef = useRef<HTMLDivElement | null>(null);
    const calendarButtonRef = useRef<HTMLButtonElement | null>(null);
    const [selectedDates, setSelectedDates] = useState<Date[]>([yesterday, today]);
    const [isFilterDropdownVisible, setIsFilterDropdownVisible] = useState(false);
    const filterDropdownRef = useRef<HTMLDivElement | null>(null);
    const filterButtonRef = useRef<HTMLButtonElement | null>(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedOptions, setSelectedOptions] = useState<{ [key: string]: Set<string> }>({});
    const [activeCategory, setActiveCategory] = useState<string | null>(null);
    const hasActiveFilters = Object.values(selectedOptions).some((options) => options.size > 0);
    const [step, setStep] = useState(1);
    const [code, setCode] = useState("");


    const settingsDialog = useRef<HTMLDialogElement>(null);
    const downloadsDialog = useRef<HTMLDialogElement>(null);
    const dialogWithdrawal = useRef<HTMLDialogElement>(null);
    const dialogMultipleWithdrawal = useRef<HTMLDialogElement>(null);

    const handleBackdropClick = (e: React.MouseEvent) => {
        if (e.target === e.currentTarget) {
            closeDialog();
        }

    };
    const closeDialog = () => {
        if (dialogMultipleWithdrawal.current) {
            dialogMultipleWithdrawal.current.close();
            setStep(1);
        }
        if (dialogWithdrawal.current) {
            dialogWithdrawal.current.close();
            setStep(1);
        }
        if (settingsDialog.current) {
            settingsDialog.current.close();
        }
    };
    interface VisibleColumns {
        id: boolean;
        tx_hash: boolean;
        api_label: boolean;
        label: boolean;
        amount: boolean;
        amount_usd: boolean;
        currency: boolean;
        network_fee: boolean;
        service_fee: boolean;
        is_paid: boolean;
        is_postback_sended: boolean;
        created_at: boolean;
    }
    const [visibleColumns, setVisibleColumns] = useState<VisibleColumns>({
        id: true,
        tx_hash: true,
        api_label: true,
        label: true,
        amount: true,
        amount_usd: true,
        currency: true,
        network_fee: true,
        service_fee: true,
        is_paid: true,
        is_postback_sended: true,
        created_at: true,
    });
    const [tempVisibleColumns, setTempVisibleColumns] = useState<VisibleColumns>(visibleColumns);

    useEffect(() => {
        const savedSettings = localStorage.getItem("visibleColumns");
        if (savedSettings) {
            const savedColumns = JSON.parse(savedSettings);
            setVisibleColumns(savedColumns);
            setTempVisibleColumns(savedColumns); // Инициализируем tempVisibleColumns значениями из localStorage
        }
    }, []);

    const handleFormSubmit = () => {
        // Получаем данные для отправки
        const withdrawalData = parseWithdrawalData();

        // Если нет данных для отправки или это пустой массив
        if (Array.isArray(withdrawalData) && withdrawalData.length === 0) {
            alert('Нет данных для отправки');
            return;
        }

        // Устанавливаем состояние загрузки
        setIsLoading(true);

        // Выполняем запрос к API в стиле Topups.tsx
        const sendData = async () => {
            try {
                // Если withdrawalData - это массив, то передаем пустой объект
                // В противном случае передаем данные как есть
                const requestData = {
                    currency: !Array.isArray(withdrawalData) ? withdrawalData.currency : '',
                    addresses_and_amounts: !Array.isArray(withdrawalData) ? withdrawalData.addresses_and_amounts : [],
                    code: code
                };

                const response = await apiRequest('/pay/user/balance/multiple-withdraw', 'POST', requestData);

                // Закрываем модальное окно и сбрасываем форму
                closeDialog();
                setNewCurrency({
                    address: '',
                    label: '',
                    currency: '',
                    addressesList: '',
                    amount: '',
                });
                setCode("");

            } catch (error) {
                // Обрабатываем ошибку
                alert('Произошла ошибка при отправке запроса');
            } finally {
                // Сбрасываем состояние загрузки
                setIsLoading(false);
            }
        };

        // Вызываем функцию отправки
        sendData();
    };
    const goToStep2 = () => {
        setStep(2);

    };
    const handleClearFilters = () => {
        setSelectedOptions({});
    };
    const toggleColumn = (column: string) => {
        if (column === "id" || column === "created_at") {
            return;
        }

        setTempVisibleColumns((prev: VisibleColumns) => ({
            ...prev,
            [column]: !prev[column],
        }));
    };

    const toggleAllColumns = () => {
        const areAllSelected = Object.keys(tempVisibleColumns)
            .filter((key) => key !== "id" && key !== "created_at")
            .every((key) => tempVisibleColumns[key]);

        setTempVisibleColumns((prev: VisibleColumns) => {
            const newVisibleColumns: VisibleColumns = {
                id: true,
                tx_hash: !areAllSelected,
                api_label: !areAllSelected,
                label: !areAllSelected,
                amount: !areAllSelected,
                amount_usd: !areAllSelected,
                currency: !areAllSelected,
                network_fee: !areAllSelected,
                service_fee: !areAllSelected,
                is_paid: !areAllSelected,
                is_postback_sended: !areAllSelected,
                created_at: true,
            };
            return newVisibleColumns;
        });
    };
    const saveSettings = () => {
        setVisibleColumns(tempVisibleColumns);

        localStorage.setItem("visibleColumns", JSON.stringify(tempVisibleColumns));
        if (settingsDialog.current) {
            settingsDialog.current.close();
        }
    };



    const handleFilterToggle = () => {
        setIsFilterDropdownVisible(!isFilterDropdownVisible);
        setSearchQuery('');
        setActiveCategory(null);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    const handleCategoryClick = (category: string) => {
        setActiveCategory(category);
        setSearchQuery('');
    };

    const handleCheckboxChange = (category: string, option: string) => {
        setSelectedOptions((prev) => {
            const updatedSet = new Set(prev[category] || []);
            if (updatedSet.has(option)) {
                updatedSet.delete(option);
            } else {
                updatedSet.add(option);
            }
            return { ...prev, [category]: updatedSet };
        });
    };

    const handleRemoveFilter = (category: string, option: string) => {
        setSelectedOptions((prev) => {
            const updatedSet = new Set(prev[category] || []);
            updatedSet.delete(option);
            return { ...prev, [category]: updatedSet };
        });
    };

    const filteredResults = Object.entries(filterOptions).flatMap(([category, options]) =>
        options.filter((option) => option.toLowerCase().includes(searchQuery.toLowerCase()))
            .map((option) => ({ category, option }))
    );

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                // Получаем баланс
                const response = await apiRequest('/pay/user/balance/', 'GET');
                if (response) {
                    setBalances(response);

                    if (response.usdt !== undefined) {
                        setBalance(response.usdt.toString());
                    } else {
                        const total = Object.values(response).reduce((sum: number, val) => {
                            return sum + (typeof val === 'number' ? val : 0);
                        }, 0);
                        setBalance(total.toString());
                        setTotalUsdtBalance(total);
                    }
                }

                // Получаем инвойсы для расчета статистики
                try {
                    const invoicesResponse = await apiRequest('/pay/user/invoices/', 'GET');
                    console.log('Полученные данные инвойсов:', invoicesResponse);

                    if (invoicesResponse && Array.isArray(invoicesResponse)) {
                        setInvoices(invoicesResponse);
                    } else if (invoicesResponse && typeof invoicesResponse === 'object') {
                        // Если вернулся один объект, помещаем его в массив
                        setInvoices([invoicesResponse]);
                    } else {
                        setInvoices([]);
                    }
                } catch (error) {
                    setInvoices([]);
                }

                // Получаем данные статистики invoice-cards
                try {
                    const invoiceCardsResponse = await apiRequest('/pay/user/balance/invoice-cards', 'GET');
                    console.log('Данные invoice-cards:', invoiceCardsResponse);

                    if (invoiceCardsResponse && typeof invoiceCardsResponse === 'object') {
                        setInvoiceCardStats(invoiceCardsResponse);
                    }
                } catch (error) {
                    console.error('Ошибка при получении данных invoice-cards:', error);
                }

                // Получаем транзакции для таблицы
                try {
                    const transactionsResponse = await apiRequest('/pay/user/balance/transactions', 'GET');

                    if (transactionsResponse) {
                        if (Array.isArray(transactionsResponse)) {
                            setTransactions(transactionsResponse);
                        } else if (transactionsResponse.transactions && Array.isArray(transactionsResponse.transactions)) {
                            setTransactions(transactionsResponse.transactions);
                        } else if (typeof transactionsResponse === 'object') {
                            setTransactions([transactionsResponse]);
                        } else {
                            setTransactions([]);
                        }
                    } else {
                        setTransactions([]);
                    }
                } catch (error) {
                    setTransactions([]);
                }
            } catch (error) {
                // Произошла ошибка при загрузке данных
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleClickOutside = (event) => {
        if (
            filterDropdownRef.current &&
            !filterDropdownRef.current.contains(event.target as Node) &&
            filterButtonRef.current &&
            !filterButtonRef.current.contains(event.target as Node)
        ) {
            setIsFilterDropdownVisible(false);
        }
        if (calendarContainerRef.current &&
            !calendarContainerRef.current.contains(event.target as Node) &&
            calendarButtonRef.current &&
            !calendarButtonRef.current.contains(event.target as Node)
        ) {
            setIsCalendarVisible(false);
        }

    };

    const handleCalendarToggle = () => {
        setIsCalendarVisible(!isCalendarVisible);
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);


        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleDateChange = (dates: Date[]) => {
        if (dates.length === 1) {
            setSelectedDates([dates[0]]);
            setStartDate(dates[0]);
            setEndDate(null);
            setIsAllTime(false);
        } else if (dates.length === 2) {
            setSelectedDates(dates);
            setStartDate(dates[0]);
            setEndDate(dates[1]);
            setIsAllTime(false);
        }
    };

    const [lineCharCount, setLineCharCount] = useState<number[]>([0]);

    const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;

        // Ограничение в 128 символов на каждую строку
        const lines = value.split('\n');
        const limitedLines = lines.map(line => {
            return line.length > 128 ? line.slice(0, 128) : line;
        });

        // Обновляем счетчик символов для каждой строки
        setLineCharCount(limitedLines.map(line => line.length));

        const limitedValue = limitedLines.join('\n');

        // Если введенное значение не изменилось после ограничения, устанавливаем его
        // Иначе устанавливаем ограниченное значение и позиционируем курсор
        if (limitedValue === value) {
            setNewCurrency({ ...newCurrency, addressesList: value });
        } else {
            setNewCurrency({ ...newCurrency, addressesList: limitedValue });

            // Устанавливаем позицию курсора после ограничения
            setTimeout(() => {
                if (textareaRef.current) {
                    const cursorPosition = e.target.selectionStart;
                    const cursorLine = value.substring(0, cursorPosition).split('\n').length - 1;
                    const prevLinesLength = lines.slice(0, cursorLine).reduce((acc, line) => acc + line.length + 1, 0);
                    const currentLineLength = Math.min(lines[cursorLine]?.length || 0, 128);

                    textareaRef.current.selectionStart = textareaRef.current.selectionEnd =
                        prevLinesLength + currentLineLength;
                }
            }, 0);
        }

        // Всегда добавляем хотя бы одну строку
        const textLines = limitedValue.split('\n');
        if (textLines.length === 0) {
            setNewCurrency(prev => ({ ...prev, addressesList: ' ' }));
        }

        // Автоматическое изменение высоты textarea
        setTimeout(() => {
            if (textareaRef.current) {
                textareaRef.current.style.height = 'auto';
                textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
            }
        }, 0);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        // Обработка нажатия Tab
        if (e.key === 'Tab') {
            e.preventDefault();
            const target = e.target as HTMLTextAreaElement;
            const value = target.value;
            const selectionStart = target.selectionStart;
            const selectionEnd = target.selectionEnd;

            // Вставляем табуляцию
            const newValue = value.substring(0, selectionStart) + '    ' + value.substring(selectionEnd);
            setNewCurrency({ ...newCurrency, addressesList: newValue });

            // Устанавливаем курсор после вставленной табуляции
            setTimeout(() => {
                target.selectionStart = target.selectionEnd = selectionStart + 4;
            }, 0);
        }

        // Запрещаем добавление символов, если достигнут лимит
        const target = e.target as HTMLTextAreaElement;
        const value = target.value;
        const selectionStart = target.selectionStart;
        const currentLineStart = value.substring(0, selectionStart).lastIndexOf('\n') + 1;
        const currentLineEnd = value.indexOf('\n', selectionStart);
        const currentLine = value.substring(
            currentLineStart,
            currentLineEnd === -1 ? value.length : currentLineEnd
        );

        // Если текущая строка достигла лимита и это не клавиша навигации или удаления
        if (currentLine.length >= 128 &&
            !['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Backspace', 'Delete', 'Tab', 'Enter'].includes(e.key) &&
            !e.ctrlKey && !e.metaKey && !e.altKey && e.key.length === 1) {
            e.preventDefault();
        }
    };

    const textareaRef = useRef<HTMLTextAreaElement | null>(null);

    useEffect(() => {
        const handleDialogOpen = () => {
            if (dialogMultipleWithdrawal.current?.open) {
                setTimeout(() => {
                    if (textareaRef.current) {
                        textareaRef.current.style.height = 'auto';
                        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;

                        // Если текстовое поле пустое, добавляем хотя бы одну строку
                        if (!newCurrency.addressesList) {
                            setNewCurrency(prev => ({ ...prev, addressesList: '' }));
                        }
                    }
                }, 0);
            }
        };

        dialogMultipleWithdrawal.current?.addEventListener('open', handleDialogOpen);

        return () => {
            dialogMultipleWithdrawal.current?.removeEventListener('open', handleDialogOpen);
        };
    }, [dialogMultipleWithdrawal.current, newCurrency.addressesList]);

    const filteredTransactions = transactions.filter((transaction) => {
        const createdAt = new Date(transaction.created_at);

        // Фильтрация по диапазону дат
        let dateMatch = false;
        if (startDate && endDate) {
            dateMatch = createdAt >= startDate && createdAt <= endDate;
        } else if (startDate) {
            const endOfDay = new Date(startDate);
            endOfDay.setHours(23, 59, 59, 999);
            dateMatch = createdAt >= startDate && createdAt <= endOfDay;
        } else {
            dateMatch = true;
        }

        // Фильтрация по текстовому поиску в tx_hash, label, api_label
        const textMatch = [transaction.tx_hash, transaction.label, transaction.api_label].some((field) =>
            field.toLowerCase().includes(searchQuery.toLowerCase())
        );

        // Фильтрация по выбранным опциям
        const matchesFilters = Object.entries(selectedOptions).every(([category, selectedValues]) => {
            if (selectedValues.size === 0) return true; // Если в категории нет выбранных значений, пропускаем фильтрацию

            switch (category) {
                case 'Currency':
                    return selectedValues.has(transaction.currency.name);
                case 'Project':
                    return selectedValues.has(transaction.api_label);
                case 'Type':
                    if (selectedValues.has('Paid') && transaction.is_paid) return true;
                    if (selectedValues.has('Unpaid') && !transaction.is_paid) return true;
                    if (selectedValues.has('Pending') && transaction.is_paid === false) return true;
                    if (selectedValues.has('Expired')) return true; // Дополните логику, если есть критерии
                    if (selectedValues.has('Sent') && transaction.is_postback_sended) return true;
                    if (selectedValues.has('Not sent') && !transaction.is_postback_sended) return true;
                    return false;
                case 'Other':
                    return selectedValues.has('Option 1'); // Тут добавьте правильное соответствие
                default:
                    return true;
            }
        });

        return dateMatch && matchesFilters && textMatch;  // Добавляем проверку на совпадение с текстом
    });


    const [sortConfig, setSortConfig] = useState<{ key: string | null; direction: 'asc' | 'desc' }>({
        key: 'created_at',
        direction: 'desc',
    });

    const handleSort = (key: string) => {
        setSortConfig((prev) => ({
            key,
            direction: prev.key === key && prev.direction === 'desc' ? 'asc' : 'desc',
        }));
    };

    const sortedTransactions = [...filteredTransactions].sort((a, b) => {
        if (!sortConfig.key) return 0;
        const { key, direction } = sortConfig;

        // Специальная обработка для поля currency, которое теперь объект
        if (key === 'currency') {
            const aValue = a.currency.name;
            const bValue = b.currency.name;
            if (aValue < bValue) return direction === 'asc' ? -1 : 1;
            if (aValue > bValue) return direction === 'asc' ? 1 : -1;
            return 0;
        }

        let aValue = a[key];
        let bValue = b[key];

        if (!isNaN(Number(aValue)) && !isNaN(Number(bValue))) {
            aValue = Number(aValue);
            bValue = Number(bValue);
        }

        if (typeof aValue === 'boolean' && typeof bValue === 'boolean') {
            return direction === 'asc' ? Number(aValue) - Number(bValue) : Number(bValue) - Number(aValue);
        }
        if (aValue < bValue) return direction === 'asc' ? -1 : 1;
        if (aValue > bValue) return direction === 'asc' ? 1 : -1;
        return 0;
    });

    const handleExportToEmail = () => {
        // Логика экспорта на почту
    };
    const handleExportToExcel = () => {
        // Логика скачивания данных в формате Excel
    };

    const openMultipleWithdrawalDialog = () => {
        // Если текстовое поле пустое, инициализируем его с одной строкой
        if (!newCurrency.addressesList) {
            setNewCurrency(prev => ({ ...prev, addressesList: '' }));
        }
        dialogMultipleWithdrawal.current?.showModal();
    };

    // Функция для вставки примера
    const insertExample = () => {
        setNewCurrency({ ...newCurrency, addressesList: EXAMPLE_TEXT });

        // Устанавливаем высоту textarea после вставки примера
        setTimeout(() => {
            if (textareaRef.current) {
                textareaRef.current.style.height = 'auto';
                textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
            }
        }, 0);

        // Обновляем счетчик символов для каждой строки
        setLineCharCount(EXAMPLE_TEXT.split('\n').map(line => line.length));
    };

    // Обработчик клика по textarea
    const handleTextareaClick = () => {
        // Если в поле содержится только пример, очистим его
        if (newCurrency.addressesList === EXAMPLE_TEXT) {
            setNewCurrency({ ...newCurrency, addressesList: '' });
            setLineCharCount([0]);

            // Устанавливаем фокус на текстовой области
            setTimeout(() => {
                if (textareaRef.current) {
                    textareaRef.current.focus();
                }
            }, 0);
        }
    };

    const handleWithdrawalSubmit = () => {
        // Проверка введенных данных
        if (!newCurrency.currency || !newCurrency.address || !newCurrency.amount) {
            alert('Заполните все поля формы');
            return;
        }

        // Устанавливаем состояние загрузки
        setIsLoading(true);

        // Подготавливаем данные для отправки в формате {currency: string, address: string, amount: number}
        const requestData = {
            currency: newCurrency.currency,
            address: newCurrency.address,
            amount: parseFloat(newCurrency.amount)
        };

        // Отправка данных
        const sendData = async () => {
            try {
                const response = await apiRequest('/pay/user/balance/withdraw', 'POST', requestData);

                // Показываем сообщение об успешной отправке
                alert('Заявка на вывод успешно отправлена');

                // Закрываем модальное окно и сбрасываем форму
                closeDialog();
                setNewCurrency({
                    address: '',
                    label: '',
                    currency: '',
                    addressesList: '',
                    amount: ''
                });
            } catch (error) {
                // Обрабатываем ошибку
                alert('Произошла ошибка при отправке запроса');
            } finally {
                // Сбрасываем состояние загрузки
                setIsLoading(false);
            }
        };
        // Вызываем функцию отправки
        sendData();
    };

    // Обновленная функция для отображения иконок валют
    const getCurrencyIcon = (currencyName: string) => {
        const icons = {
            USDTBEP20: BtcSvg,
            USDTERC20: EthSvg,
            USDTTRC20: TrcSvg,
            ETH: EthSvg,
            // Можно добавить другие валюты по мере необходимости
        };

        // Возвращаем иконку из списка или EthSvg как дефолтную
        return icons[currencyName] || EthSvg;
    };

    // Добавляем эффект для получения списка валют для компонента Select
    useEffect(() => {
        const fetchCurrencies = async () => {
            try {
                setIsLoadingCurrencies(true);
                const currencies = await getCurrencies();

                // Преобразуем данные для Select
                const options = currencies.map(currency => ({
                    value: currency.name, // Используем оригинальное имя валюты из API
                    label: currency.name
                }));

                setCurrencyOptions(options);
            } catch (error) {
                console.error('Ошибка при получении данных о валютах:', error);
            } finally {
                setIsLoadingCurrencies(false);
            }
        };

        fetchCurrencies();
    }, []);

    // Добавляем эффект для обновления фильтров валют
    useEffect(() => {
        const fetchCurrenciesForFilters = async () => {
            try {
                const currencies = await getCurrencies();
                // Извлекаем только имена валют для фильтрации
                const currencyNames = currencies.map(currency => currency.name);
                // Обновляем filterOptions с новыми валютами
                filterOptions.Currency = currencyNames;
            } catch (error) {
                console.error('Ошибка при получении данных о валютах для фильтров:', error);
            }
        };

        fetchCurrenciesForFilters();
    }, []);

    return (
        <div className={`${styles.balance} ${mediaStyles.balance}`}>

            <div className={`${styles.balanceInfo} ${mediaStyles.balanceInfo}`}>
                <div className={`${styles.balanceInfoWrapper}`}>
                    <div className={`${styles.balanceInfoAmount}`}>
                        <span className={`${styles.balanceInfoIconWrapper}`}><BalanceSvg /></span>
                        <div className={`${styles.balanceInfoBalance}`}>
                            <h3 className={`${styles.balanceInfoTitle}`}>Balance</h3>
                            <div className={`${styles.balanceInfoSum}`}>$ {balance}</div>
                        </div>
                    </div>
                    <div className={`${styles.balanceInfoСurrency}`}>
                        {Object.entries(balances).map(([currencyName, value]) => {
                            const IconComponent = getCurrencyIcon(currencyName);
                            return (
                                <div key={currencyName} className={`${styles.balanceInfoItem}`}>
                                    <div>
                                        <IconComponent />
                                    </div>
                                    <div className={`${styles.balanceInfoProperty}`}>
                                        <span className={`${styles.balanceInfoValue}`}>{value} {currencyName === 'ETH' ? 'ETH' : 'USDT'}</span>
                                        <small className={`${styles.balanceInfoRate}`}>
                                            ~$ {value.toFixed(2)}
                                        </small>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className={`${styles.balanceInfoButtons}`}>
                        <button className={`${styles.balanceInfoButtonMultiple}`} onClick={openMultipleWithdrawalDialog}><WithdrawSvg className={`${styles.balanceInfoButtonMultipleSvg}`} />Multiple Withdrawal</button>
                        <button className={`${styles.balanceInfoButton}`} onClick={() => dialogWithdrawal.current?.showModal()}><WithdrawSvg className={`${styles.balanceInfoButtonSvg}`} />Withdrawal</button>

                        <dialog ref={dialogWithdrawal} onClick={handleBackdropClick} className={`${styles.dialog} ${mediaStyles.dialog}`}>
                            <div className={`${styles.dialogBlock} ${mediaStyles.dialogBlock}`}>
                                <div className={`${styles.dialogContainer} ${mediaStyles.dialogContainer} ${styles.dialogContainerWithdrawal}`}>
                                    <div className={`${styles.dialogHeader}`}>
                                        <h3 className={`${styles.dialogTitle}`}>Withdrawal request</h3>
                                        <button onClick={closeDialog}>X</button>
                                    </div>
                                    <div className={`${styles.dialogContent}`}>
                                        <label className={`${styles.dialogLabel}`}>
                                            <span className={`${styles.dialogSpan}`}>Choose the currency of withdrawal</span>
                                            {isLoadingCurrencies ? (
                                                <div className={`${styles.loading}`}>Loading currencies...</div>
                                            ) : (
                                                <Select
                                                    placeholder='Выберите валюту'
                                                    value={currencyOptions.find(option => option.value === newCurrency.currency) || null}
                                                    onChange={handleCurrencyChange}
                                                    options={currencyOptions}
                                                    isSearchable={false}
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            borderColor: 'light-dark(var(--border-color-light), var(--border-color-dark))',
                                                            backgroundColor: 'light-dark(var(--second-color-light), var(--second-color-dark))',
                                                            color: 'light-dark(var(--text-color-light), var(--text-color-dark));',
                                                            padding: '5px',
                                                            borderRadius: '10px',
                                                            cursor: 'pointer',
                                                            height: '48px',
                                                            boxShadow: 'none',
                                                            ':hover': {
                                                                borderColor: 'light-dark(var(--border-color-light), var(--border-color-dark))',
                                                            },
                                                        }),
                                                        singleValue: (provided) => ({
                                                            ...provided,
                                                            color: "light-dark(var(--text-color-light), var(--text-color-dark));",
                                                        }),
                                                        menu: (provided) => ({
                                                            ...provided,
                                                            backgroundColor: 'light-dark(var(--second-color-light), var(--second-color-dark))',
                                                            borderRadius: '13px',
                                                            border: '1px solid light-dark(var(--border-color-light), var(--border-color-dark))',
                                                        }),
                                                        menuList: (provided) => ({
                                                            ...provided,
                                                            padding: 0,
                                                            borderRadius: '10px',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            gap: "2px"
                                                        }),
                                                        option: (provided) => ({
                                                            ...provided,
                                                            backgroundColor: "light-dark(var(--second-color-light), var(--second-color-dark))",
                                                            color: 'light-dark(var(--text-color-light), var(--text-color-dark))',
                                                            padding: '15px 19px',
                                                            borderRadius: '10px',
                                                            minHeight: '48px',
                                                            transition: "background-color 0.2s ease",
                                                            cursor: 'pointer',
                                                            '&:hover': {
                                                                backgroundColor: 'light-dark(var(--second-color-light), var(--second-color-dark))',
                                                            },
                                                        }),
                                                        indicatorSeparator: (provided) => ({
                                                            ...provided,
                                                            display: 'none',
                                                        }),
                                                        dropdownIndicator: (provided) => ({
                                                            ...provided,
                                                            display: 'none',
                                                        })
                                                    }}
                                                />
                                            )}
                                        </label>
                                        <label className={`${styles.dialogLabel}`}>
                                            <span className={`${styles.dialogSpan}`}>Wallet address for funds transfer</span>
                                            <input
                                                className={`${styles.dialogInput}`}
                                                type="text"
                                                name="address"
                                                placeholder="Address"
                                                value={newCurrency.address}
                                                onChange={(e) => setNewCurrency({ ...newCurrency, address: e.target.value })}
                                            />
                                        </label>
                                        <label className={`${styles.dialogLabel}`}>
                                            <span className={`${styles.dialogSpan}`}>Enter the withdrawal amount</span>
                                            <input
                                                className={`${styles.dialogInput}`}
                                                type="text"
                                                name="amount"
                                                placeholder="Amount from 10 USD"
                                                value={newCurrency.amount}
                                                onChange={(e) => {
                                                    // Разрешаем ввод только цифр и точки
                                                    const value = e.target.value;
                                                    const regex = /^[0-9]*\.?[0-9]*$/;
                                                    if (value === '' || regex.test(value)) {
                                                        setNewCurrency({ ...newCurrency, amount: value });
                                                    }
                                                }}
                                            />
                                        </label>
                                    </div>

                                    <button className={`${styles.dialogButton}`} onClick={handleWithdrawalSubmit}>Отправить</button>
                                </div>
                            </div>
                        </dialog>
                        <dialog ref={dialogMultipleWithdrawal} onClick={handleBackdropClick} className={`${styles.dialog} ${mediaStyles.dialog}`}>
                            <div className={`${styles.dialogBlock} ${mediaStyles.dialogBlock}`}>
                                <div className={`${styles.dialogContainer} ${mediaStyles.dialogContainer} ${styles.dialogContainerWithdrawal}`}>
                                    <div className={`${styles.dialogHeader}`}>
                                        <h3 className={`${styles.dialogTitle}`}>Multiple Withdrawal</h3>
                                        <button onClick={closeDialog}>X</button>
                                    </div>
                                    <div className={`${styles.dialogContent}`}>
                                        {step === 1 ? (
                                            <>
                                                <label className={`${styles.dialogLabel}`}>
                                                    <span className={`${styles.dialogSpan}`}>Wallet address for funds transfer</span>
                                                    {isLoadingCurrencies ? (
                                                        <div className={`${styles.loading}`}>Loading currencies...</div>
                                                    ) : (
                                                        <Select
                                                            placeholder='Выберите валюту'
                                                            value={currencyOptions.find(option => option.value === newCurrency.currency) || null}
                                                            onChange={handleCurrencyChange}
                                                            options={currencyOptions}
                                                            styles={{
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    borderColor: 'light-dark(var(--border-color-light), var(--border-color-dark))',
                                                                    backgroundColor: 'light-dark(var(--second-color-light), var(--second-color-dark))',
                                                                    color: 'light-dark(var(--text-color-light), var(--text-color-dark));',
                                                                    padding: '5px',
                                                                    borderRadius: '10px',
                                                                    cursor: 'text',
                                                                    height: '48px',
                                                                    boxShadow: 'none',
                                                                    ':hover': {
                                                                        borderColor: 'light-dark(var(--border-color-light), var(--border-color-dark))',
                                                                    },
                                                                }),
                                                                singleValue: (provided) => ({
                                                                    ...provided,
                                                                    color: "light-dark(var(--text-color-light), var(--text-color-dark));",
                                                                }),
                                                                menu: (provided) => ({
                                                                    ...provided,
                                                                    backgroundColor: 'light-dark(var(--second-color-light), var(--second-color-dark))',
                                                                    borderRadius: '13px',
                                                                    border: '1px solid light-dark(var(--border-color-light), var(--border-color-dark))',
                                                                }),
                                                                menuList: (provided) => ({
                                                                    ...provided,
                                                                    padding: 0,
                                                                    borderRadius: '10px',
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    gap: "2px"
                                                                }),
                                                                option: (provided) => ({
                                                                    ...provided,
                                                                    backgroundColor: "light-dark(var(--second-color-light), var(--second-color-dark))",
                                                                    color: 'light-dark(var(--text-color-light), var(--text-color-dark))',
                                                                    padding: '15px 19px',
                                                                    borderRadius: '10px',
                                                                    minHeight: '48px',
                                                                    transition: "background-color 0.2s ease",
                                                                    cursor: 'pointer',
                                                                    '&:hover': {
                                                                        backgroundColor: 'light-dark(var(--second-color-light), var(--second-color-dark))',
                                                                    },
                                                                }),
                                                                indicatorSeparator: (provided) => ({
                                                                    ...provided,
                                                                    display: 'none',
                                                                }),
                                                                dropdownIndicator: (provided) => ({
                                                                    ...provided,
                                                                    display: 'none',
                                                                })
                                                            }}
                                                        />
                                                    )}
                                                </label>
                                                <div className={`${styles.dialogLabel}`}>
                                                    <div className={`${styles.dialogLabelHeader}`}>
                                                        <span className={`${styles.dialogSpan}`}>Recipient addresses and amounts</span>
                                                        <button
                                                            type="button"
                                                            className={`${styles.dialogExampleButton}`}
                                                            onClick={insertExample}
                                                        >
                                                            Show Example
                                                        </button>
                                                    </div>
                                                    <div className={`${styles.textareaContainer}`}>
                                                        <div className={`${styles.lineNumbers}`}>
                                                            {newCurrency.addressesList?.split('\n').map((line, i) => (
                                                                <div key={i} className={`${styles.lineNumber} ${lineCharCount[i] >= 128 ? styles.lineNumberLimit : ''}`}>
                                                                    {i + 1}
                                                                </div>
                                                            )) || <div className={`${styles.lineNumber}`}>1</div>}
                                                        </div>
                                                        <textarea
                                                            ref={textareaRef}
                                                            className={`${styles.dialogTextarea}`}
                                                            placeholder="Type your address and amount for withdrawal"
                                                            value={newCurrency.addressesList || ''}
                                                            onChange={handleTextareaChange}
                                                            onKeyDown={handleKeyDown}
                                                            onClick={handleTextareaClick}
                                                            rows={8}
                                                        />
                                                    </div>
                                                </div>
                                                <button className={`${styles.dialogButton}`} type="button" onClick={goToStep2}>1/2 Next</button>
                                            </>
                                        ) : (
                                            <>
                                                <TwoFactorInput value={code} onChange={setCode} />
                                                <button
                                                    className={`${styles.dialogButton} ${isLoading ? styles.dialogButtonLoading : ''}`}
                                                    type="button"
                                                    onClick={handleFormSubmit}
                                                    disabled={isLoading}
                                                >
                                                    {isLoading ? 'Отправка...' : '2/2 Submit'}
                                                </button>
                                            </>
                                        )}
                                    </div>

                                </div>
                            </div>
                        </dialog>
                    </div>
                </div>
                <div className={`${styles.balanceInfoContainer} ${mediaStyles.balanceInfoContainer}`}>
                    {invoiceItems.map((item, index) => (
                        <div key={index} className={`${styles.balanceInfoBlock}`}>
                            <div className={`${styles.balanceInfoLabel}`}>{item.label}</div>
                            <div className={`${styles.balanceInfoCount}`}>
                                <span className={`${styles.balanceInfoAsset} ${item.label === 'Created invoicdes' ? styles.balanceInfoAssetTotalColor : item.label === 'Paid invoices' ? styles.balanceInfoAssetPaidColor : item.label === 'Partially paid' ? styles.balanceInfoAssetPartiallyColor : styles.balanceInfoAssetUnpaidColor}`}>$ {item.value.toFixed(2)}</span>
                                {item.label !== "Created invoicdes" && (
                                    <span className={`${styles.balanceInfoPercent}`}>{item.percent}%</span>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>



            <div className={`${styles.balanceContainer} ${mediaStyles.balanceContainer}`}>
                <div className={`${styles.balanceWrapper} ${mediaStyles.balanceWrapper}`}>
                    <div className={`${styles.balanceTitle} ${mediaStyles.balanceTitle}`}>History of operations</div>
                    <div className={`${styles.balanceItem}`}>
                        <button className={`${styles.balanceButton}`} onClick={() => downloadsDialog.current?.showModal()}>Экспорт <ExportSvg /></button>
                        <ExportModal
                            closeDialog={closeDialog}
                            downloadsDialog={downloadsDialog}
                            filterOptions={filterOptions}
                            selectedOptions={selectedOptions}
                            i18n={i18n}
                            handleCheckboxChange={handleCheckboxChange}
                            handleCategoryClick={handleCategoryClick}
                            handleSearchChange={handleSearchChange}
                            searchQuery={searchQuery}
                            filteredResults={filteredResults}
                            isAllTime={isAllTime}
                            startDate={startDate}
                            endDate={endDate}
                            handleDateChange={handleDateChange}
                            selectedDates={selectedDates}
                            isCalendarVisible={isCalendarVisible}
                            handleCalendarToggle={handleCalendarToggle}
                            calendarButtonRef={calendarButtonRef}
                            handleExportToEmail={handleExportToEmail}
                            handleExportToExcel={handleExportToExcel}
                        />
                        <button className={`${styles.balanceButton}`} onClick={() => settingsDialog.current?.showModal()}><SettingsSvg /></button>
                        <dialog ref={settingsDialog} onClick={handleBackdropClick} className={`${styles.dialog} ${mediaStyles.dialog}`}>
                            <div className={`${styles.dialogBlock} ${mediaStyles.dialogBlock}`}>
                                <div className={`${styles.dialogContainer} ${mediaStyles.dialogContainer}`}>

                                    <div className={`${styles.balanceDialogHeader} ${mediaStyles.balanceDialogHeader}`}>
                                        <h3 className={`${styles.balanceDialogTitle}`}>Settings</h3>
                                        <button onClick={closeDialog} className={`${styles.balanceDialogCross}`}>X</button>
                                    </div>

                                    <div className={`${styles.balanceDialogWrapper} ${mediaStyles.balanceDialogWrapper}`}>
                                        Parameters
                                        <label className={`${styles.balanceDialogSub}`}>
                                            Status
                                            <input
                                                type="checkbox"
                                                checked={Object.keys(tempVisibleColumns)
                                                    .filter((key) => key !== "id" && key !== "created_at")
                                                    .every((key) => tempVisibleColumns[key])}
                                                onChange={toggleAllColumns}
                                                className={`${styles.balanceDialogCheckbox}`}
                                            />
                                        </label>
                                    </div>

                                    <ul className={`${styles.balanceDialogUl} ${mediaStyles.balanceDialogUl}`}>
                                        {Object.keys(tempVisibleColumns).map((key) => (
                                            <li key={key}>
                                                <label className={`${styles.balanceDialogLabel}`}>
                                                    {key}
                                                    <input
                                                        type="checkbox"
                                                        checked={tempVisibleColumns[key]}
                                                        onChange={() => toggleColumn(key)}
                                                        disabled={key === "id" || key === "created_at"}
                                                        className={`${styles.balanceDialogCheckbox}`}
                                                    />

                                                </label>
                                            </li>
                                        ))}
                                    </ul>
                                    <div className={`${styles.balanceDialogButtons} ${mediaStyles.balanceDialogButtons}`}>
                                        <button className={`${styles.balanceDialogClose} ${mediaStyles.balanceDialogClose}`} onClick={closeDialog}>Close</button>
                                        <button className={`${styles.balanceDialogSave} ${mediaStyles.balanceDialogSave}`} onClick={saveSettings}>Save</button>
                                    </div>
                                </div>
                            </div>
                        </dialog>
                    </div>
                </div>
                <div className={`${styles.balanceSearchWrapper} ${mediaStyles.balanceSearchWrapper}`}>
                    <div className={`${styles.balanceSearch} ${mediaStyles.balanceSearch}`}>
                        <div className={`${styles.balanceSearchInput}`}>
                            <LupaSvg />
                            <input
                                className={`${styles.balanceInput}`}
                                type="text"
                                placeholder='Search hash, api, wallet etc'
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                        </div>
                        <button ref={calendarButtonRef} className={`${styles.balanceСalendar} ${mediaStyles.balanceСalendar}`} onClick={handleCalendarToggle}>
                            <CalendarSvg className={`${styles.balanceСalendarSvg}`} />
                            <>{isAllTime
                                ? ''
                                : (startDate && endDate
                                    ? (format(startDate, 'dd.MM.yy') === format(endDate, 'dd.MM.yy')
                                        ? format(startDate, 'dd.MM.yy')
                                        : `${format(startDate, 'dd.MM.yy')} - ${format(endDate, 'dd.MM.yy')}`)
                                    : `${format(yesterday, 'dd.MM.yy')} - ${format(today, 'dd.MM.yy')}`
                                )
                            }</>
                        </button>
                        {isCalendarVisible && (
                            <div ref={calendarContainerRef} className={`${styles.calendarContainer} ${mediaStyles.calendarContainer}`}>
                                <LocalizedCalendar
                                    selectRange={true}
                                    onChange={handleDateChange}
                                    value={selectedDates}
                                    tileGroupCount={3}
                                    tileDisabled={({ date }) => date > new Date()}
                                    minDetail="year"
                                />
                                <div className={styles.calendarContainerButtons}>
                                    <button
                                        onClick={() => {
                                            setIsAllTime(true);
                                            setStartDate(null);
                                            setEndDate(null);
                                            setSelectedDates([]);
                                            setIsCalendarVisible(false);
                                        }}
                                        className={styles.calendarContainerallTimeButton}
                                    >
                                        {t('calendar.buttons.allTime')}
                                    </button>
                                    <button
                                        onClick={() => {
                                            setIsCalendarVisible(false);
                                        }}
                                        className={styles.calendarContainercloseButton}
                                    >
                                        {t('calendar.buttons.close')}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className={`${styles.balancePreferences}`}>
                    {hasActiveFilters && (
                        <button className={`${styles.balanceClearButton}`} onClick={handleClearFilters}>
                            <TrashSvg />
                        </button>
                    )}
                    {Object.entries(selectedOptions).map(([filter, options]) =>
                        Array.from(options).map((option) => (
                            <div key={`${filter}-${option}`} className={styles.balanceFilterTag}>
                                {option}
                                <button className={styles.balanceCrossButton} onClick={() => handleRemoveFilter(filter, option)}>
                                    <OstentatiousCrossSvg className={styles.balanceCross} />
                                </button>
                            </div>
                        ))
                    )}

                    <button className={`${styles.balancePreferencesButton}`} ref={filterButtonRef} onClick={handleFilterToggle}>Add filter +</button>
                    {isFilterDropdownVisible && (
                        <div ref={filterDropdownRef} className={styles.balanceFilterDropdown}>
                            <div className={styles.balanceFilterSearch}>
                                <LupaSvg className={styles.balanceLupa} />
                                <input
                                    className={styles.balanceFilterInput}
                                    type="text"
                                    placeholder="Search filters..."
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                />
                            </div>

                            {searchQuery ? (
                                <ul className={styles.balanceFilterUl}>
                                    {filteredResults.map(({ category, option }) => (
                                        <li key={option} className={styles.balanceFilterItem}>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    checked={selectedOptions[category]?.has(option) || false}
                                                    onChange={() => handleCheckboxChange(category, option)}
                                                />
                                                {option} <span className={styles.filterCategory}>({category})</span>
                                            </label>
                                        </li>
                                    ))}
                                </ul>
                            ) : activeCategory ? (
                                <>
                                    <ul className={styles.balanceFilterUl}>
                                        {filterOptions[activeCategory].map((option) => (
                                            <li key={option}>
                                                <label className={styles.balanceFilterItem}>
                                                    {option}
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedOptions[activeCategory]?.has(option) || false}
                                                        onChange={() => handleCheckboxChange(activeCategory, option)}
                                                        className={`${styles.balanceDialogFilterCheckbox}`}
                                                    />
                                                </label>
                                            </li>
                                        ))}
                                    </ul>
                                    <button className={styles.balanceBackButton} onClick={() => setActiveCategory(null)}>
                                        <ArrowSvg className={styles.balanceBackArrow} />Back
                                    </button>
                                </>
                            ) : (
                                <ul className={styles.balanceFilterUl}>
                                    {Object.keys(filterOptions).map((category) => (
                                        <li key={category} className={styles.balanceFilterItem} onClick={() => handleCategoryClick(category)}>
                                            {category}
                                            <ArrowSvg className={styles.balanceFilterArrow} />
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    )}
                </div>

                <div className={`${styles.balanceBlock} ${mediaStyles.balanceBlock}`}>
                    <div className={`${styles.balanceLine} ${mediaStyles.balanceLine}`}>
                        {visibleColumns.id && <div className={`${styles.balanceText} ${styles.listId}`} onClick={() => handleSort('id')}>TX ID {sortConfig.key === 'id' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</div>}
                        {visibleColumns.tx_hash && <div className={`${styles.balanceText} ${styles.listHash}`} onClick={() => handleSort('tx_hash')}>TX Hash {sortConfig.key === 'tx_hash' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</div>}
                        {visibleColumns.api_label && <div className={`${styles.balanceText} ${styles.listApi}`} onClick={() => handleSort('api_label')}>API {sortConfig.key === 'api_label' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</div>}
                        {visibleColumns.label && <div className={`${styles.balanceText} ${styles.listLabel}`} onClick={() => handleSort('label')}>{i18n.t('Balance')} {sortConfig.key === 'label' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</div>}
                        {visibleColumns.currency && <div className={`${styles.balanceText} ${styles.listCurrency}`} onClick={() => handleSort('currency')}>Валюта {sortConfig.key === 'currency' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</div>}
                        {visibleColumns.amount && <div className={`${styles.balanceText} ${styles.listToken}`} onClick={() => handleSort('amount')}>{i18n.t('amount')} {sortConfig.key === 'amount' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</div>}
                        {visibleColumns.amount_usd && <div className={`${styles.balanceText} ${styles.listAmount}`} onClick={() => handleSort('amount_usd')}>{i18n.t('amount')} USD {sortConfig.key === 'amount_usd' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</div>}
                        {visibleColumns.network_fee && <div className={`${styles.balanceText} ${styles.listNetworkFee}`} onClick={() => handleSort('network_fee')}>Комиссия сети {sortConfig.key === 'network_fee' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</div>}
                        {visibleColumns.service_fee && <div className={`${styles.balanceText} ${styles.listServiceFee}`} onClick={() => handleSort('service_fee')}>Сервисный сбор {sortConfig.key === 'service_fee' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</div>}
                        {visibleColumns.is_paid && <div className={`${styles.balanceText} ${styles.listPaid}`} onClick={() => handleSort('is_paid')}>{i18n.t('payed')} {sortConfig.key === 'is_paid' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</div>}
                        {visibleColumns.is_postback_sended && <div className={`${styles.balanceText} ${styles.listSent}`} onClick={() => handleSort('is_postback_sended')}>IPN {i18n.t('sent')} {sortConfig.key === 'is_postback_sended' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</div>}
                        {visibleColumns.created_at && <div className={`${styles.balanceText} ${styles.listTime}`} onClick={() => handleSort('created_at')}>{i18n.t('created')} {sortConfig.key === 'created_at' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</div>}
                    </div>

                    <ul className={`${styles.list} ${mediaStyles.list}`}>
                        {isLoading ? (
                            <div className={styles.listLoading}>Загрузка транзакций...</div>
                        ) : sortedTransactions.length === 0 ? (
                            <div className={styles.listNoTransactions}>
                                <h3 className={styles.listNoTransactionsTitle}>{i18n.t('noTransactionsTitle')}</h3>
                                <p className={styles.listNoTransactionsSubtitle}>{i18n.t('noTransactionsSubtitle')}</p>
                            </div>
                        ) : (
                            sortedTransactions.map((transaction) => (
                                <li key={transaction.id} className={`${styles.listItem} ${mediaStyles.listItem}`}>
                                    {visibleColumns.id && (
                                        <div className={`${styles.listWrapper} ${styles.listId}`}>
                                            {transaction.id}
                                        </div>
                                    )}
                                    {visibleColumns.tx_hash && (
                                        <div className={`${styles.listWrapper} ${styles.listHash}`}>{transaction.tx_hash}</div>
                                    )}
                                    {visibleColumns.api_label && (
                                        <div className={`${styles.listWrapper} ${styles.listApi}`}>{transaction.api_label}</div>
                                    )}
                                    {visibleColumns.label && (
                                        <div className={`${styles.listWrapper} ${styles.listLabel}`}>{transaction.label}</div>
                                    )}
                                    {visibleColumns.currency && (
                                        <div className={`${styles.listWrapper} ${styles.listCurrency}`}>{transaction.currency.name}</div>
                                    )}
                                    {visibleColumns.amount && (
                                        <div className={`${styles.listWrapper} ${styles.listToken}`}>
                                            {transaction.amount % 1 === 0 ? transaction.amount : transaction.amount.toFixed(2)} {transaction.currency.name}
                                        </div>
                                    )}
                                    {visibleColumns.amount_usd && (
                                        <div className={`${styles.listWrapper} ${styles.listAmount}`}>
                                            {transaction.amount_usd % 1 === 0 ? transaction.amount_usd : transaction.amount_usd.toFixed(2)} USDT
                                        </div>
                                    )}
                                    {visibleColumns.network_fee && (
                                        <div className={`${styles.listWrapper} ${styles.listNetworkFee}`}>
                                            {transaction.network_fee % 1 === 0 ? transaction.network_fee : transaction.network_fee.toFixed(2)}
                                        </div>
                                    )}
                                    {visibleColumns.service_fee && (
                                        <div className={`${styles.listWrapper} ${styles.listServiceFee}`}>
                                            {transaction.service_fee % 1 === 0 ? transaction.service_fee : transaction.service_fee.toFixed(2)}
                                        </div>
                                    )}
                                    {visibleColumns.is_paid && (
                                        <div className={`${styles.listWrapper} ${styles.listPaid}`}>
                                            {transaction.is_paid ? (
                                                <span className={styles.listStatusTrue}>{i18n.t('paid')}</span>
                                            ) : (
                                                <span className={styles.listStatusFalse}>{i18n.t('pending')}</span>
                                            )}
                                        </div>
                                    )}
                                    {visibleColumns.is_postback_sended && (
                                        <div className={`${styles.listWrapper} ${styles.listSent}`}>
                                            {transaction.is_postback_sended ? (
                                                <span className={styles.listStatusTrue}>{i18n.t('sent')}</span>
                                            ) : (
                                                <span className={styles.listStatusFalse}>{i18n.t('notsent')}</span>
                                            )}
                                        </div>
                                    )}
                                    {visibleColumns.created_at && (
                                        <div className={`${styles.listWrapper} ${styles.listTime}`}>
                                            {new Date(transaction.created_at).toLocaleString('ru-RU', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                            })}
                                        </div>
                                    )}
                                </li>
                            ))
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Balance;
