import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMenu } from '../../tools/MenuContext.tsx';
import { profileImage } from '../../assets/images/images.ts';
import Header from '../Header/Header.tsx';
import Footer from '../Footer/Footer.tsx';
import { getUserProfile, UserProfile } from '../../data/Api.ts';

import { LogoSvg, DashboardSvg, BalanceAsideSvg, WalletsSvg, SubtractSvg, InvoicesSvg, SupportSvg, FaqSvg, ApiSvg, ExclamationSvg, StarsSvg } from '../../assets/svg-files/SvgIcons.ts';
import { Link, NavLink, BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { Authorization, Profile, Dashboard, Balance, Wallets, Invoices, MyProjects, Support, Faq, Api, TicketPage, PrivacyPolicy, TermsOfUse } from '../../pages/Pages.ts';

// @ts-ignore
import styles from './Aside.module.scss';
// @ts-ignore
import mediaStyles from './Aside-media.module.scss';


const Aside = () => {
    const { t } = useTranslation();
    const { isMenuOpen, toggleMenu } = useMenu();
    const [scrollPosition, setScrollPosition] = useState(0);
    const navigate = useNavigate();
    const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                setLoading(true);
                const data = await getUserProfile();
                setUserProfile(data);
            } catch (error) {
                console.error('Ошибка при получении данных профиля:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchUserProfile();
    }, []);

    const handleNavLinkClick = () => {
        if (isMenuOpen) {
            toggleMenu();
        }
    };
    const handleOfferButtonClick = () => {
        localStorage.setItem('activeTab', 'subscriptions');
        navigate('/pages/Profile', { replace: true });
    };
    useEffect(() => {
        if (isMenuOpen) {
            setScrollPosition(window.scrollY);
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
            window.scrollTo(0, scrollPosition);
        }

        return () => {
            document.body.style.overflow = '';
        };
    }, [isMenuOpen, scrollPosition]);

    // Определяем, что отображать: имя или email
    const displayName = userProfile ? (userProfile.name || userProfile.email) : 'Loading...';

    return (
        <>
            <div className={`${styles.overlay} ${isMenuOpen ? `${mediaStyles.overlay}` : ''}`} onClick={toggleMenu}></div>
            <Header />
            <aside className={`${styles.aside} ${mediaStyles.aside} ${isMenuOpen ? `${mediaStyles.open}` : ''}`}>
                <div>
                    <nav>
                        <div className={`${styles.titleWrapper} ${mediaStyles.titleWrapper}`}>
                            <div className={`${styles.burger} ${mediaStyles.burger}`} onClick={toggleMenu}>
                                <span className={styles.topLine}></span>
                                <span className={styles.botLine}></span>
                            </div>
                            <a className={`${styles.logo} ${mediaStyles.logo}`} href="/">
                                <LogoSvg />
                                <h2 className={styles.logoTitle}>NordPay</h2>
                            </a>
                        </div>
                        <ul className={`${styles.item} ${mediaStyles.item}`}>
                            <h3 className={styles.title}>{t('profile.tabs.main')}</h3>
                            <NavLink onClick={handleNavLinkClick} className={({ isActive }) => isActive ? `${styles.profile} ${mediaStyles.profile} ${styles.activeProfile} ${mediaStyles.activeProfile}` : `${styles.profile} ${mediaStyles.profile}`} to="/pages/Profile">
                                <img id="profile-avatar" className={styles.avatar} src={profileImage.test} alt="Avatar" />
                                <p id="profile-email" className={styles.email}>{displayName}</p>
                            </NavLink>
                        </ul>

                        <ul className={`${styles.item} ${mediaStyles.item}`}>
                            <h3 className={styles.title}>{t('mainMenu')}</h3>
                            <NavLink onClick={handleNavLinkClick} className={({ isActive }) => isActive ? `${styles.navButton}  ${mediaStyles.navButton} ${styles.active}  ${mediaStyles.active}` : `${styles.navButton} ${mediaStyles.navButton}`} to="/">
                                <p className={styles.link}>
                                    <DashboardSvg className={styles.svg} />
                                    {t('dashboard')}
                                </p>
                            </NavLink>
                            <NavLink onClick={handleNavLinkClick} className={({ isActive }) => isActive ? `${styles.navButton}  ${mediaStyles.navButton} ${styles.active}  ${mediaStyles.active}` : `${styles.navButton} ${mediaStyles.navButton}`} to="/pages/Balance">
                                <p className={styles.link}>
                                    <BalanceAsideSvg className={styles.svg} />
                                    {t('Balance')}
                                </p>
                            </NavLink>
                            <NavLink onClick={handleNavLinkClick} className={({ isActive }) => isActive ? `${styles.navButton} ${mediaStyles.navButton} ${styles.active}  ${mediaStyles.active}` : `${styles.navButton} ${mediaStyles.navButton}`} to="/pages/Wallets">
                                <p className={styles.link}>
                                    <WalletsSvg className={styles.svg} />
                                    {t('wallets')}
                                </p>
                            </NavLink>
                            <NavLink onClick={handleNavLinkClick} className={({ isActive }) => isActive ? `${styles.navButton} ${mediaStyles.navButton} ${styles.active}  ${mediaStyles.active}` : `${styles.navButton} ${mediaStyles.navButton}`} to="/pages/Invoices">
                                <p className={styles.link}>
                                    <InvoicesSvg className={styles.svg} />
                                    {t('invoices')}
                                </p>
                            </NavLink>
                            <NavLink onClick={handleNavLinkClick} className={({ isActive }) => isActive ? `${styles.navButton} ${mediaStyles.navButton} ${styles.active}  ${mediaStyles.active}` : `${styles.navButton} ${mediaStyles.navButton}`} to="/pages/MyProjects">
                                <p className={styles.link}>
                                    <SubtractSvg className={styles.svg} />
                                    {t('MyProjects')}
                                </p>
                            </NavLink>
                        </ul>
                        <ul className={`${styles.item} ${mediaStyles.item}`}>
                            <h3 className={styles.title}>{t('documentation')}</h3>
                            <NavLink onClick={handleNavLinkClick} className={({ isActive }) => isActive ? `${styles.navButton} ${mediaStyles.navButton} ${styles.active}  ${mediaStyles.active}` : `${styles.navButton} ${mediaStyles.navButton}`} to="/pages/Support">
                                <p className={styles.link}>
                                    <SupportSvg className={styles.svg} />
                                    {t('support')}
                                </p>
                            </NavLink>
                            <NavLink onClick={handleNavLinkClick} className={({ isActive }) => isActive ? `${styles.navButton} ${mediaStyles.navButton} ${styles.active}  ${mediaStyles.active}` : `${styles.navButton} ${mediaStyles.navButton}`} to="/pages/Faq">
                                <p className={styles.link}>
                                    <FaqSvg className={styles.svg} />
                                    FAQ
                                </p>
                            </NavLink>
                            <NavLink onClick={handleNavLinkClick} className={({ isActive }) => isActive ? `${styles.navButton} ${mediaStyles.navButton} ${styles.active}  ${mediaStyles.active}` : `${styles.navButton} ${mediaStyles.navButton}`} to="/pages/Api">
                                <p className={styles.link}>
                                    <ApiSvg className={styles.svg} />
                                    {t('api')}
                                </p>
                            </NavLink>
                        </ul>
                    </nav>
                    <div className={styles.offer}>
                        <div className={styles.offerWrapper}>
                            <div className={styles.offerTitle}>
                                <ExclamationSvg className={styles.offerTitleSvg} />
                                <p className={styles.offerTitleText}>{t('offerTitle')}</p>
                            </div>
                            <button onClick={handleOfferButtonClick} className={styles.offerButton}>
                                <StarsSvg className={styles.offerButtonSvg} />
                                <p className={styles.offerButtonText}>{t('offerButton')}</p>
                            </button>
                        </div>
                        <div className={styles.docs}>
                            <Link className={styles.docsLink} to="/pages/PrivacyPolicy">{t('privacy')}</Link>
                            <Link className={styles.docsLink} to="/pages/TermsOfUse">Terms of use</Link>
                        </div>
                    </div>
                </div>
            </aside>
            <main className={`${styles.main} ${mediaStyles.main}`}>
                <Routes>
                    <Route path="/pages/Profile" element={<Profile />} />
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/pages/Balance" element={<Balance />} />
                    <Route path="/pages/Wallets" element={<Wallets />} />
                    <Route path="/pages/Invoices" element={<Invoices />} />
                    <Route path="/pages/MyProjects" element={<MyProjects />} />
                    <Route path="/pages/Support" element={<Support />} />
                    <Route path="/pages/Support/:ticketId" element={<TicketPage />} />
                    <Route path="/pages/Faq" element={<Faq />} />
                    <Route path="/pages/Api" element={<Api />} />
                    <Route path="/pages/PrivacyPolicy" element={<PrivacyPolicy />} />
                    <Route path="/pages/TermsOfUse" element={<TermsOfUse />} />
                    <Route path="Authorization/*" element={<Authorization />} />
                </Routes>
            </main>
            <Footer />
        </>
    );
};

export default Aside;
