import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import { PaperclipSvg } from '../../assets/svg-files/SvgIcons.ts';
import { apiRequest } from '../../data/Api.ts';
// import { fakeTickets } from '../../data/data.ts';
//@ts-ignore
import styles from './Support.module.scss';
//@ts-ignore
import mediaStyles from './Support-media.module.scss';

interface Ticket {
    id: number;
    category: string;
    subject: string;
    status: string;
    created_at: string;
}

const ticketCategories = [
    { value: 'Technical Issues', label: 'Technical Issues' },
    { value: 'Account and Access', label: 'Account and Access' },
    { value: 'Financial Questions', label: 'Financial Questions' },
    { value: 'Information and Documentation', label: 'Information and Documentation' },
    { value: 'Feature Requests and Improvements', label: 'Feature Requests and Improvements' },
    { value: 'Security', label: 'Security' },
    { value: 'Support and Consultation', label: 'Support and Consultation' },
    { value: 'Software Bugs', label: 'Software Bugs' },
    { value: 'General Questions', label: 'General Questions' },
];
const Support: React.FC = () => {
    const dialogRef = useRef<HTMLDialogElement | null>(null);
    const [tickets, setTickets] = useState<Ticket[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [category, setCategory] = useState<string>('');
    const [subject, setSubject] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [attachment, setAttachment] = useState<File | null>(null);
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);

    const openDialog = () => {
        if (dialogRef.current) {
            dialogRef.current.showModal();
        }
    };
    const closeDialog = () => {
        if (dialogRef.current) {
            dialogRef.current.close();
        }
    };
    const handleBackdropClick = (e: React.MouseEvent) => {
        if (e.target === e.currentTarget) {
            closeDialog();
        }
    };

    const fetchTickets = async () => {
        try {
            setLoading(true);
            const data = await apiRequest<Ticket[]>('/pay/user/support/tickets', 'GET');
            setTickets(data);
        } catch (error) {
            console.error('Ошибка при получении тикетов:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTickets();
    }, []);

    const createTicket = async (ticketDataStr: string) => {
        try {
            setSubmitLoading(true);
            console.log(ticketDataStr);
            const response = await apiRequest<{ success: boolean; message?: string }>('/pay/user/support/ticket', 'POST', ticketDataStr);

            showSuccessToast('Тикет создан!');
            closeDialog();
            // Очистка формы
            setCategory('');
            setSubject('');
            setDescription('');
            setAttachment(null);
            // Перезагрузка списка тикетов
            await fetchTickets();
        } catch (error) {
            console.error('Ошибка при создании тикета:', error);
            showErrorToast('Произошла ошибка. Пожалуйста, попробуйте снова.');
        } finally {
            setSubmitLoading(false);
        }
    };

    const showErrorToast = (message: string) => {
        alert(message);
    };

    const showSuccessToast = (message: string) => {
        alert(message);
    };

    const handleCategoryChange = (selectedOption: any) => {
        setCategory(selectedOption ? selectedOption.value : '');
    };

    const handleSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSubject(event.target.value);
    };

    const handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setDescription(event.target.value);
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileInput = event.target;
        const file = fileInput.files?.[0] || null;
        setAttachment(file);

        const fileName = file?.name || '';
        const fileNameElement = document.getElementById("fileName") as HTMLSpanElement | null;
        if (fileNameElement) {
            fileNameElement.textContent = fileName;
        }
    }

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        if (!category || !subject || !description) {
            alert('Заполните все поля');
            return;
        }

        // Создаем строку с данными тикета
        const ticketDataStr = JSON.stringify({
            category,
            subject,
            description,
            photo: attachment ? attachment.name : null
        });

        createTicket(ticketDataStr);
    };

    return (
        <div className={`${styles.mainSupport}`} id="top">

            <div className={`${styles.mainSupportCard}`}>
                <div className={`${styles.mainSupportHeader}`}>
                    <h5 className={`${styles.mainSupportTitle}`}>
                        Your Support Tickets
                    </h5>
                    <button
                        className={`${styles.mainSupportButton}`}
                        onClick={openDialog}
                    >
                        Create New Ticket
                    </button>
                </div>

                <table className={`${styles.mainSupportTable}`}>
                    <thead className={`${styles.mainSupportTableLine}`}>
                        <tr className={`${styles.mainSupportTableWrapperTitle}`}>
                            <th className={`${styles.mainSupportTableTitle}`}>Ticket ID</th>
                            <th className={`${styles.mainSupportTableTitle}`}>Category</th>
                            <th className={`${styles.mainSupportTableTitle}`}>Subject</th>
                            <th className={`${styles.mainSupportTableTitle}`}>Status</th>
                            <th className={`${styles.mainSupportTableTitle}`}>Create time</th>
                            <th className={`${styles.mainSupportTableTitle}`}>Actions</th>
                        </tr>
                    </thead>
                    <tbody className={`${styles.mainSupportTableBody}`}>
                        {loading ? (
                            <tr>
                                <td className={`${styles.mainSupportTextCenter}`} colSpan={6}>Loading...</td>
                            </tr>
                        ) : tickets.length === 0 ? (
                            <tr>
                                <td colSpan={6} className={`${styles.mainSupportTextCenter}`}>No tickets found</td>
                            </tr>
                        ) : (
                            tickets.map((ticket) => {
                                const formattedDate = new Date(ticket.created_at).toLocaleString('en-GB', {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                    hour12: false,
                                }).replace(',', '');

                                return (
                                    <tr
                                        className={`${styles.mainSupportTr}`}
                                        key={ticket.id}
                                        onClick={() => window.location.href = `/pages/Support/${ticket.id}`}
                                    >
                                        <td className={`${styles.mainSupportTrText} ${styles.mainSupportTrId}`}><b>#{ticket.id}</b></td>
                                        <td className={`${styles.mainSupportTrText}`}>{ticket.category}</td>
                                        <td className={`${styles.mainSupportTrText}`}>{ticket.subject}</td>

                                        <td className={`${styles.mainSupportTrText}`}>
                                            <span className={`${styles.mainSupportTrStatus} 
                                        ${ticket.status === 'in_progress' ?
                                                    styles.mainSupportTrStatusInProgress :
                                                    ticket.status === 'freeze' ? styles.mainSupportTrStatusFreeze : styles.mainSupportTrStatusClosed}`}>
                                                {ticket.status === 'in_progress' ? 'In progress' : ticket.status === 'freeze' ? 'Freezed' : 'Closed'}
                                            </span>
                                        </td>

                                        <td className={`${styles.mainSupportTrText}`}>{formattedDate}</td>
                                        <td className={`${styles.mainSupportTrText} ${styles.mainSupportTrActions}`}>
                                            <a href={`/pages/Support/${ticket.id}`}>View</a>
                                        </td>
                                    </tr>
                                );
                            })
                        )}
                    </tbody>
                </table>
                {/* Модальное окно, использующее <dialog> */}
                <dialog ref={dialogRef} className={`${styles.dialog} ${mediaStyles.dialog}`} onClick={handleBackdropClick}>
                    <div className={`${styles.dialogBlock} ${mediaStyles.dialogBlock}`}>
                        <div className={`${styles.dialogContainer} ${mediaStyles.dialogContainer}`}>
                            <div className={`${styles.dialogHeader} ${mediaStyles.dialogHeader}`}>
                                <h5 className={`${styles.dialogTitle}`}>Create New Ticket</h5>
                                <button className={styles.closeButton} onClick={closeDialog}>X</button>
                            </div>
                            <form className={`${styles.dialogContent}`} onSubmit={handleSubmit}>
                                <div className={`${styles.dialogLabel}`}>
                                    <label htmlFor="ticketCategory" className={`${styles.dialogSpan}`}>Category</label>
                                    <Select
                                        placeholder="Select category of ticket"
                                        value={ticketCategories.find(option => option.value === category) || null}
                                        onChange={handleCategoryChange}
                                        options={ticketCategories}
                                        isSearchable={false}
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                borderColor: 'light-dark(var(--border-color-light), var(--border-color-dark))',
                                                backgroundColor: 'light-dark(var(--second-color-light), var(--second-color-dark))',
                                                color: 'light-dark(var(--text-color-light), var(--text-color-dark));',
                                                padding: '5px',
                                                borderRadius: '10px',
                                                cursor: 'pointer',
                                                height: '48px',
                                                boxShadow: 'none',
                                                ':hover': {
                                                    borderColor: 'light-dark(var(--border-color-light), var(--border-color-dark))',
                                                },
                                            }),
                                            singleValue: (provided) => ({
                                                ...provided,
                                                color: "light-dark(var(--text-color-light), var(--text-color-dark));",
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                backgroundColor: 'light-dark(var(--second-color-light), var(--second-color-dark))',
                                                borderRadius: '13px',
                                                border: '1px solid light-dark(var(--border-color-light), var(--border-color-dark))',
                                            }),
                                            menuList: (provided) => ({
                                                ...provided,
                                                padding: 0,
                                                borderRadius: '10px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: "2px"
                                            }),
                                            option: (provided) => ({
                                                ...provided,
                                                backgroundColor: "light-dark(var(--second-color-light), var(--second-color-dark))",
                                                color: 'light-dark(var(--text-color-light), var(--text-color-dark))',
                                                padding: '15px 19px',
                                                borderRadius: '10px',
                                                minHeight: '48px',
                                                transition: "background-color 0.2s ease",
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    backgroundColor: 'light-dark(var(--second-color-light), var(--second-color-dark))',
                                                },
                                            }),
                                            indicatorSeparator: (provided) => ({
                                                ...provided,
                                                display: 'none',
                                            }),
                                            dropdownIndicator: (provided) => ({
                                                ...provided,
                                                display: 'none',
                                            })
                                        }}
                                    />
                                </div>
                                <div className={`${styles.dialogLabel}`}>
                                    <label htmlFor="ticketSubject" className={`${styles.dialogSpan}`}>Subject</label>
                                    <input
                                        type="text"
                                        className={`${styles.dialogInput}`}
                                        id="ticketSubject"
                                        value={subject}
                                        onChange={handleSubjectChange}
                                    />
                                </div>
                                <div className={`${styles.dialogLabel}`}>
                                    <label htmlFor="ticketDescription" className={`${styles.dialogSpan}`}>Description</label>
                                    <textarea
                                        className={`${styles.dialogDescription}`}
                                        id="ticketDescription"
                                        rows={4}
                                        value={description}
                                        onChange={handleDescriptionChange}
                                    ></textarea>
                                </div>
                                <div className={styles.dialogFooter}>
                                    <div className={`${styles.dialogUpload}`}>
                                        <span id="fileName" className={`${styles.fileName}`}></span>
                                        <label htmlFor="ticketAttachment" className={`${styles.dialogAttachment}`}>
                                            <PaperclipSvg className={`${styles.dialogAttachmentSvg}`} />Upload file
                                        </label>
                                        <input
                                            className={`${styles.dialogAttachmentInput}`}
                                            type="file"
                                            id="ticketAttachment"
                                            onChange={handleFileChange}
                                        />
                                    </div>
                                    <button
                                        className={`${styles.dialogButton}`}
                                        type="submit"
                                        disabled={submitLoading}
                                    >
                                        {submitLoading ? 'Создание...' : 'Submit Ticket'}
                                    </button>
                                </div>
                            </form>

                        </div>
                    </div>
                </dialog>
            </div>

        </div>
    );
}

export default Support;
