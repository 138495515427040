import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
//@ts-ignore
import styles from './TermsOfUse.module.scss';

interface TermsItem {
    id: number;
    question: string;
    answer: string;
}

const TermsOfUse: React.FC = () => {
    const [activeId, setActiveId] = useState<number | null>(null);
    const { t } = useTranslation();

    const toggleAccordion = (id: number) => {
        setActiveId(activeId === id ? null : id);
    };

    const getTermsData = (): TermsItem[] => {
        const sections = t('termsOfUse.sections', { returnObjects: true });
        return Object.entries(sections).map(([id, section]: [string, any]) => ({
            id: parseInt(id),
            question: section.title,
            answer: section.content
        }));
    };

    return (
        <div className={styles.faq}>
            <div className={styles.faqContainer}>
                <div className={styles.faqHeader}>
                    <h2 className={styles.faqTitle}>{t('termsOfUse.title')}</h2>
                    <p className={styles.faqDate}>{t('termsOfUse.lastUpdated')}</p>
                </div>
                <div className={styles.faqContent}>
                    <div className={styles.faqSection}>
                        <div className={styles.faqList}>
                            {getTermsData().map((item) => (
                                <div key={item.id} className={styles.faqItem}>
                                    <button
                                        className={`${styles.faqQuestion} ${activeId === item.id ? styles.active : ''}`}
                                        onClick={() => toggleAccordion(item.id)}
                                    >
                                        {item.question}
                                        <span className={`${styles.faqIcon} ${activeId === item.id ? styles.active : ''}`}>
                                            {activeId === item.id ? '−' : '+'}
                                        </span>
                                    </button>
                                    <div className={`${styles.faqAnswer} ${activeId === item.id ? styles.active : ''}`}>
                                        <p>{item.answer}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TermsOfUse; 