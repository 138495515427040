import React, { useState, useRef, useEffect } from 'react';
import { isToday, isThisWeek, isThisMonth, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useDashboardData } from '../DataProvider/DashboardDataProvider.tsx';
import { apiRequest, getCurrencies, Currency } from '../../../../data/Api.ts';
import { LupaSvg, ArrowSvg, CalendarSvg, SettingsSvg, TrashSvg, OstentatiousCrossSvg } from '../../../../assets/svg-files/SvgIcons.ts';
// @ts-ignore
import styles from './Transactions.module.scss';
// @ts-ignore
import mediaStyles from './Transactions-media.module.scss';

// Обновим интерфейс Transaction в соответствии с форматом API
interface Transaction {
    id: number;
    tx_type: string;
    api_label: string;
    label: string;
    currency: {
        name: string;
        token: string;
        network: string;
        contract: string;
        decimals: number;
        confirmations: number;
        network_fee: number;
        min_deposit: number;
    };
    amount: number;
    amount_usd: number;
    network_fee: number;
    network_fee_usd: number;
    service_fee: number;
    service_fee_usd: number;
    tx_hash: string;
    is_paid: boolean;
    is_postback_sended: boolean;
    created_at: string;
}

interface VisibleColumns {
    id: boolean;
    tx_hash: boolean;
    api_label: boolean;
    label: boolean;
    amount: boolean;
    amount_usd: boolean;
    is_paid: boolean;
    is_postback_sended: boolean;
    created_at: boolean;
}
const maxResults = 30;

const filterOptions = {
    Currency: [] as string[], // Инициализируем пустым массивом, будет заполнен из API
    Project: ['Project A', 'Project B', 'Project C'],
    Type: ['Paid', 'Unpaid', 'Pending', 'Expired', 'Sent', 'Not sent'],
    Other: ['Option 1', 'Option 2', 'Option 3'],
};
const Transactions: React.FC<{ selectedPeriod: 'day' | 'week' | 'month' }> = ({ selectedPeriod }) => {

    const [visibleColumns, setVisibleColumns] = useState<VisibleColumns>({
        id: true,
        tx_hash: true,
        api_label: true,
        label: true,
        amount: true,
        amount_usd: true,
        is_paid: true,
        is_postback_sended: true,
        created_at: true,
    });

    const { data, isLoading, error } = useDashboardData();

    const [transactionsWithFilters, setTransactionsWithFilters] = useState<Transaction[]>([]);
    const { i18n } = useTranslation();
    const settingsDialog = useRef<HTMLDialogElement>(null);
    const [tempVisibleColumns, setTempVisibleColumns] = useState<VisibleColumns>(visibleColumns);
    const [selectedOptions, setSelectedOptions] = useState<{ [key: string]: Set<string> }>({});
    const hasActiveFilters = Object.values(selectedOptions).some((options) => options.size > 0);
    const filterButtonRef = useRef<HTMLButtonElement | null>(null);
    const filterDropdownRef = useRef<HTMLDivElement | null>(null);
    const [isFilterDropdownVisible, setIsFilterDropdownVisible] = useState(false);
    const [activeCategory, setActiveCategory] = useState<string | null>(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [sortConfig, setSortConfig] = useState<{ key: string | null; direction: 'asc' | 'desc' }>({
        key: 'created_at',
        direction: 'desc',
    });

    // Состояние для хранения списка валют из API
    const [currencyOptions, setCurrencyOptions] = useState<string[]>([]);
    const [isLoadingCurrencies, setIsLoadingCurrencies] = useState(false);

    // Получение списка валют с API
    useEffect(() => {
        const fetchCurrencies = async () => {
            setIsLoadingCurrencies(true);
            try {
                const currencies = await getCurrencies();
                // Извлекаем только имена валют для фильтрации
                const currencyNames = currencies.map(currency => currency.name);
                setCurrencyOptions(currencyNames);

                // Обновляем filterOptions с новыми валютами
                filterOptions.Currency = currencyNames;
            } catch (error) {
                console.error('Ошибка при получении данных о валютах:', error);
            } finally {
                setIsLoadingCurrencies(false);
            }
        };

        fetchCurrencies();
    }, []);

    // Обновляем transactionsWithFilters при изменении данных
    useEffect(() => {
        // Получаем транзакции с API
        const fetchTransactions = async () => {
            setTransactionsWithFilters([]); // Очищаем текущие данные
            try {
                const response = await apiRequest<Transaction[]>('/pay/user/balance/transactions', 'GET');
                if (response && Array.isArray(response)) {
                    // Фильтруем транзакции по выбранному периоду
                    const filtered = response.filter(tx => {
                        const date = parseISO(tx.created_at);
                        if (selectedPeriod === 'day') {
                            return isToday(date);
                        } else if (selectedPeriod === 'week') {
                            return isThisWeek(date);
                        } else if (selectedPeriod === 'month') {
                            return isThisMonth(date);
                        }
                        return true;
                    });
                    setTransactionsWithFilters(filtered);
                }
            } catch (error) {
                console.error('Ошибка при получении транзакций:', error);
            }
        };

        fetchTransactions();
    }, [selectedPeriod]);

    const sortedTransactions = [...transactionsWithFilters].sort((a, b) => {
        if (!sortConfig.key) return 0;
        const { key, direction } = sortConfig;

        let aValue = a[key];
        let bValue = b[key];

        if (!isNaN(Number(aValue)) && !isNaN(Number(bValue))) {
            aValue = Number(aValue);
            bValue = Number(bValue);
        }

        if (typeof aValue === 'boolean' && typeof bValue === 'boolean') {
            return direction === 'asc' ? Number(aValue) - Number(bValue) : Number(bValue) - Number(aValue);
        }
        if (aValue < bValue) return direction === 'asc' ? -1 : 1;
        if (aValue > bValue) return direction === 'asc' ? 1 : -1;
        return 0;
    });
    const handleSort = (key: string) => {
        setSortConfig((prev) => ({
            key,
            direction: prev.key === key && prev.direction === 'desc' ? 'asc' : 'desc',
        }));
    };
    const handleCategoryClick = (category: string) => {
        setActiveCategory(category);
        setSearchQuery('');
    };
    const handleCheckboxChange = (category: string, option: string) => {
        setSelectedOptions((prev) => {
            const updatedSet = new Set(prev[category] || []);
            if (updatedSet.has(option)) {
                updatedSet.delete(option);
            } else {
                updatedSet.add(option);
            }
            return { ...prev, [category]: updatedSet };
        });
    };
    const filteredResults = Object.entries(filterOptions).flatMap(([category, options]) =>
        options.filter((option) => option.toLowerCase().includes(searchQuery.toLowerCase()))
            .map((option) => ({ category, option }))
    );


    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };
    const handleFilterToggle = () => {
        setIsFilterDropdownVisible(!isFilterDropdownVisible);
        setActiveCategory(null);
    };
    const handleRemoveFilter = (category: string, option: string) => {
        setSelectedOptions((prev) => {
            const updatedSet = new Set(prev[category] || []);
            updatedSet.delete(option);
            return { ...prev, [category]: updatedSet };
        });
    };
    const handleClearFilters = () => {
        setSelectedOptions({});
    };
    const saveSettings = () => {
        // Обновляем видимость столбцов
        setVisibleColumns(tempVisibleColumns);

        // Сохраняем изменения в localStorage
        localStorage.setItem("visibleColumns", JSON.stringify(tempVisibleColumns));
        if (settingsDialog.current) {
            settingsDialog.current.close();
        }
    };
    const toggleColumn = (column: string) => {
        if (column === "id" || column === "created_at") {
            return; // Заблокированные столбцы нельзя изменить
        }

        setTempVisibleColumns((prev: VisibleColumns) => ({
            ...prev,
            [column]: !prev[column],
        }));
    };
    const toggleAllColumns = () => {
        const areAllSelected = Object.keys(tempVisibleColumns)
            .filter((key) => key !== "id" && key !== "created_at") // Исключаем id и created_at
            .every((key) => tempVisibleColumns[key]); // Проверяем, все ли остальные выбраны

        setTempVisibleColumns((prev: VisibleColumns) => {
            const newVisibleColumns: VisibleColumns = {
                id: true,
                tx_hash: !areAllSelected,
                api_label: !areAllSelected,
                label: !areAllSelected,
                amount: !areAllSelected,
                amount_usd: !areAllSelected,
                is_paid: !areAllSelected,
                is_postback_sended: !areAllSelected,
                created_at: true,
            };
            return newVisibleColumns;
        });
    };

    const closeDialog = () => {
        if (settingsDialog.current) {
            settingsDialog.current?.close();
        }
        if (settingsDialog.current) {
            settingsDialog.current.close();
        }
    };

    const handleBackdropClick = (e: React.MouseEvent) => {
        if (e.target === e.currentTarget) {
            closeDialog();
        }
    };

    const periodText = {
        day: { label: i18n.t('day'), subtitle: i18n.t('dailyStatistic') },
        week: { label: i18n.t('week'), subtitle: i18n.t('weeklyStatistic') },
        month: { label: i18n.t('month'), subtitle: i18n.t('moonthlyStatistic') }
    };
    const currentPeriodText = periodText[selectedPeriod] || periodText.day;

    // Добавляем обработчик кликов вне меню
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (filterDropdownRef.current &&
                !filterDropdownRef.current.contains(event.target as Node) &&
                !filterButtonRef.current?.contains(event.target as Node)) {
                setIsFilterDropdownVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Фильтр транзакций по поисковому запросу и выбранным фильтрам
    const filteredAndSortedTransactions = sortedTransactions.filter(transaction => {
        // Поиск по тексту
        const textMatch = searchQuery === '' ||
            transaction.api_label.toLowerCase().includes(searchQuery.toLowerCase());

        // Фильтрация по выбранным опциям
        const matchesFilters = Object.entries(selectedOptions).every(([category, selectedValues]) => {
            if (selectedValues.size === 0) return true; // Если в категории нет выбранных значений, пропускаем фильтрацию

            switch (category) {
                case 'Currency':
                    return selectedValues.has(transaction.currency.name);
                case 'Project':
                    return selectedValues.has(transaction.api_label);
                // Для остальных фильтров можно добавить логику по мере необходимости
                default:
                    return true;
            }
        });

        return textMatch && matchesFilters;
    });

    return (
        <div className={`${styles.transactions} ${mediaStyles.transactions}`}>

            <div className={`${styles.transactionsContainer} ${mediaStyles.transactionsContainer}`}>
                <div className={`${styles.transactionsWrapper} ${mediaStyles.transactionsWrapper}`}>

                    <div className={`${styles.transactionsTitleWrapper} ${mediaStyles.transactionsTitleWrapper}`}>
                        <div className={`${styles.transactionsTitle} ${mediaStyles.transactionsTitle}`}>{i18n.t('latestTransactions')}</div>
                        <div className={`${styles.transactionsSubtitle} ${mediaStyles.transactionsSubtitle}`}>{currentPeriodText.subtitle}</div>
                    </div>
                    <div className={`${styles.transactionsItem}`}>
                        <button className={`${styles.transactionsButton}`} onClick={() => settingsDialog.current?.showModal()}><SettingsSvg /></button>
                        <dialog ref={settingsDialog} onClick={handleBackdropClick} className={`${styles.transactionsDialog} ${mediaStyles.transactionsDialog}`}>
                            <div className={`${styles.transactionsDialogBlock} ${mediaStyles.transactionsDialogBlock}`}>

                                <div className={`${styles.transactionsDialogContainer} ${mediaStyles.transactionsDialogContainer}`}>
                                    <div className={`${styles.transactionsDialogHeader} ${mediaStyles.transactionsDialogHeader}`}>
                                        <h3 className={`${styles.transactionsDialogTitle} ${mediaStyles.transactionsDialogTitle}`}>Settings</h3>
                                        <button onClick={closeDialog} className={`${styles.transactionsDialogCross} ${mediaStyles.transactionsDialogCross}`}>X</button>
                                    </div>

                                    <div className={`${styles.transactionsDialogWrapper} ${mediaStyles.transactionsDialogWrapper}`}>
                                        Parameters
                                        <label className={`${styles.transactionsDialogSub} ${mediaStyles.transactionsDialogSub}`}>
                                            Status
                                            <input
                                                type="checkbox"
                                                checked={Object.keys(tempVisibleColumns)
                                                    .filter((key) => key !== "id" && key !== "created_at")
                                                    .every((key) => tempVisibleColumns[key])}
                                                onChange={toggleAllColumns}
                                                className={`${styles.transactionsDialogCheckbox} ${mediaStyles.transactionsDialogCheckbox}`}
                                            />
                                        </label>
                                    </div>

                                    <ul className={`${styles.transactionsDialogUl} ${mediaStyles.transactionsDialogUl}`}>
                                        {Object.keys(tempVisibleColumns).map((key) => (
                                            <li key={key}>
                                                <label className={`${styles.transactionsDialogLabel} ${mediaStyles.transactionsDialogLabel}`}>
                                                    {key}
                                                    <input
                                                        type="checkbox"
                                                        checked={tempVisibleColumns[key]}
                                                        onChange={() => toggleColumn(key)}
                                                        disabled={key === "id" || key === "created_at"}
                                                        className={`${styles.transactionsDialogCheckbox} ${mediaStyles.transactionsDialogCheckbox}`}
                                                    />

                                                </label>
                                            </li>
                                        ))}
                                    </ul>
                                    <div className={`${styles.transactionsDialogButtons} ${mediaStyles.transactionsDialogButtons}`}>
                                        <button className={`${styles.transactionsDialogClose} ${mediaStyles.transactionsDialogClose}`} onClick={closeDialog}>Close</button>
                                        <button className={`${styles.transactionsDialogSave} ${mediaStyles.transactionsDialogSave}`} onClick={saveSettings}>Save</button>
                                    </div>
                                </div>
                            </div>
                        </dialog>
                    </div>
                </div>

                <div className={`${styles.transactionsPreferences}`}>
                    {hasActiveFilters && (
                        <button className={`${styles.transactionsClearButton}`} onClick={handleClearFilters}>
                            <TrashSvg />
                        </button>
                    )}
                    {Object.entries(selectedOptions).map(([filter, options]) =>
                        Array.from(options).map((option) => (
                            <div key={`${filter}-${option}`} className={styles.transactionsFilterTag}>
                                {option}
                                <button className={styles.transactionsCrossButton} onClick={() => handleRemoveFilter(filter, option)}>
                                    <OstentatiousCrossSvg className={styles.transactionsCross} />
                                </button>
                            </div>
                        ))
                    )}

                    <button className={`${styles.transactionsPreferencesButton}`} ref={filterButtonRef} onClick={handleFilterToggle}>Add filter +</button>
                    {isFilterDropdownVisible && (
                        <div ref={filterDropdownRef} className={styles.transactionsFilterDropdown}>
                            <div className={styles.transactionsFilterSearch}>
                                <LupaSvg className={styles.transactionsLupa} />
                                <input
                                    className={styles.transactionsFilterInput}
                                    type="text"
                                    placeholder="Search filters..."
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                />
                            </div>

                            {searchQuery ? (
                                <ul className={styles.transactionsFilterUl}>
                                    {filteredResults.map(({ category, option }) => (
                                        <li key={option} className={styles.transactionsFilterItem}>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    checked={selectedOptions[category]?.has(option) || false}
                                                    onChange={() => handleCheckboxChange(category, option)}
                                                />
                                                {option} <span className={styles.filterCategory}>({category})</span>
                                            </label>
                                        </li>
                                    ))}
                                </ul>
                            ) : activeCategory ? (
                                <>
                                    <ul className={styles.transactionsFilterUl}>
                                        {isLoadingCurrencies && activeCategory === 'Currency' ? (
                                            <li className={styles.transactionsFilterItem}>Loading currencies...</li>
                                        ) : (
                                            (activeCategory === 'Currency' ? currencyOptions : filterOptions[activeCategory]).map((option) => (
                                                <li key={option}>
                                                    <label className={styles.transactionsFilterItem}>
                                                        {option}
                                                        <input
                                                            type="checkbox"
                                                            checked={selectedOptions[activeCategory]?.has(option) || false}
                                                            onChange={() => handleCheckboxChange(activeCategory, option)}
                                                            className={`${styles.transactionsDialogFilterCheckbox} ${mediaStyles.transactionsDialogFilterCheckbox}`}
                                                        />
                                                    </label>
                                                </li>
                                            ))
                                        )}
                                    </ul>
                                    <button className={styles.transactionsBackButton} onClick={() => setActiveCategory(null)}>
                                        <ArrowSvg className={styles.transactionsBackArrow} />Back
                                    </button>
                                </>
                            ) : (
                                <ul className={styles.transactionsFilterUl}>
                                    <li className={styles.transactionsFilterItem} onClick={() => handleCategoryClick('Currency')}>
                                        Currency
                                        <ArrowSvg className={styles.transactionsFilterArrow} />
                                    </li>
                                    {Object.keys(filterOptions).map((category) => (
                                        <li key={category} className={styles.transactionsFilterItem} onClick={() => handleCategoryClick(category)}>
                                            {category}
                                            <ArrowSvg className={styles.transactionsFilterArrow} />
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    )}
                </div>

                <div className={`${styles.transactionsBlock} ${mediaStyles.transactionsBlock}`}>
                    <div className={`${styles.transactionsLine} ${mediaStyles.transactionsLine}`}>
                        {visibleColumns.id && <div className={`${styles.transactionsText} ${styles.listId}`} onClick={() => handleSort('id')}>TX ID {sortConfig.key === 'id' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</div>}
                        {visibleColumns.tx_hash && <div className={`${styles.transactionsText} ${styles.listHash}`} onClick={() => handleSort('tx_hash')}>TX Hash {sortConfig.key === 'tx_hash' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</div>}
                        {visibleColumns.api_label && <div className={`${styles.transactionsText} ${styles.listApi}`} onClick={() => handleSort('api_label')}>API {sortConfig.key === 'api_label' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</div>}
                        {visibleColumns.label && <div className={`${styles.transactionsText} ${styles.listLabel}`} onClick={() => handleSort('label')}>{i18n.t('transactions')} {sortConfig.key === 'label' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</div>}
                        {visibleColumns.amount && <div className={`${styles.transactionsText} ${styles.listToken}`} onClick={() => handleSort('amount')}>{i18n.t('amount')} {sortConfig.key === 'amount' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</div>}
                        {visibleColumns.amount_usd && <div className={`${styles.transactionsText} ${styles.listAmount}`} onClick={() => handleSort('amount_usd')}>{i18n.t('amount')} USD {sortConfig.key === 'amount_usd' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</div>}
                        {visibleColumns.is_paid && <div className={`${styles.transactionsText} ${styles.listPaid}`} onClick={() => handleSort('is_paid')}>{i18n.t('payed')} {sortConfig.key === 'is_paid' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</div>}
                        {visibleColumns.is_postback_sended && <div className={`${styles.transactionsText} ${styles.listSent}`} onClick={() => handleSort('is_postback_sended')}>IPN {i18n.t('sent')} {sortConfig.key === 'is_postback_sended' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</div>}
                        {visibleColumns.created_at && <div className={`${styles.transactionsText} ${styles.listTime}`} onClick={() => handleSort('created_at')}>{i18n.t('created')} {sortConfig.key === 'created_at' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</div>}
                    </div>

                    <ul className={`${styles.list} ${mediaStyles.list}`}>
                        {isLoading ? (
                            <div className={styles.listLoading}>Загрузка транзакций...</div>
                        ) : error ? (
                            <div className={styles.listError}>Ошибка загрузки: {error}</div>
                        ) : filteredAndSortedTransactions.length === 0 ? (
                            <div className={styles.listNoTransactions}>
                                <h3 className={styles.listNoTransactionsTitle}>{i18n.t('noTransactionsTitle')}</h3>
                                <p className={styles.listNoTransactionsSubtitle}>{i18n.t('noTransactionsSubtitle')}</p>
                            </div>
                        ) : (
                            filteredAndSortedTransactions.map((transaction) => (
                                <li key={transaction.id} className={`${styles.listItem} ${mediaStyles.listItem}`}>
                                    {visibleColumns.id && (
                                        <div className={`${styles.listWrapper} ${styles.listId}`}>
                                            {transaction.id}
                                        </div>
                                    )}
                                    {visibleColumns.tx_hash && (
                                        <div className={`${styles.listWrapper} ${styles.listHash}`}>{transaction.tx_hash.substring(0, 8)}...</div>
                                    )}
                                    {visibleColumns.api_label && (
                                        <div className={`${styles.listWrapper} ${styles.listApi}`}>{transaction.api_label}</div>
                                    )}
                                    {visibleColumns.label && (
                                        <div className={`${styles.listWrapper} ${styles.listLabel}`}>{transaction.label}</div>
                                    )}
                                    {visibleColumns.amount && (
                                        <div className={`${styles.listWrapper} ${styles.listToken}`}>
                                            {transaction.amount.toFixed(2)} {transaction.currency.name}
                                        </div>
                                    )}
                                    {visibleColumns.amount_usd && (
                                        <div className={`${styles.listWrapper} ${styles.listAmount}`}>
                                            {transaction.amount_usd.toFixed(2)} USD
                                        </div>
                                    )}
                                    {visibleColumns.is_paid && (
                                        <div className={`${styles.listWrapper} ${styles.listPaid}`}>
                                            {transaction.is_paid ? (
                                                <span className={styles.listStatusTrue}>{i18n.t('paid')}</span>
                                            ) : (
                                                <span className={styles.listStatusFalse}>{i18n.t('pending')}</span>
                                            )}
                                        </div>
                                    )}
                                    {visibleColumns.is_postback_sended && (
                                        <div className={`${styles.listWrapper} ${styles.listSent}`}>
                                            {transaction.is_postback_sended ? (
                                                <span className={styles.listStatusTrue}>{i18n.t('sent')}</span>
                                            ) : (
                                                <span className={styles.listStatusFalse}>{i18n.t('notsent')}</span>
                                            )}
                                        </div>
                                    )}
                                    {visibleColumns.created_at && (
                                        <div className={`${styles.listWrapper} ${styles.listTime}`}>
                                            {new Date(transaction.created_at).toLocaleString('ru-RU', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                            })}
                                        </div>
                                    )}
                                </li>
                            ))
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Transactions;
