import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationRU from '../assets/locales/ru/translation.json';
import translationEN from '../assets/locales/en/translation.json';

const localLang = localStorage.getItem('language');
const firstChangeLang = navigator.language.includes('ru') ? 'ru' : 'en';

const savedLanguage = localLang || firstChangeLang

const resources = {
    ru: {
        translation: translationRU,
    },
    en: {
        translation: translationEN,
    },
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: savedLanguage,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
