import React, { useState, useEffect } from 'react';
// @ts-ignore
import styles from './TwoFactorInput.module.scss';

interface TwoFactorInputProps {
    value: string;
    onChange: (value: string) => void;
}

const TwoFactorInput: React.FC<TwoFactorInputProps> = ({ value, onChange }) => {
    const [error, setError] = useState('');

    useEffect(() => {
        // Если переданный код пустой, сбрасываем ошибку
        if (value.length === 6) {
            setError('');
        }
    }, [value]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let inputValue = e.target.value;

        // Убираем все нецифровые символы, кроме дефиса
        inputValue = inputValue.replace(/\D/g, '');

        // Ограничиваем длину кода до 6 символов
        if (inputValue.length > 6) {
            inputValue = inputValue.slice(0, 6);
        }

        // Форматируем код с дефисом (если длина больше 3)
        if (inputValue.length > 3) {
            inputValue = inputValue.slice(0, 3) + '-' + inputValue.slice(3, 6);
        }

        // Обновляем значение кода в родительском компоненте
        onChange(inputValue);
    };

    // Очищаем дефис перед передачей в родительский компонент
    const codeWithoutDash = value.replace('-', '');

    return (
        <div>
            <label className={`${styles.label}`}>
                <span className={`${styles.span}`}>Enter the 2FA key from the app</span>
                <input
                    className={`${styles.input}`}
                    type="text"
                    placeholder="000-000" // Ожидается код из 6 цифр
                    value={value} // Показываем код с дефисом
                    onChange={handleChange}
                    maxLength={7} // Ограничение по длине
                />
            </label>
            {error && <span className={styles.error}>{error}</span>}
        </div>
    );
};

export default TwoFactorInput;
