import { BASE_URL } from "./config.js";

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

function getCookie(name: string): string {
  const matches = document.cookie.match(
    new RegExp('(?:^|; )' + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)')
  );
  return matches ? decodeURIComponent(matches[1]) : '';
}

// Интерфейс для данных о валютах из /pay/user/currencies
export interface Currency {
  name: string;
  token: string;
  network: string;
  contract: string;
  decimals: number;
  confirmations: number;
  network_fee: number;
  min_deposit: number;
}

// Функция для получения данных о валютах
export async function getCurrencies(): Promise<Currency[]> {
  return await apiRequest<Currency[]>('/pay/user/currencies', 'GET');
}

// Интерфейс для данных пользователя из /pay/user/info
export interface UserInfo {
  id: number;
  photo: string;
  name: string;
  email: string;
  country: string;
  status: string;
  balance: number;
}

// Функция для получения данных пользователя из /pay/user/info
export async function getUserInfo(): Promise<UserInfo> {
  return await apiRequest<UserInfo>('/pay/user/info', 'GET');
}

// Интерфейс для данных профиля пользователя
export interface UserProfile {
  id: number;
  photo: string | null;
  name: string | null;
  email: string;
  country: string;
  status: string;
  is_2fa_enabled: boolean;
  telegram_code: string | null;
  telegram_id: number | null;
}

// Функция для получения данных профиля пользователя
export async function getUserProfile(): Promise<UserProfile> {
  return await apiRequest<UserProfile>('/pay/user/profile/', 'GET');
}

export async function apiRequest<T = any>(
  url: string,
  method: HttpMethod = 'GET',
  body: unknown = null,
  token: string = getCookie('access_token')
): Promise<T> {
  const headers: Record<string, string> = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json'
  };

  const options: RequestInit = {
    method,
    headers
  };

  if (body) {
    // Если body уже строка, используем как есть, иначе преобразуем в JSON
    options.body = typeof body === 'string' ? body : JSON.stringify(body);
  }

  try {
    const response = await fetch(BASE_URL + url, options);
    if (!response.ok) {
      // Пытаемся распарсить тело ответа как JSON, чтобы вытащить detail
      let errorData: any;
      try {
        errorData = await response.json();
      } catch (parseError) {
        throw new Error('Something went wrong (JSON parse error)');
      }
      throw new Error(errorData.detail || 'Something went wrong');
    }
    return (await response.json()) as T;
  } catch (error) {
    console.error('API request error:', error);
    throw error;
  }
}
