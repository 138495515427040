import React, { useState, useEffect, useRef } from 'react';
import { useLocation, NavLink, Link as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { convertedBalance } from '../../data/data.ts';
import { useTranslation } from 'react-i18next';
import { useMenu } from '../../tools/MenuContext.tsx';
import { profileImage } from '../../assets/images/images.ts';
import { getUserInfo, UserInfo } from '../../data/Api.ts';

import { LupaSvg, CrossSvg, InfoSvg, SunSvg, MoonSvg, ArrowSvg, LogoutSvg, LangSvg } from '../../assets/svg-files/SvgIcons.ts';

// @ts-ignore
import styles from './Header.module.scss';
// @ts-ignore
import mediaStyles from './Header-media.module.scss';


const Header = () => {
    const { toggleMenu } = useMenu();
    const { i18n } = useTranslation();
    const navigate = useNavigate();
    const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
    const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false);
    const profileMenuRef = useRef<HTMLDivElement | null>(null);
    const dropdownMenuRef = useRef<HTMLDivElement | null>(null);
    const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const totalBalance = Object.values(convertedBalance).reduce((acc, val) => acc + val, 0);

    // Функция для выхода из аккаунта
    const handleLogout = () => {
        // Удаляем токен из cookies
        document.cookie = 'access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

        // Можно также очистить localStorage, если там хранятся какие-то данные пользователя
        localStorage.removeItem('emailEnabled');
        localStorage.removeItem('telegramEnabled');
        localStorage.removeItem('emailCheckboxes');
        localStorage.removeItem('telegramCheckboxes');

        // Перенаправляем на страницу авторизации
        navigate('/Authorization', { replace: true });
    };

    // Получение информации о пользователе
    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                setIsLoading(true);
                const data = await getUserInfo();
                setUserInfo(data);
            } catch (error) {
                console.error('Ошибка при получении данных пользователя:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchUserInfo();
    }, []);

    const toggleProfileMenu = () => {
        setIsProfileMenuOpen((prev) => !prev);
        setIsLanguageMenuOpen(false); // Закрываем язык при открытии основного меню
    };

    const toggleLanguageMenu = () => {
        setIsLanguageMenuOpen((prev) => !prev);
    };

    const changeLanguage = (lang: string) => {
        i18n.changeLanguage(lang);
        localStorage.setItem('language', lang);
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                dropdownMenuRef.current &&
                !dropdownMenuRef.current.contains(event.target as Node) &&
                profileMenuRef.current &&
                !profileMenuRef.current.contains(event.target as Node)
            ) {
                setIsProfileMenuOpen(false);
                setIsLanguageMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    const [isDarkMode, setIsDarkMode] = useState(() => {
        const savedTheme = localStorage.getItem('theme');
        if (savedTheme) {
            return savedTheme === 'dark';
        }
        // Если темы нет в localStorage, проверяем системные настройки
        return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    });

    useEffect(() => {
        const root = document.documentElement;

        // Функция для установки темы
        const setTheme = (isDarkMode) => {
            if (isDarkMode) {
                root.style.colorScheme = 'dark';
                localStorage.setItem('theme', 'dark'); // Сохраняем выбранную тему
            } else {
                root.style.colorScheme = 'light';
                localStorage.setItem('theme', 'light');
            }
        };

        // Устанавливаем тему при монтировании компонента и изменении состояния
        setTheme(isDarkMode);
    }, [isDarkMode]);

    // Убираем моргание, устанавливаем начальную тему в рендер
    useEffect(() => {
        const savedTheme = localStorage.getItem('theme');
        const root = document.documentElement;
        if (savedTheme === 'dark') {
            root.style.colorScheme = 'dark'; // Применяем темную тему, если она сохранена
        } else {
            root.style.colorScheme = 'light'; // В противном случае светлая
        }
    }, []);

    const toggleTheme = () => {
        setIsDarkMode((prevMode) => !prevMode); // Переключаем состояние темы
    };

    let title = '';
    let tutorialButton = null;
    const location = useLocation();

    switch (location.pathname) {
        case '/':
            title = i18n.t('dashboard');
            // @ts-ignore
            tutorialButton = (
                <button className={styles.item}>
                    <InfoSvg className={styles.ulSvg} />
                </button>
            );
            break;
        case '/pages/Balance':
            title = i18n.t('Balance');
            // @ts-ignore
            tutorialButton = (
                <button className={styles.item}>
                    <InfoSvg className={styles.ulSvg} />
                </button>
            );
            break;
        case '/pages/Wallets':
            title = i18n.t('wallets');
            // @ts-ignore
            tutorialButton = (
                <button className={styles.item}>
                    <InfoSvg className={styles.ulSvg} />
                </button>
            );
            break;
        case '/pages/Invoices':
            title = i18n.t('invoices');
            // @ts-ignore
            tutorialButton = (
                <button className={styles.item}>
                    <InfoSvg className={styles.ulSvg} />
                </button>
            );
            break;
        case '/pages/MyProjects':
            title = i18n.t('MyProjects');
            // @ts-ignore
            tutorialButton = (
                <button className={styles.item}>
                    <InfoSvg className={styles.ulSvg} />
                </button>
            );
            break;
        case '/pages/Support':
            title = i18n.t('support');
            // @ts-ignore
            tutorialButton = (
                <button className={styles.item}>
                    <InfoSvg className={styles.ulSvg} />
                </button>
            );
            break;
        case '/pages/Faq':
            title = 'FAQ';
            // @ts-ignore
            tutorialButton = (
                <button className={styles.item}>
                    <InfoSvg className={styles.ulSvg} />
                </button>
            );
            break;
        case '/pages/Api':
            title = i18n.t('api');
            // @ts-ignore
            tutorialButton = (
                <button className={styles.item}>
                    <InfoSvg className={styles.ulSvg} />
                </button>
            );
            break;
        case '/pages/Profile':
            title = i18n.t('profile.tabs.main');
            // @ts-ignore
            tutorialButton = (
                <button className={styles.item}>
                    <InfoSvg className={styles.ulSvg} />
                </button>
            );
            break;
        case '/pages/PrivacyPolicy':
            title = 'Privacy Policy';
            tutorialButton = null;
            break;
        case '/pages/TermsOfUse':
            title = 'Terms of Use';
            tutorialButton = null;
            break;
        default:
            title = 'Default Title';
            tutorialButton = null;
            break;
    }




    return (
        <header className={`${styles.header} ${mediaStyles.header}`}>
            <div className={`${styles.titleWrapper} ${mediaStyles.titleWrapper}`}>
                <div className={`${styles.burger} ${mediaStyles.burger}`} onClick={toggleMenu}>
                    <span className={styles.topLine}></span>
                    <span className={styles.botLine}></span>
                </div>

                <div className={styles.headerWrapper}>
                    <h1 className={styles.title}>{title}</h1>
                    {tutorialButton}
                </div>
            </div>
            <div className={styles.container}>
                <form action="/search" id="search-form" className={`${styles.searchForm} ${mediaStyles.searchForm}`} method="get">
                    <LupaSvg className={styles.searchSvg} />
                    <input className={styles.searchInput} id="searchInput" name="search"
                        placeholder={i18n.t('searchinput')} autoComplete="off" />
                    <button className={styles.searchCross} aria-label="Clear search" type="reset" >
                        <CrossSvg className={styles.crossSvg} />
                    </button>
                    <ul id="results" className="results">
                        <li></li>
                    </ul>
                </form>

                <div className={styles.headerContainer}>
                    <div className={`${styles.headerSum} ${mediaStyles.headerSum}`}>
                        $ {isLoading ? '...' : userInfo ? userInfo.balance.toFixed(2) : '0.00'}
                    </div>
                    <div className={styles.headerMenu} onClick={toggleProfileMenu} ref={profileMenuRef}>
                        <img className={styles.headerImage} src={profileImage.test} alt="Profile" />
                        <ArrowSvg className={`${styles.headerArrow} ${isProfileMenuOpen ? styles.headerArrowOpen : ''}`} />
                    </div>
                    {isProfileMenuOpen && (
                        <div className={styles.headerDropdown} ref={dropdownMenuRef}>
                            {!isLanguageMenuOpen ? (
                                <ul>
                                    <li className={styles.headerLi}>
                                        <img className={styles.headerDropdownImage} src={profileImage.test} alt="Profile" />
                                        <NavLink className={styles.headerText} onClick={toggleProfileMenu} to="/pages/Profile">
                                            <p className={styles.headerUsername}>
                                                {isLoading ? 'Загрузка...' : (userInfo?.name || userInfo?.email || 'Вы кто?')}
                                            </p>
                                            <small className={styles.headerSmall}>Перейти в профиль</small>
                                        </NavLink>
                                    </li>
                                    <li className={styles.headerPreferences} onClick={toggleLanguageMenu}><LangSvg className={styles.ulSvg} />Язык</li>
                                    <li className={styles.headerPreferences} onClick={toggleTheme}>
                                        {isDarkMode ? (
                                            <>
                                                <MoonSvg className={styles.ulSvg} />Тема: Тёмная
                                            </>
                                        ) : (
                                            <>
                                                <SunSvg className={styles.ulSvg} />Тема: Светлая
                                            </>
                                        )}
                                    </li>
                                    <li className={styles.headerLogout} onClick={handleLogout}><LogoutSvg className={styles.ulSvg} />Logout</li>
                                </ul>
                            ) : (
                                <ul>
                                    <li className={styles.headerPreferencesBack} onClick={toggleLanguageMenu}>← Назад</li>
                                    <li className={styles.headerPreferences} onClick={() => changeLanguage('ru')}>
                                        {i18n.language === 'ru' && '✔'} Русский
                                    </li>
                                    <li className={`${styles.headerPreferencesLang}`} onClick={() => changeLanguage('en')}>
                                        {i18n.language === 'en' && '✔'} English
                                    </li>
                                </ul>
                            )}
                        </div>
                    )}
                </div>

            </div>
        </header>
    );
};

export default Header;
